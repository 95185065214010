import ModalDE1 from "../../../../src/markdown/de/chapter_2/sub_3/modals/modal-2-3-1-1.mdx";
import ModalDE2 from "../../../../src/markdown/de/chapter_2/sub_3/modals/modal-2-3-1-2.mdx";
import ModalDE3 from "../../../../src/markdown/de/chapter_2/sub_3/modals/modal-2-3-1-3.mdx";
import ModalDE4 from "../../../../src/markdown/de/chapter_2/sub_3/modals/modal-2-3-1-4.mdx";
import React from 'react';
export default {
  ModalDE1,
  ModalDE2,
  ModalDE3,
  ModalDE4,
  React
};