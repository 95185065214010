import React from 'react';
import { useInView } from 'react-intersection-observer';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Hyperlink from 'src/components/_shared/hyperlink/hyperlink';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import AvatarMessage from 'src/components/desktop/avatarMessage/avatarMessage';
import { ColumnContent, Wrapper } from './styles';

const LeftColContent = ({
  body, images, dark, audio, audioImage, audioSize, avatarImage,
  style, smallerTitle, largeHeader, smallerText, customTreshold,
  customWidth,
}) => {
  const [ref, inView] = useInView({
    threshold: customTreshold || 0.8,
  });
  return (
    <ColumnContent
      dark={dark}
      ref={ref}
      visible={inView}
      style={style}
      largeHeader={largeHeader}
      smallerTitle={smallerTitle}
      smallerText={smallerText}
      customWidth={customWidth}
    >
      <MDXProvider components={{
        Wrapper,
        Hyperlink,
        AvatarMessage,
        AudioPlayer,
        p: (props) => <p {...props} tabIndex="0" />,
      }}
      >
        <MDXRenderer images={images} visible={inView} audio={audio} avatarImage={avatarImage} audioImage={audioImage} size={audioSize}>{body}</MDXRenderer>
      </MDXProvider>
    </ColumnContent>
  );
};

export default LeftColContent;
