/* eslint-disable max-len */
/* eslint-disable import/named */
import React from 'react';
import { AnimatePresence } from 'framer-motion';
import {
  ModalCurtain, MobileModalWrapper, StyledModalContainer, StyledModal, ModalText, CloseIcon, StyledImage,
} from './styled';
import {
  fadeVariant, unfoldVariant, modalTextVariant, closeIconVariant,
} from './animations';


const Modal = (props) => {
  const {
    body, show, image, close, clear,
  } = props;
  return (
    <AnimatePresence
      onExitComplete={clear}
    >
      {show
    && (
      <ModalCurtain
        initial="exit"
        animate="enter"
        exit="exit"
        variants={fadeVariant}
      >
        <MobileModalWrapper
          initial="exit"
          animate="enter"
          exit="exit"
          variants={unfoldVariant}
        >
          {/* {image
          && (
            <StyledImage fixed={image} body={body} />
          )} */}
          {body && (
          <StyledModalContainer>

            <StyledModal>

              <ModalText
                initial="exit"
                animate="enter"
                exit="exit"
                variants={modalTextVariant}
              >
                {body}
              </ModalText>
            </StyledModal>
          </StyledModalContainer>
          )}
          <CloseIcon
            onClick={close}
            initial="exit"
            animate="enter"
            exit="exit"
            variants={closeIconVariant}
          />
        </MobileModalWrapper>

      </ModalCurtain>
    )}
    </AnimatePresence>
  );
};

export default Modal;
