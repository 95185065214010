import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "HU",
  "name": "Finnország",
  "title": "modal-2-3-1-4"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0" style={{
      fontSize: '2.2rem'
    }}>A FINN POLGÁRHÁBORÚ</h2>
    <p tabIndex="0">
Finnország az októberi bolsevista fordulatig az orosz birodalom része volt, élve Németország támogatásával és kihasználva az Oroszországban kialakult fejetlenséget 1917 decemberében kikiáltotta függetlenségét. A bolsevikok ugyan elismerték az állam függetlenségét, de nem mondtak le a „forradalom exportjáról” Finnország irányába. A „<strong>Vörös Gárda</strong>” Helsinkiben a volt orosz helyőrségek leszerelt katonáinak támogatása mellett kikiáltotta a Szovjet Köztársaságot. Az orosz bolsevisták ezeknek az erőknek „testvéri segítséget” nyújtva fegyveres intervencióba kezdtek Finnország ellen. Az ország északi részein <strong>Karl Mannerheim</strong>, svéd önkéntesek és Németország támogatásával nemzeti kormányt és hadsereget alakított, amelyeknek sikerült a „vörösöket” Oroszországba visszaszorítani. Finnország az I. világháborút követően szabad és független állam maradt.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      