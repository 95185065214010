/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch } from 'react-redux';
import actions from 'src/store/actions';
import { isMobile } from 'react-device-detect';
import hyperlinkDot from 'src/assets/images/_shared/hyperlink-dot.svg';
import {
  InfoIcon, Container, Text, DotIcon,
} from './styled';

const Hyperlink = ({
  children, modalType, body, image, style, dot, noIcon, color, containerStyle,
}) => {
  const dispatch = useDispatch();
  const dispatchModal = () => {
    dispatch({ type: actions.SET_MODAL_CONTENTS, payload: body });
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: image });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: (isMobile ? 'mobile' : modalType) });
  };
  const openModal = (event) => {
    if (event.key === 'Enter') {
      dispatchModal();
    }
  };
  return (
    <>
      <Container onClick={dispatchModal} onKeyDown={openModal} style={containerStyle}>
        {noIcon ? null : (dot ? <DotIcon><img src={hyperlinkDot} /></DotIcon> : <InfoIcon>i</InfoIcon>)}
        <Text isMobile={isMobile} tabIndex={0} style={style} color={color}>{children}</Text>
      </Container>
    </>
  );
};

export default Hyperlink;
