import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
  border-bottom: 1px solid rgba(255,255,255,0.1);
`;
