import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "RU",
  "name": "Венгрия",
  "title": "modal-2-3-1-3"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Революция в Венгрии</h2>
    <p tabIndex="0">
    Поражение в Первой мировой войне дискредитировало династию Габсбургов и привело к распаду Австро-Венгрии. Осенью 1918 года в Венгрии и Австрии произошла революция, в результате которой император и король были отстранены от власти. Коммунисты, отправленные в Венгрию из Москвы, пытались захватить власть в Венгрии уже в феврале 1919 года, но попытка переворота не удалась. However, the attempted coup was unsuccessful. Они пришли к власти только 21 марта 1919 года в условиях компрометации президента Михая Каройи и правительства Денеша Беринкеи. В тот день вместе с социал-демократами они создали <strong>Венгерскую советскую республику</strong> под руководством <strong>Белы Куна</strong>. В стране воцарился <strong>“красный террор”</strong>, по образцу того, который большевики установили в России. 
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      