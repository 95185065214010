import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import ArrowSvg from 'images/_shared/Arrow-down.svg';
import actions from 'src/store/actions';
import flagPL from 'src/assets/images/_shared/flag_pol.svg';
import flagEN from 'src/assets/images/_shared/flag_eng.svg';
import flagDE from 'src/assets/images/_shared/flag_ger.svg';
import flagRU from 'src/assets/images/_shared/flag_rus.svg';
import flagFR from 'src/assets/images/_shared/flag_fra.svg';
import flagGL from 'src/assets/images/_shared/flag_gla.svg';
import flagUA from 'src/assets/images/_shared/flag_ukr.svg';
import {
  SelectorWrapper,
  CircleBorder,
  FlagCircle,
  Dropdown,
  DropdownElement,
  SelectedFlagAndArrow,
  Arrow,
} from './styled';
import { locales } from '../../../../../globals';

const LanguageSelector = ({ path }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState(null);

  const getLangFromPath = () => {
    const pathname = path;
    const diffLanguages = ['en', 'fr', 'hu', 'ru', 'ua', 'de'];
    let result = 'PL';

    pathname
      && pathname.split('/').forEach((el) => {
        const lang = diffLanguages.find((language) => language === el);
        if (lang) result = lang.toUpperCase();
      });

    return result;
  };

  useEffect(() => {
    const pathLocale = getLangFromPath();
    if (pathLocale) setSelectedLocale(pathLocale);
  }, [path]);

  const {
    routesPL,
    routesEN,
    routesDE,
    routesFR,
    routesRU,
    routesUA,
    routesHU,
  } = useStaticQuery(graphql`
    {
      routesPL: mdx(
        frontmatter: {
          title: { eq: "routes-navigation" }
          language: { eq: "PL" }
        }
      ) {
        exports {
          routes {
            route
          }
        }
      }
      routesEN: mdx(
        frontmatter: {
          title: { eq: "routes-navigation" }
          language: { eq: "EN" }
        }
      ) {
        exports {
          routes {
            route
          }
        }
      }
      routesDE: mdx(
        frontmatter: {
          title: { eq: "routes-navigation" }
          language: { eq: "DE" }
        }
      ) {
        exports {
          routes {
            route
          }
        }
      }
      routesFR: mdx(
        frontmatter: {
          title: { eq: "routes-navigation" }
          language: { eq: "FR" }
        }
      ) {
        exports {
          routes {
            route
          }
        }
      }
      routesRU: mdx(
        frontmatter: {
          title: { eq: "routes-navigation" }
          language: { eq: "RU" }
        }
      ) {
        exports {
          routes {
            route
          }
        }
      }
      routesUA: mdx(
        frontmatter: {
          title: { eq: "routes-navigation" }
          language: { eq: "UA" }
        }
      ) {
        exports {
          routes {
            route
          }
        }
      }
      routesHU: mdx(
        frontmatter: {
          title: { eq: "routes-navigation" }
          language: { eq: "HU" }
        }
      ) {
        exports {
          routes {
            route
          }
        }
      }
    }
  `);

  const languageStrings = {
    PL: 'Polski',
    EN: 'English',
    DE: 'Deutsch',
    FR: 'France',
    RU: 'Pусский',
    UA: 'Ukraine',
    HU: 'Hungry',
  };

  const flags = {
    PL: flagPL,
    EN: flagEN,
    DE: flagDE,
    FR: flagFR,
    RU: flagRU,
    UA: flagUA,
    HU: flagGL,
  };

  const routes = {
    PL: routesPL.exports.routes,
    EN: routesEN.exports.routes,
    DE: routesDE.exports.routes,
    FR: routesFR.exports.routes,
    RU: routesRU.exports.routes,
    UA: routesUA.exports.routes,
    HU: routesHU.exports.routes,

  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const setLocaleInUrl = (locale) => {
    const URLlocale = selectedLocale;

    const currentRouteIndex = routes[URLlocale.toUpperCase()].findIndex(
      (element) => encodeURI(element.route) === path,
    );

    return routes[locale][currentRouteIndex].route;
  };

  // const localesTemp = ['PL', 'EN', 'DE', 'RU', 'FR', 'UA']

  return (
    <SelectorWrapper>
      <SelectedFlagAndArrow onClick={toggleDropdown}>
        <CircleBorder>
          <FlagCircle>
            <div
              style={{ backgroundImage: `url(${flags[selectedLocale]})` }}
              id="flag"
            />
          </FlagCircle>
        </CircleBorder>
        <Arrow src={ArrowSvg} turned={dropdownVisible} />
      </SelectedFlagAndArrow>
      <Dropdown>
        <AnimatePresence>
          {dropdownVisible
            && locales
              .filter((locale) => locale !== selectedLocale)
              .map((locale) => (
                <Link
                  to={`${setLocaleInUrl(locale)}`}
                  key={languageStrings[locale]}
                // onClick={() => handleLanguageChange(locale)}
                >
                  <DropdownElement
                    initial={{ maxHeight: 0, opacity: 0 }}
                    animate={{ maxHeight: 35, opacity: 1 }}
                    exit={{ maxHeight: 0, opacity: 0 }}
                    transition={{
                      duration: 1,
                      delay: 0,
                      ease: [0.82, 0.0, 0.195, 1.0],
                    }}
                    // onClick={() => handleLanguageChange(locale)}
                    key={`dropdown${Math.random(1000)}`}
                  >
                    <CircleBorder>
                      <FlagCircle>
                        <div
                          style={{ backgroundImage: `url(${flags[locale]})` }}
                          id="flag"
                          onClick={toggleDropdown}
                        />
                      </FlagCircle>
                    </CircleBorder>
                  </DropdownElement>
                </Link>
              ))}
        </AnimatePresence>
      </Dropdown>
    </SelectorWrapper>
  );
};

export default LanguageSelector;
