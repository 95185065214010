import styled from 'styled-components';

export const InfoIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  font-family: "Red Hat Display", sans-serif;
  border-radius: 100%;
  color: white;
  background-color: #de2831;
  font-weight: 700;
  font-size: 1rem;
  margin: 0 5px;
`;

export const DotIcon = styled.span`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px; 
  vertical-align: middle;
  img {
    width: 1rem;
  }
`;

export const Container = styled.span`
  display: block;
  position: relative;
  display: inline;
  cursor: pointer;
`;

export const Text = styled.span`
  font-family: "Red Hat Display", sans-serif;
  /* padding-left: 1.7rem; */
  color: ${(props) => (props.color ? props.color : '#de2831')};
  font-weight: bold;
  text-transform: ${(props) => (props.isMobile ? 'uppercase' : 'none')};

`;
