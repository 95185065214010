import ModalFR1 from "../../../../src/markdown/fr/chapter_2/sub_3/modals/modal-2-3-1-1.mdx";
import ModalFR2 from "../../../../src/markdown/fr/chapter_2/sub_3/modals/modal-2-3-1-2.mdx";
import ModalFR3 from "../../../../src/markdown/fr/chapter_2/sub_3/modals/modal-2-3-1-3.mdx";
import ModalFR4 from "../../../../src/markdown/fr/chapter_2/sub_3/modals/modal-2-3-1-4.mdx";
import React from 'react';
export default {
  ModalFR1,
  ModalFR2,
  ModalFR3,
  ModalFR4,
  React
};