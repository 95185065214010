import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "EN",
  "name": "Hungary",
  "title": "modal-2-3-1-3"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Revolution in Hungary</h2>
    <p tabIndex="0"> Defeat in the Great War discredited the House of Habsburg and ultimately led to the break-up of the Austro-Hungarian Empire. In the autumn of 1918, a revolution in Hungary and Austria ended up ousting the emperor. In February 1919, Communists sent to Hungary from Moscow attempted to seize power. However, the attempted coup was unsuccessful. On 21 March 1919 they achieved their goals when President Mihály Károlyi and Dénes Berinkey’s government were left discredited. That day, the Communists and Social Democrats established the <strong>Hungarian Soviet Republic</strong>, led by <strong>Béla Kun</strong>. The <strong>“Red Terror”</strong>, modelled on the Bolsheviks in Russia, spread throughout the country. One of the activities of the Communists consisted in the formation of the <strong>“Lenin Boys”</strong>, armed gangs determined to suppress the counterrevolution. The Hungarian Communists established the short-lived <strong>Slovak Soviet Republic</strong>. Romanian armies collaborating with the Triple Entente brought an end to the Hungarian Soviet Republic. The Romanians had been occupying the majority of its territory, which included Budapest. After the fall of the Communists, power was seized by <strong>Admiral Miklós Horthy</strong> supported extreme right-wing insurgents. The new government would end up repressing the representatives and supporters of the previous regime. 
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      