import React from 'react';
import { getLangFromPath } from 'src/utils/index.js';
import { Link } from 'gatsby';
import { LogoImg } from './styles';

const Logo = ({ theme }) => {
  const lang = getLangFromPath();
  const url = require(`images/_shared/logo_${lang === 'PL' ? 'PL' : 'EN'}.svg`);

    return <Link to={`/${lang.toLowerCase()}`}><LogoImg src={url} alt="logo" style={{ filter: theme.defaultColor !== '#a6a59e' && 'invert(0.8)' }} /></Link>;
}

export default Logo;
