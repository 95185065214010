import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { sidebarAdjustment } from '../../../../../globals';

const arrowDown = keyframes`
  from {
    transform: translate3d(0, -4px, 0);
  }
  to {
    transform: translate3d(0, 10px, 0);
  }
`;

const arrowOpacity = keyframes`
  0%   {opacity: 0}
  50%  {opacity: 1}
  100% {opacity: 0}
`;

export const Wrapper = styled(motion.div)`
  position: fixed;
  bottom: 15px;
  left: ${50 + sidebarAdjustment}%;
  transform: translateX(-50%);
  cursor: pointer;
  width: 1.4rem;
`;

export const Icon = styled.img`
  opacity: 0.7;
  width: 1.4rem;
`;

export const Arrow = styled.img`
  position: relative;
  animation: 1.4s ${arrowDown} ease-in-out infinite forwards,
    1.4s ${arrowOpacity} ease-in-out infinite;
`;
