import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "RU",
  "name": "Финляндия",
  "title": "modal-2-3-1-4"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Финская Гражданская Война</h2>
    <p tabIndex="0">
    Финляндия, которая оставалась частью Российской империи до Октябрьского переворота большевиков, пользуясь поддержкой Германии и беспорядками в России, провозгласила независимость в декабре 1917 года. Большевики признали независимость государства, но не собирались отказываться от "экспорта революции" в Финляндию <strong>“Красная гвардия”</strong> Хельсинки, поддерживаемая восставшими солдатами бывших русских гарнизонов, провозгласила Советскую Республику. Русские большевики оказали ей “братскую помощь” устраивая вооруженное вмешательство в Финляндии. На севере страны <strong>Карл Маннергейм</strong>,помощью шведских добровольцев и Германии создал национальное правительство и армию, которые вытеснили „красных” в Россию. После Первой мировой войны Финляндия осталась свободным и независимым государством. 
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      