import ModalUA1 from "../../../../src/markdown/ua/chapter_2/sub_3/modals/modal-2-3-1-1.mdx";
import ModalUA2 from "../../../../src/markdown/ua/chapter_2/sub_3/modals/modal-2-3-1-2.mdx";
import ModalUA3 from "../../../../src/markdown/ua/chapter_2/sub_3/modals/modal-2-3-1-3.mdx";
import ModalUA4 from "../../../../src/markdown/ua/chapter_2/sub_3/modals/modal-2-3-1-4.mdx";
import React from 'react';
export default {
  ModalUA1,
  ModalUA2,
  ModalUA3,
  ModalUA4,
  React
};