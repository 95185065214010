import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const Container = styled.div`
  background: #21211f;
  display: flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Subheader = styled(motion.p)`
    font-family: 'Abril Fatface';
    color: #f5f1ec;
    font-size: 2.5rem;
    max-width: 600px;
    text-align: center;
`;


const NotFoundPage = () => {
  const caption = [
    'Prosimy o aktualizację przeglądarki do najnowszej wersji',
    'Please download newest version of your browser'
  ];

  const [captionIndex, setCaptionIndex] = useState(0);
  const [currentCaption, setCurrentCaption] = useState(caption[captionIndex]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (captionIndex + 2 > caption.length) {
        setCaptionIndex(0);
      } else {
        setCaptionIndex(captionIndex + 1);
      }
      setCurrentCaption(caption[captionIndex]);
    }, 6000);
    return () => clearTimeout(timeout);
  }, [captionIndex]);

  return (
    <>
      <Container>
        <AnimatePresence exitBeforeEnter>
          <Subheader
            key={currentCaption}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 3,
            }}
          >
            {currentCaption}
          </Subheader>
        </AnimatePresence>
      </Container>
    </>
  );
};

export default NotFoundPage;
