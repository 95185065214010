import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "PL",
  "name": "Lew Trocki",
  "title": "modal-2-3-1-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Lew Trocki</h2>
    <p tabIndex="0">„Polscy panowie-szlachta wyszarpią chwilowe zwycięstwo - zwycięstwo maruderów - ale kiedy skończymy z Denikinem, rzucimy wszystkie nasze rezerwy na front polski”. (Lew Trocki, 15 sierpnia 1919 na łamach francuskiego „L’Internationale Communiste”)
    </p>
    <p tabIndex="0">
Był propagatorem idei <strong>rozszerzania rewolucji</strong> poza granice Rosji bolszewickiej i głównym organizatorem <strong>Armii Czerwonej</strong>.
    </p>
    <p tabIndex="0">
Przyszedł na świat jako <strong>Lejba Bronsztejn</strong> w ubogiej, chłopskiej rodzinie na południu Ukrainy (wówczas części imperium rosyjskiego). Mimo trudnych warunków finansowych, Bronsztejnowie posłali Lwa do Szkoły Realnej Św. Piotra i Pawła w Odessie. Po szkole związał się z radykalnym <strong>ruchem narodnickim</strong>, który postulował demokratyzację państwa. Z biegiem czasu poglądy Trockiego (taki przybrał pseudonim) zradykalizowały się, porzucił narodnictwo dla marksizmu. Przewodzenie własnej <strong>organizacji rewolucyjnej</strong> przypłacił dwoma latami więzienia i zsyłką na Sybir. Po dwóch latach Trocki uciekł z zesłania i wyjechał za granicę, do Europy Zachodniej. W Londynie nawiązał kontakt z <strong>Leninem</strong>
    </p>
    <p tabIndex="0">
W 1905 roku powrócił do Rosji, by wziąć udział w rewolucji. Był ostatnim przewodniczącym <strong>Piotrogrodzkiej Rady Robotniczej</strong>. Aresztowany po stłumieniu rewolucji, został aresztowany i skazany na dożywotnią, syberyjską zsyłkę. I tym razem udało mu się uciec i przedostać do Szwajcarii, a następnie do Francji. Jako zwolennik <strong>światowej rewolucji proletariatu</strong>, był zdecydowanie przeciwny I wojnie światowej. Swoje poglądy głosił na łamach rosyjskojęzycznego „Nowego Słowa”. Zagrożony wydaleniem do Rosji, zbiegł do Nowego Jorku.
    </p>
    <p tabIndex="0">
Po wybuchu rewolucji lutowej w 1917 powrócił do Rosji. We wrześniu 1917 roku przystąpił do bolszewików. W czasie <strong>rewolucji październikowej</strong> kierował działaniami zbrojnymi podczas pierwszych dni rebelii. Wkrótce objął stanowisko <strong>komisarza spraw zagranicznych Rosji bolszewickiej</strong>.
    </p>
    <p tabIndex="0">
We wrześniu 1918 roku kierownictwo polityczne bolszewików podjęło decyzję o przekształceniu Rosji sowieckiej w <strong>obóz wojskowy</strong>. Na czele Armii Czerwonej stanął Siergiej Kamieniew, ale był on podległy Rewolucyjnej Radzie Wojennej. Na czele Rady stanął Trocki, co czyniło go <strong>faktycznym dowódcą</strong> wojsk bolszewickich i – po Leninie – <strong>drugą osobą w państwie</strong>.
    </p>
    <p tabIndex="0">
Trocki był zwolennikiem idei <strong>„eksportu rewolucji”</strong> na Zachód. Początkowo uważał, że należy jej dokonać poprzez zburzenie imperiów kolonialnych w Azji, co z kolei jego zdaniem wywołałoby kryzys i <strong>rewolucję w Wielkiej Brytanii</strong>, Stanach Zjednoczonych i Francji. „Droga do Londynu i Paryża wiedzie przez Kalkutę” – miał mówić. Po wybuchu rewolucji w Niemczech zmienił zdanie i był jednym z propagatorów <strong>ataku na Polskę</strong>, którą uważał za „przegrodę” oddzielającą Rosję od Niemiec.
    </p>
    <p tabIndex="0">
      <strong>Po śmierci Lenina</strong> toczył walkę o przejęcie schedy po „wodzu rewolucji” z Lwem Kamieniewem (swoim byłym szwagrem), Grigorijem Zinowjewem i <strong>Józefem Stalinem</strong>. Ostatecznie zwyciężył ten ostatni. Trocki był stopniowo  odsuwany od najważniejszych stanowisk w ZSRS. W 1929 roku, w obawie o własne życie, opuścił sowiecką Rosję.
    </p>
    <p tabIndex="0">
Przebywał na emigracji w Turcji, Francji, Norwegii i Meksyku. Krytykował ustrój ZSRS jako odejście od idei światowej rewolucji. W tym duchu powołał <strong>IV Międzynarodówkę</strong> – organizację partii komunistycznych nieprzychylnych polityce Stalina.
    </p>
    <p tabIndex="0">
Wszędzie tropiło go <strong>NKWD</strong> (Ludowy Komisariat Spraw Wewnętrznych ZSRS, sowiecka policja polityczna). Cała rodzina Trockiego, poza jego żoną, została wymordowana na rozkaz Stalina. Sam Trocki przeżył trzy zamachy na swoje życie. Czwarty okazał się skuteczny. 20 sierpnia 1940 hiszpański komunista i agent NKWD Ramón Mercader ranił Trockiego czekanem w głowę. Niegdysiejszy twórca Armii Czerwonej zmarł po dwudziestu godzinach agonii, 21 sierpnia 1940.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      