import Modal1HU from "../../../../src/markdown/hu/chapter_2/sub_3/modals/modal-2-3-1-1.mdx";
import Modal2HU from "../../../../src/markdown/hu/chapter_2/sub_3/modals/modal-2-3-1-2.mdx";
import Modal3HU from "../../../../src/markdown/hu/chapter_2/sub_3/modals/modal-2-3-1-3.mdx";
import Modal4HU from "../../../../src/markdown/hu/chapter_2/sub_3/modals/modal-2-3-1-4.mdx";
import React from 'react';
export default {
  Modal1HU,
  Modal2HU,
  Modal3HU,
  Modal4HU,
  React
};