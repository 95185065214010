/* eslint-disable max-len */
/* eslint-disable import/named */
import React, {
  useRef, useState, createRef,
} from 'react';
import { AnimatePresence } from 'framer-motion';
import SimpleBar from 'simplebar-react';
import {
  ModalCurtain, StyledModalContainer, StyledModal, ModalText, CloseIcon, ImageOverflow, ImageWrapper, StyledImage, TopFade, BottomFade,
} from './styled';
import {
  fadeVariant, slideLeftVariant, modalTextVariant, slideRightVariant, closeIconVariant,
} from './animations';
import 'simplebar/dist/simplebar.min.css';

const Modal = ({
  body, image, show, close, clear,
}) => {
  const scrollBarRef = useRef();
  const modalContainer = createRef();
  const [topFade, setTopFade] = useState(false);
  const [bottomFade, setBottomFade] = useState(false);

  const scrollHandler = () => {
    if (modalContainer.current) {
      modalContainer.current.focus();
    }
    if (scrollBarRef.current.scrollTop > 0) setTopFade(true);
    if (scrollBarRef.current.scrollTop === 0) setTopFade(false);
    if (scrollBarRef.current.scrollHeight > scrollBarRef.current.offsetHeight) setBottomFade(true);
    if (scrollBarRef.current.scrollTop + scrollBarRef.current.offsetHeight === scrollBarRef.current.scrollHeight) setBottomFade(false);
  };

  const animComplete = () => {
    modalContainer.current.focus();
    scrollHandler();
  };
  const closeModal = (event) => {
    if (event.key === 'Escape') {
      close();
    }
  };
  const closeModal2 = () => {
    close();
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const closeModalIconHandler = (event) => {
    if (event.key === 'Enter') {
      close();
    }
  };
  return (
    <AnimatePresence
      onExitComplete={clear}
    >
      {show
      && (
      <ModalCurtain
        initial="exit"
        animate="enter"
        exit="exit"
        variants={fadeVariant}
        onKeyDown={closeModal}
        onClick={closeModal2}
      >
        <StyledModalContainer onClick={stopPropagation}>
          <TopFade
            initial="exit"
            animate="enter"
            exit="exit"
            variants={slideLeftVariant}
            show={topFade}
          />
          <BottomFade
            initial="exit"
            animate="enter"
            exit="exit"
            variants={slideLeftVariant}
            show={bottomFade}
          />
          <SimpleBar
            scrollableNodeProps={{ ref: scrollBarRef }}
            style={{ height: '80vh' }}
            onScroll={scrollHandler}
          >
            <StyledModal
              initial="exit"
              animate="enter"
              exit="exit"
              variants={slideLeftVariant}
              onAnimationComplete={animComplete}
            >

              <ModalText
                initial="exit"
                animate="enter"
                exit="exit"
                variants={modalTextVariant}
                ref={modalContainer}
              >
                {body}
              </ModalText>
            </StyledModal>
          </SimpleBar>
          <CloseIcon
            onClick={close}
            initial="exit"
            animate="enter"
            exit="exit"
            variants={closeIconVariant}
            onKeyDown={closeModalIconHandler}
          />
        </StyledModalContainer>
        {image
      && (
        <ImageOverflow onClick={stopPropagation}>
          <ImageWrapper
            initial="exit"
            animate="enter"
            exit="exit"
            variants={slideRightVariant}
          >
            <StyledImage
              fixed={image}
            />
          </ImageWrapper>
        </ImageOverflow>
      )}
      </ModalCurtain>
      )}
    </AnimatePresence>
  );
};

export default Modal;
