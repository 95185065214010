import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "EN",
  "name": "Germany",
  "title": "modal-2-3-1-2"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Revolution in Germany</h2>
    <p tabIndex="0">In November 1918, the internal situation within the German Empire was worse than it had been on the front. The country had grown tired of a conflict that had dragged on for four years. Economic problems caused by the naval blockade of the country proved to be particularly taxing. For years, the Germans hadn’t seen either coffee or chocolate; whereas potatoes and bread without sawdust or other additives were considered a luxury. <strong>
  Substitutes (ersätze)
      </strong> for the most important products were generally used. Over 11,000 of such substitutes had been elaborated upon and officially propagated.  
    </p>
    <p tabIndex="0">
ERSÄTZE:
    </p>
    <p tabIndex="0">
Butter -> fat with yellow dye
    </p>
    <p tabIndex="0">
Coffee -> infusion of acorn flour
    </p>
    <p tabIndex="0">
Meat/sausage -> potato black pudding
    </p>
    <p tabIndex="0">
The impasse on the Western Front and economic hardship led to <strong>mutinies amongst soldiers and discontented civilians</strong>. In early November 1918, seamen staged a mutiny and took control over key port and industrial cities. They sought to establish labour and military councils. Social Democrats from the SPD party did not support the mutiny. However, the seamen were supported by Marxists-Maximalists from the <strong>Spartacus League</strong>, who had already been campaigning for the transformation of Germany into a state with a structure similar to that found in Soviet Russia.    
    </p>
    <p tabIndex="0">
The revolution spread throughout the entire country. German Emperor Wilhelm II was <strong>forced to abdicate</strong> and he fled to the neutral Netherlands. On 9 November, the leader of the the Spartacus League, <strong>Karl Libknecht</strong>, proclaimed a <strong>socialist republic</strong>. Military leaders, who had been previously ruling the country, saw a chance to repress the revolution in alliance with the Social Democrats. The cooperation between the army and SPD allowed to prevent the victory of the communists.
    </p>
    <p tabIndex="0">
The climax of the revolution was the so-called “Spartacist Uprising” - an armed attack of the fighting squads of the Communist Party of Germany against the government of the Social Democrats. The authorities managed to quell the rebellion and kill the leaders of the uprising, among them <strong>Karl Liebknecht</strong> and <strong>Rosa Luxemburg</strong>.  
    </p>
    <p tabIndex="0">
The revolutionary forces survived longest in Bavaria, where they established the <strong>Bavarian Soviet Republic</strong>. The regime collapsed in early May 1919, following military intervention.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      