
export const transition = {
  ease: [0.820, 0.000, 0.195, 1.000],
  duration: 1,
};

export const fadeVariant = {
  exit: {
    opacity: 0,
    transition,
  },
  enter: {
    opacity: 1,
    transition,
  },
};

export const unfoldVariant = {
  exit: {
    transform: 'translateX(100%)',
    transition,
  },
  enter: {
    transform: 'translateX(0%)',
    transition,
  },
};

export const modalTextVariant = {
  exit: {
    opacity: 0, transition: { ...transition, duration: 1, delay: 0 },
  },
  enter: { opacity: 1, transition: { ...transition, duration: 1, delay: 0.2 } },
};

export const slideRightVariant = {
  exit: {
    transform: 'translateX(-100%)',
    transition,
  },
  enter: {
    transform: 'translateX(0%)',
    transition,
  },
};

export const closeIconVariant = {
  exit: {
    transform: 'scale(0.01)',
    transition: { ...transition, delay: 0, duration: 0.4 },
  },
  enter: {
    transform: 'scale(1)',
    transition: { ...transition, delay: 0.6, duration: 0.4 },
  },

};
