import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "PL",
  "name": "Węgry",
  "title": "modal-2-3-1-3"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Rewolucja na Węgrzech</h2>
    <p tabIndex="0">Klęska w I wojnie światowej skompromitowała dynastię Habsburgów i spowodowała rozpad Austro-Węgier. Jesienią 1918 roku na Węgrzech i w Austrii dokonała się rewolucja, która odsunęła cesarza i króla od władzy. Komuniści przysłani na Węgry z Moskwy próbowali przejąć władzę na Węgrzech już w lutym 1919 roku, jednak próba puczu nie powiodła się. Władzę przejęli dopiero w 21 marca 1919 wobec kompromitacji prezydenta Mihaly’ego Karolyi’ego i rządu Denesa Berinkeia. Tego dnia wraz z socjaldemokratami i powołali <strong>Węgierską Republikę Rad</strong> pod przywództwem <strong>Beli Kuna</strong>. W kraju zapanował „czerwony terror” wzorowany na tym, który bolszewicy zaprowadzili w Rosji. Jednym z działań komunistów było utworzenie bojówek walczących z kontrrewolucją – <strong>„Chłopców Lenina”</strong>.  Węgierskim komunistom udało się też utworzenie na krótko <strong>Słowackiej Republiki Rad</strong>. Kres WRR zadały na początku sierpnia 1919 roku – współpracujące z Ententą – wojska rumuńskie, które okupowały większość terytorium wraz z Budapesztem. Po upadku rządów komunistycznych władzę przejął adm. Miklos Horthy. Skrajna prawica represjonowała przedstawicieli poprzedniego systemu.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      