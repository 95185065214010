import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
    height: 2px;
    width: 100%;
    background-color: rgba(255,255,255,0.3);
    position: fixed;
    top: calc(3rem + 1.5rem);
    left: 0;
    z-index: 1;
`;

export const ProgressIndicator = styled.div`
    transition: width 0.2s ease-out;
    height: 2px;
    width: ${({ width }) => `${width}%`};
    background-color: #df3242;
`;
