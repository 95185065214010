import styled, { keyframes, css } from 'styled-components';
import Img from 'gatsby-image';

const rotate = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const animationEnter = () => css`
  ${rotate} 1.2s cubic-bezier(0.78, 0, 0.235, 1) 800ms forwards
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const Avatar = styled(Img)`
  width: 24.375rem !important;
  height: 27.18rem !important;

  @media (max-width: 1600px) {
    width: 12.175rem !important;
    height: 13.6rem !important;
  }
`;

export const Message = styled.div`
  background: #eeede7;
  position: absolute;
  top: 50%;
  left: 35%;
  padding: 1.5rem 2.2rem;
  border-radius: 3px;
  font-weight: 500;
  max-width: 25rem;
  font-size: 0.8rem;
  letter-spacing: 0.28px;
  line-height: 1.5rem;
  animation: ${animationEnter};
  opacity: 0;
  transform: translate(0, -50%);
  min-height: 7rem;

  @media (max-width: 1600px) {
    left: 22%;
    top: 70%;
  }

  &::before {
    content: "◀";
    font-size: 3rem;
    color: #eeede7;
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translate(-50%, -50%);
    @media (max-width: 1600px) {
      top: 40%;
      left: 1%;
      width: 3.5rem;
      height: 3.5rem;
    }
  }
`;
