import ModalRU1 from "../../../../src/markdown/ru/chapter_2/sub_3/modals/modal-2-3-1-1.mdx";
import ModalRU2 from "../../../../src/markdown/ru/chapter_2/sub_3/modals/modal-2-3-1-2.mdx";
import ModalRU3 from "../../../../src/markdown/ru/chapter_2/sub_3/modals/modal-2-3-1-3.mdx";
import ModalRU4 from "../../../../src/markdown/ru/chapter_2/sub_3/modals/modal-2-3-1-4.mdx";
import React from 'react';
export default {
  ModalRU1,
  ModalRU2,
  ModalRU3,
  ModalRU4,
  React
};