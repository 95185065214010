import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "PL",
  "name": "Finlandia",
  "title": "modal-2-3-1-4"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Fińska wojna domowa</h2>
    <p tabIndex="0">Finlandia, pozostająca do październikowego przewrotu bolszewickiego częścią imperium rosyjskiego, korzystając ze wsparcia Niemiec i z zamętu panującego w Rosji, ogłosiła niepodległość w grudniu 1917 roku. Bolszewicy uznali niepodległość państwa, nie zamierzali jednak rezygnować z „eksportu rewolucji” do Finlandii. <strong>„Czerwone gwardie”</strong> w Helsinkach, wsparte przez zrewoltowanych żołnierzy dawnych rosyjskich garnizonów, proklamowały Sowiecką Republikę. Rosyjski bolszewicy udzielili jej „bratniej pomocy” podejmując zbrojną interwencję w Finlandii. Na północy kraju <strong>Karl Mannerheim</strong>, z pomocą szwedzkich ochotników oraz Niemiec, stworzył narodowy rząd oraz wojsko, które wyparło „czerwonych” do Rosji.  Finlandia pozostała po I wojnie światowej wolnym i niepodległym państwem. 
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      