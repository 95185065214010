import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "DE",
  "name": "LEO TROTZKI - DIE NR. 2 IN DER REVOLUTION",
  "title": "modal-2-3-1-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Leo Trotzki - die Nr. 2 in der Revolution</h2>
    <p tabIndex="0">„Der polnische Adel wird einen augenblicklichen Sieg erringen - einen Sieg der Marodeure - aber wenn wir mit Denikin hier fertig sind, werden wir alle unsere Reserven an die polnische Front schicken“ 
Leo Trotzki, am 15. August 1919 in der französischen Zeitschrift „L’Internationale Communiste”
    </p>
    <p tabIndex="0">
Er propagierte die Idee einer Erweiterung der Revolution über die Grenzen des bolschewistischen Russlands hinaus und war der Hauptorganisator der Roten Armee.
    </p>
    <p tabIndex="0">
Er kam als Lew Dawidowitsch Bronstein in einer armen Bauernfamilie im Süden von Ukraine (damals einem Teil des russischen Imperiums) zur Welt. Trotz der schwierigen finanziellen Verhältnisse haben die Bronsteins den Lew zur Realschule zum hl. Paulus in Odessa geschickt. Nach der Schule hat er sich einer radikalen, nationalen Bewegung angeschlossen, die eine Demokratisierung des Staates postulierte. Mit der Zeit wurden die Ansichten von Trotzki (das war sein Pseudonym) zunehmend radikaler,er verließ die Bewegung der „Narodniki“ (Volksfreunde) zugunsten des Marxismus. Für die Leitung seiner eigenen revolutionären Organisation hat er mit zwei Jahren Haft und Verbannung nach Sibirien bezahlt. Nach zwei Jahren floh Trotzki aus der Verbannung und ging ins Ausland, nach Westeuropa. In London hat er mit Lenin Kontakt aufgenommen.
    </p>
    <p tabIndex="0">
1905 kehrte er nach Russland zurück, um an der Revolution teilzunehmen. Er war der letzte Anführer des Petrograder Arbeiterrates. Nach der Erstickung der Revolution wurde er verhaftet und zu einer lebenslangen Verbannung nach Sibirien verurteilt Und auch diesmal gelang es ihm zu fliehen und nach Schweiz, und dann nach Frankreich zu kommen. Als Anhänger der Weltrevolution des Proletariats war er entschieden gegen den ersten Weltkrieg. Seine Ansichten teilte er in der russischsprachigen Zeitschrift „Nasche Slowo“ („Unser Wort“) mit. Da ihm eine Herausgabe an Russland drohte, floh er nach New York.
    </p>
    <p tabIndex="0">
Nach dem Ausbruch der Februarrevolution 1917 kehrte er nach Russland zurück. Im September 1917 schloss er sich den Bolschewiki an. Während der Oktoberrevolution leitete er die Waffenhandlungen während der ersten Tage der Rebellion.  In Kürze wurde er zum Kommisar für ausländische Angelegenheiten des bolschewistischen Russlands.
    </p>
    <p tabIndex="0">
Im September 1918 hat die politische Führung der Bolschewiki den Beschluss über die Umgestaltung des sowjetischen Russlands in einen Kriegslager gefasst. An der Spitze der Roten Armee stand Siergiej Kamieniew, der aber ein Unterstellter des Revolutionären Kreigsrates war. An der Spitze des Rates stand Trotzki, was ihn zum tatsächlichen Anführer der bolschewistischen Armee machte, der somit - nach Lenin - die zweite wichtigste Person im Staat war.  
    </p>
    <p tabIndex="0">
Trotzki vertrat die Idee eines„Exports der Revolution” nach Westen. Anfäglich war er der Ansicht, dass man die Revolution durch die Zerstörung der kolonialen Imperien in Asien realisieren sollte, was wiederum seiner Meinung nach eine Krise und Revolution in Großbritannien, in den Vereinigten Staaten und in Frankreich auslösen würde. „Der Weg nach London und Paris führt über Kalkutta“ - soll er gesagt haben. Nach dem Ausbruch der Revolution in Deutschland hat er seine Meinung geändert und war einer der Befürworter des Angriffs auf Polen, die eine Russland von Deutschland trennende  „Schranke“ darstellte.
    </p>
    <p tabIndex="0">
Nach Lenins Tod kämpfte er um die Übernahme des Erbes nach dem „Anführer der Revolution“, zusammen mit Lew Kamenew (seinem ehemaligen Schwager), Grigori Sinowjew und Josef Stalin. Letztendlich hat aber dieser letztere gesiegt.  Trotzki wurde stufenweise von den wichtigsten Posten in der UdSSR entbunden. 1929 hat er das sowjetische Russland verlassen, weil er um sein Leben fürchtete. 
    </p>
    <p tabIndex="0">
Er hielt sich im Exil in Türkei, Frankreich, Norwegen und Mexiko auf. Er kritisierte das politische System der UdSSR als eine Abweichung von der Idee der weltweiten Revolution. In diesem Sinne hat er die IV. Internationale ins Leben gerufen - eine Organisation der kommunistischen Parteien, die mit Stalins Politik nicht einverstanden waren.
    </p>
    <p tabIndex="0">
Er wurde überall von NKWD (dem Volkskommissariat für innere Angelegenheiten der UdSSR , der politischen Polizei der Sowjets, verfolgt). Die ganze Familie von Trotzki, außer seiner Frau, wurde auf Befehl von Stalin ermordet. Trotzki selbst hat drei Anschläge auf sein Leben überlebt. Der vierte hat sich als erfolgreich erwiesen. Am 20. August 1940 hat der spanische Kommunist und NKWD-Agent Ramón Mercader Trozki mit einem Eispickel am Kopf verletzt. Der ehemalige Gründer der Roten Armee verstarb nach 20 Stunden Todeskampf, am 21. August 1940. 
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      