import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "HU",
  "name": "Németország",
  "title": "modal-2-3-1-2"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0" style={{
      fontSize: '2.2rem'
    }}>FORRADALOM NÉMETORSZÁGBAN</h2>
    <p tabIndex="0">
  1918 novemberében a Német Császárság belpolitikai helyzete rosszabb volt, mint a Nagy Háború frontjain tapasztalt helyzet. Az ország belefáradt a négy éve tartó konfliktusba. Leginkább megterhelőnek mindenekelőtt a gazdasági problémák bizonyultak, amelyeket az ország ellen alkalmazott tengeri blokád okozott. A németek évek óta nem láttak kávét, csokoládét, luxus volt a krumpli vagy a fűrészpor- és egyéb adalékmentes kenyér. Általánosan elterjedtek az élelmiszer <strong>pótlékok („Ersatz”-ok)</strong>, melyekből 11 ezernél több fajtát találtak fel.
    </p>
    <p tabIndex="0">
ERSATZOK:
    </p>
    <p tabIndex="0">
Vaj -> sárga színezékkel kevert zsiradék
    </p>
    <p tabIndex="0">
Kávé -> makklisztből készült lé
    </p>
    <p tabIndex="0">
Hús/kolbász -> burgonya alapú felvágott
    </p>
    <p tabIndex="0">
A nyugati fronton tapasztalt tehetetlenség és a gazdasági összeomlás a <strong>katonák zendüléseihez és a civilek elégedetlenségéhez</strong> vezetett. 1918 novembere elején fellázadtak a tengerészek és elfoglaltak kulcsfontosságú kikötő- és iparvárosokat, valamint munkástanácsokat és katonai tanácsokat kezdtek alapítani. A lázadást nem támogatták a német szociáldemokraták (SDP), a tengerészek oldalára a <strong>Spartakus-szövetségben</strong> tömörülő marxista-maximalisták álltak, ők már korábban is Németország átalakítása mellett agitáltak, hogy a Szovjet Oroszországban uralkodó rendszerhez hasonlóvá alakítsák az országot.
    </p>
    <p tabIndex="0">
A forradalom majdnem az egész országra kiterjedt. II. Vilmos császárt <strong>lemondásra kényszerítették </strong>– ő a semleges Hollandiában talált menedéket. November 9-én a Spartakus vezetője <strong>Karl Libknecht</strong> kikiáltotta a <strong>szocialista köztársaság</strong> létrejöttét. A katonák, akik mindaddig az állam tényleges vezetését gyakorolták, felismerték, hogy a forradalom legyűrésére a szociáldemokratákkal együtt van esélyük. A hadsereg és az SDP együttműködésének köszönhetően sikerült megakadályozni a kommunisták győzelmét.
    </p>
    <p tabIndex="0">
A forradalom tetőpontja az úgynevezett „<strong>Spartakus-felkelés</strong>” volt – azaz Németország Kommunista Pártja fegyveres különítményeinek fellépése a szociáldemokrata kormánnyal szemben. A helyzetet a hatalomnak sikerült ellenőrzése alá vonni és a felkelés vezetői elestek. Köztük volt Karl Liebknecht és <strong>Rosa Luxemburg</strong>.
    </p>
    <p tabIndex="0">
A forradalmi erők legtovább Bajorországban tartottak ki, ahol kikiáltották a <strong>Bajor Tanácsköztársaságot</strong> is. A rezsim a hadsereg beavatkozásáig kitartott, 1919. május elején bukott meg.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      