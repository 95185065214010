/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable import/named */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'src/store/actions';
import RightModal from './modalRight/modalRight';

const Modal = (props) => {
  const show = useSelector((state) => state.showArmyModal);
  const body = useSelector((state) => state.armyModalContents);
  const dispatch = useDispatch();
  const closeHandler = () => {
    dispatch({ type: actions.SET_SHOW_ARMY_MODAL, payload: false });
  };
  const clearHandler = () => {
    dispatch({ type: actions.CLEAR_ARMY_MODAL });
  };

  return <RightModal {...props} show={show} body={body} close={closeHandler} clear={clearHandler} />;

};

export default Modal;
