import Modal1 from "../../../../src/markdown/pl/chapter_2/sub_3/modals/modal-2-3-1-1.mdx";
import Modal2 from "../../../../src/markdown/pl/chapter_2/sub_3/modals/modal-2-3-1-2.mdx";
import Modal3 from "../../../../src/markdown/pl/chapter_2/sub_3/modals/modal-2-3-1-3.mdx";
import Modal4 from "../../../../src/markdown/pl/chapter_2/sub_3/modals/modal-2-3-1-4.mdx";
import React from 'react';
export default {
  Modal1,
  Modal2,
  Modal3,
  Modal4,
  React
};