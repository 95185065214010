import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "RU",
  "name": "Германия",
  "title": "modal-2-3-1-2"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Революция в Германии</h2>
    <p tabIndex="0">В ноябре 1918 года внутренняя обстановка в Германской империи была хуже, чем на фронте Первой мировой войны. Страна устала от конфликта, который длился четыре года. Прежде всего, обременительными были экономические проблемы, вызванные морской блокадой страны. Немцы годами не видели кофе и шоколад, картофель и хлеб без опилок и других добавок были роскошью. Широко использовались <strong>заменители (эрзацы)</strong> самых необходимых продуктов – их было разработано более 11 тыс.  
    </p>
    <p tabIndex="0">
ЭРЗАЦЫ:
    </p>
    <p tabIndex="0">
Масло -> жир с желтым красителем
    </p>
    <p tabIndex="0">
Кофе -> настой на основе желудевой муки
    </p>
    <p tabIndex="0">
Мясо/колбасные изделия -> картофельная колбаса
    </p>
    <p tabIndex="0">
Патовая ситуация на Западном фронте и экономический крах вызвали <strong>восстания среди солдат и недовольство мирных жителей</strong>. В начале ноября 1918 года восстали моряки и захватили ключевые портовые и промышленные города, начали формировать рабочие и солдатские советы. Восстание не было поддержано немецкими социал-демократами от партии СДПГ, вместо этого на сторону моряков стали марксисты-максималисты из <strong>Союза Спартака</strong>, которые ранее агитировали за превращение Германии в страну с системой, подобной той, которая существовала в Советской России.
    </p>
    <p tabIndex="0">
Революция охватила почти всю страну. Император Вильгельм II был <strong>вынужден отречься от престола</strong> и бежать в нейтральную Голландию. 9 ноября лидер Союза Спартака <strong>Карл Либкнехт</strong>, провозгласил <strong>социалистическую республику</strong>. Военные, которые до сих пор фактически правили в стране, увидели возможность подавить революцию в союзе с социал-демократами. Благодаря сотрудничеству армии и СДПГ, коммунисты были лишены победы.
    </p>
    <p tabIndex="0">
Кульминационной точкой революции было так называемое “Восстание спартакистов” вооруженное восстание воинствующих группировок Коммунистической партии Германии против правления социал-демократов. Власть овладела ситуацией, а лидеры восстания были убиты. Среди них были Карл Либкнехт и <strong>Роза Люксембург</strong>. 
    </p>
    <p tabIndex="0">
Революционные силы просуществовали дольше всего в Баварии, где они образовали <strong>Баварскую советскую республику</strong>. Режим рухнул после вмешательства армии в начале мая 1919 года.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      