import styled from 'styled-components';
import Img from 'gatsby-image';
import { motion } from 'framer-motion';
import { styles } from '../../../../../globals';

export const ModalCurtain = styled(motion.div)`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyledModalContainer = styled.div`
    overflow: hidden;
    width: 28%;
    min-width: 440px;
    height: 80%;
    position: relative;
    .simplebar-horizontal {
        display: none;
    }
    .simplebar-track {
        width: 0.3rem;
    }

    .simplebar-scrollbar {
        width: 100%;
        right: 0;
        &::before {
            background-color: #de2831;
            top: 0 !important;
            bottom: 0 !important;
            border-radius: 0;
            opacity: 1 !important;
        }
    }
    .simplebar-content {
        overflow-x: hidden;
    }

`;
export const StyledModal = styled(motion.div)`
    width: 100%;
    min-height: 80vh;
    padding: 0 17%;
    background-color: ${styles.colors.beigeBg};
`;

export const TopFade = styled(motion.div)`
        position: absolute;
        height: 20%;
        width: 100%;
        background-color: black;
        z-index: 1;
        background: linear-gradient(to top, transparent 0%,#f5f4ee 100%);
        transition: opacity 0.3s;
        opacity: ${(props) => (props.show ? '1' : '0')};
        pointer-events: none;
`;

export const BottomFade = styled(motion.div)`
        position: absolute;
        height: 20%;
        width: 100%;
        bottom: 0;
        background-color: black;
        z-index: 1;
        background: linear-gradient(to bottom, transparent 0%,#f5f4ee 100%);
        transition: opacity 0.3s;
        opacity: ${(props) => (props.show ? '1' : '0')};
        pointer-events: none;
`;

export const ModalText = styled(motion.div).attrs(() => ({ tabIndex: 0 }))`
    padding: 17% 0;
    &:focus {
      box-shadow:none;
      outline: none;
    }
    p {
        ${{ ...styles.fonts.modalText }}
        color: ${styles.colors.black};
        &:focus {
          box-shadow:none;
        }
      }
    h2 {
        text-align: center;
        ${{ ...styles.fonts.header }}
        color: ${styles.colors.black};
        font-weight: 300;
        &:focus {
          box-shadow:none;
        }
    }
`;

export const ImageOverflow = styled.div`
    height: 80% !important;
    width: 28%;
    min-width: 440px;
    overflow: hidden;
`;

export const ImageWrapper = styled(motion.div)`
    width: 100%;
    height: 100%;
`;

export const StyledImage = styled(Img)`
    width: 100% !important;
    height: 100% !important;
`;

export const CloseIcon = styled(motion.i).attrs(() => ({ tabIndex: 0 }))`
    width: 2.31rem;
    height: 2.31rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
    background-color: ${styles.colors.red};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    cursor: pointer;
    &::before, &::after {
        transform-origin: 50% 50%;
        position: absolute;
    }
    &::before, &::after {
        content: '';
        width: 4%;
        height: 4%;
        background-color: white;
        border-radius: 2px;
    }
    &::before {
        animation: expandL 0.7s 0.9s forwards cubic-bezier(0.820, 0.000, 0.195, 1.000);
    }
    &::after {
        animation: expandR 0.7s 0.9s forwards cubic-bezier(0.820, 0.000, 0.195, 1.000);
    }
    @keyframes expandL {
        0% {
            height: 4%;
        }
        50% {
            transform: rotate(0deg);
            height: 50%;
        }
        100% {
            transform: rotate(45deg);
            height: 50%;

        }
    }
    @keyframes expandR {
        0% {
            height: 4%;
        }
        50% {
            transform: rotate(0deg);
            height: 50%;
        }
        100% {
            transform: rotate(-45deg);
            height: 50%;

        }
    }
`;

export const TestBtn = styled.button`
    background: red;
`;
