import styled, { keyframes, css } from 'styled-components';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

const easeInOpacity = keyframes`
  0%{
    background-color: rgba(0, 0, 0, 0);
  }
  100%{
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

const borderLeft = keyframes`
  0%{
    border-left: 1px solid rgba(222,40,49, 1);
  }
  100%{
    border-left: 4px solid rgba(222,40,49, 1);
  }
`;

const fadeText = keyframes`
    0%{
        color: rgba(242,239,228,0.1);
    }
    100%{
        color: rgba(242,239,228,1);
    }
`;

const fadeTextAnimation = () => css`
      ${fadeText} 1s forwards 500ms
    `;

const borderAnimation = ({ isActive }) => {
  if (isActive) {
    return css`
      ${borderLeft} 500ms forwards 
    `;
  }
  return null;
};

export const MenuContainer = styled(motion.div)`
  margin-top: calc( 3rem + 2px);
  width: 100%;
  height: calc(( 100vh - 3rem ) - 2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 10;
`;

export const MenuLink = styled(Link)`
  position: relative;
  width: 100%;
  height: 18%;
  text-decoration: none;
  display: flex;
  color: transparent;
  align-items: center;
  justify-content: center;
  font-family: "Abril Fatface";
  font-size: 20px;
  font-weight: 400;
  animation: ${fadeTextAnimation};
`;

export const LinkBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #20201e;
  background-image: ${({ image }) => `url(${image})`};
  background-position: center;
  background-size: cover;
  z-index: -1;
  animation: ${borderAnimation};
  border-left: 0px solid black;
`;

export const Mask = styled.div`
  width: 100%;
  height: 100%;
  animation: ${easeInOpacity} 0.4s forwards 200ms;
`;
