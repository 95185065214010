import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fonts, colors, sidebarAdjustment } from '../../../../../globals';


export const TitleContainer = styled(motion.header)`
        position: fixed;
        top: 2rem;
        z-index: 2;
        left: ${50 + sidebarAdjustment}%;
        transform: translateX(-50%);
`;

export const TitleHeader = styled.h1`
        ${{ ...fonts.headline_1 }};
        font-size: 1.6rem;
        color: white;
        line-height: 1;
        margin-bottom: 0;
        text-align: center;
        ${({ dark }) => dark && `
        color: #4f4a47;
        `}
`;

export const TitleText = styled.p`
        ${{ ...fonts.page_title }}
        ${{ ...colors.light }};
        position: relative;
        text-align: center;
        ${({ dark }) => dark && `
        color: #4f4a47;
        `}
        &::after {
            content: '';
            height: 2px;
            width: 67px;
            background-color: ${colors.red.color};
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
`;
