import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import actions from 'src/store/actions';
import ArrowSvg from 'images/_shared/Arrow-down.svg';
import flagPL from 'src/assets/images/_shared/flag_pol.svg';
import flagEN from 'src/assets/images/_shared/flag_eng.svg';
import flagDE from 'src/assets/images/_shared/flag_ger.svg';
import flagRU from 'src/assets/images/_shared/flag_rus.svg';
import flagFR from 'src/assets/images/_shared/flag_fra.svg';
import flagHU from 'src/assets/images/_shared/flag_gla.svg';
import flagUA from 'src/assets/images/_shared/flag_ukr.svg';
import { getLangFromPath } from 'src/utils/index.js';
import { locales } from '../../../../../globals';
import {
  SelectorWrapper, FlagContainer, Flag, Dropdown, DropdownElement, Arrow,
} from './styles';

const LanguageSelector = ({ path, theme }) => {
  const flags = {
    PL: flagPL,
    EN: flagEN,
    DE: flagDE,
    FR: flagFR,
    HU: flagHU,
    RU: flagRU,
    UA: flagUA,
  };

  const dispatch = useDispatch();
  const [selectedLocale, setSelectedLocale] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const pathLocale = getLangFromPath();
  const [selectedFlag, setSelectedFlag] = useState(null);

  useEffect(() => {
    if (pathLocale) setSelectedLocale(pathLocale);
  }, [path]);
  useEffect(() => {
    setSelectedFlag(flags[selectedLocale]);
  }, [selectedLocale]);

  const languageStrings = {
    PL: 'Polski',
    EN: 'English',
    DE: 'Deutsch',
    FR: 'Français',
    HU: 'Magyar',
    RU: 'Pусский',
    UA: 'Українська',
  };

  const {
    routesPL, routesEN, routesDE, routesFR, routesRU, routesHU, routesUA,
  } = useStaticQuery(graphql`
{
  routesPL: mdx(frontmatter: {title: {eq: "routes-navigation"}, language: {eq: "PL"}}) {
    exports {
      routes {
        route
      }
    }
  },
  routesEN: mdx(frontmatter: {title: {eq: "routes-navigation"}, language: {eq: "EN"}}) {
    exports {
      routes {
        route
      }
    }
  },
  routesDE: mdx(frontmatter: {title: {eq: "routes-navigation"}, language: {eq: "DE"}}) {
    exports {
      routes {
        route
      }
    }
  },
  routesFR: mdx(frontmatter: {title: {eq: "routes-navigation"}, language: {eq: "FR"}}) {
    exports {
      routes {
        route
      }
    }
  },
  routesRU: mdx(frontmatter: {title: {eq: "routes-navigation"}, language: {eq: "RU"}}) {
    exports {
      routes {
        route
      }
    }
  },
  routesHU: mdx(frontmatter: {title: {eq: "routes-navigation"}, language: {eq: "HU"}}) {
    exports {
      routes {
        route
      }
    }
  },
  routesUA: mdx(frontmatter: {title: {eq: "routes-navigation"}, language: {eq: "UA"}}) {
    exports {
      routes {
        route
      }
    }
  },
}
    `);


  const routes = {
    PL: routesPL.exports.routes,
    EN: routesEN.exports.routes,
    DE: routesDE.exports.routes,
    FR: routesFR.exports.routes,
    HU: routesHU.exports.routes,
    RU: routesRU.exports.routes,
    UA: routesUA.exports.routes,
  };

  const setLocaleInUrl = (locale) => {
    const currentRouteIndex = routes[pathLocale].findIndex((element) => encodeURI(element.route) === path);
    return routes[locale] && routes[locale][currentRouteIndex].route;
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLanguageChange = (locale) => {
    dispatch({ type: actions.SET_LANGUAGE, payload: locale });
  };
  return (
    <SelectorWrapper>
      <FlagContainer>
        <AnimatePresence>
          <Flag
            key={selectedLocale}
            src={selectedFlag}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            theme={theme}
            onClick={toggleDropdown}
          />
        </AnimatePresence>
      </FlagContainer>
      <Dropdown theme={theme} border={dropdownVisible}>
        <DropdownElement key={languageStrings[selectedLocale]} onClick={toggleDropdown}>
          {languageStrings[selectedLocale]}
          <Arrow theme={theme} src={ArrowSvg} turned={dropdownVisible} alt="toggle dropdown menu" />
        </DropdownElement>
        <AnimatePresence>

          {
            dropdownVisible && locales.filter((locale) => locale !== selectedLocale).map((locale) => (
              <Link
                to={`${setLocaleInUrl(locale)}`}
                key={languageStrings[locale]}
                onClick={() => handleLanguageChange(locale)}
              >
                <DropdownElement
                  onClick={toggleDropdown}
                  initial={{ maxHeight: 10, opacity: 0 }}
                  animate={{ maxHeight: 50, opacity: 1 }}
                  exit={{ maxHeight: 0, opacity: 0 }}
                >
                  {languageStrings[locale]}

                </DropdownElement>
              </Link>

            ))
          }
        </AnimatePresence>

      </Dropdown>
    </SelectorWrapper>

  );
};


export default LanguageSelector;
