import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "FR",
  "name": "Allemagne",
  "title": "modal-2-3-1-2"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">La révolution en Allemagne</h2>
    <p tabIndex="0">En novembre 1918, la situation au sein de l'Empire allemand était pire que sur le front lors de la Grande Guerre. Le pays est fatigué du conflit qui dure depuis quatre ans. Tout d'abord, les problèmes économiques causés par le blocus maritime du pays étaient lourds. Les Allemands n'ont pas vu de café et de chocolat depuis des années, les pommes de terre et le pain sans sciure et autres additifs étaient un luxe. Les <strong>équivalents (erzatze)</strong> des produits les plus nécessaires étaient couramment utilisés - plus de 11 000 ont été développés.
    </p>
    <p tabIndex="0">
ERZATZE:
    </p>
    <p tabIndex="0">
Beurre -> gras avec colorant jaune
    </p>
    <p tabIndex="0">
Café -> infusion à base de farine de gland
    </p>
    <p tabIndex="0">
Viande / saucisses -> saucisses de pommes de terre
    </p>
    <p tabIndex="0">
L'impasse sur le front occidental et l'effondrement économique ont provoqué <strong>des révoltes parmi les soldats et l'insatisfaction des civils</strong>. Au début du mois de novembre 1918, les marins ont entamé une rébellion et saisi les principales villes portuaires et industrielles, et ont commencé à former des conseils de travailleurs et de soldats. La rébellion n'a pas été soutenue par les sociaux-démocrates allemands du parti SPD, mais les marxistes rassemblés dans <strong>Ligue spartakiste</strong>, se tenaient du côté des marins, qui avaient auparavant agit pour la transformation de l'Allemagne en un pays avec un système similaire à celui qui prévalait en Russie soviétique.  
    </p>
    <p tabIndex="0">
La révolution a envahi presque tout le pays. L'empereur Guillaume II a été <strong>contraint d'abdiquer</strong> et s'est enfui en Hollande neutre. Le 9 novembre, le chef spartakiste <strong>Karl Libknecht</strong> a proclamé une <strong>république socialiste</strong>. Les militaires, qui avaient jusqu'à présent le pouvoir réel dans le pays, ont vu l'opportunité d'étouffer la révolution dans une alliance avec les sociaux-démocrates. Grâce à la coopération de l'armée et du SPD, les communistes ont été écartés du pouvoir.
    </p>
    <p tabIndex="0">
Le point culminant de la révolution a été le soi-disant <strong>„la révolte spartakiste”</strong> – discours armé de militants du Parti communiste allemand contre les sociaux-démocrates. La situation était sous le contrôle des autorités et les initiateurs du soulèvement ont été tués. Parmi eux, Karl Liebknecht et <strong>Rosa Luxemburg</strong>.
    </p>
    <p tabIndex="0">
Les forces révolutionnaires ont résisté le plus longtemps en Bavière, où elles ont formé la <strong>République soviétique bavaroise</strong>. Le régime s'est effondré après l'intervention de l'armée début mai 1919.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      