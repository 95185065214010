export const transition = {
  duration: 1,
};
export const slideRightVariant = {
  exit: {
    transform: 'translateX(100%)',
    transition,
  },
  enter: {
    transform: 'translateX(0%)',
    transition,
  },
};

