/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions';
import BazierEasing from 'bezier-easing';
import Modal from '../../_shared/modal/modal';
import GlobalStyle from './styles/styles';
import Header from '../header/header';
import MenuDropdown from '../menuDropdown/menuDropdown';
import ProgressBar from '../progressBar/progressBar';
import PageTitle from '../pageTitle/pageTitle';

const Layout = ({
  children, path, location,
}) => {
  const [isVisibleMenu, setVisibleMenu] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'SET_PATH', payload: path });
  }, [path]);

  const [removeui, setRemoveUI] = useState(true);
  useEffect(() => {
    const introPages = [
      '/pl/',
      '/en/',
      '/fr/',
      '/de/',
      '/ru/',
      '/ua/',
      '/hu/',
      '/',
    ];
    if (introPages.find((el) => el === path)) {
      setRemoveUI(true);
    } else {
      setRemoveUI(false);
    }
  }, [path]);

  return (
    <>
      <GlobalStyle />
      <Modal />
      <TransitionProvider
        location={location}
        mode="immediate"
        enter={{
          opacity: 1,
          transform: 'translate3d(0,0.1vh,0) scale3d(1, 1, 1) rotate(0deg)',
          config: {
            duration: 1000,
          },
        }}
        usual={{
          opacity: 1,
          transform: 'translate3d(0vh,0vh,0) scale3d(1, 1, 1) rotate(0deg)',
        }}
        leave={{
          opacity: 1,
          transform: 'translate3d(0vh,-100vh,0) scale3d(1, 1, 1) rotate(0deg)',
          config: {
            mass: 1,
            tension: 220,
            clamp: true,
            duration: 1000,
            easing: (t) => BazierEasing(0.82, 0.0, 0.195, 1.0)(t),
          },
        }}
      >
        {!removeui && (
        <>
          <Header
            isVisible={isVisibleMenu}
            setVisible={setVisibleMenu}
            path={path}
          />
          <MenuDropdown
            isVisible={isVisibleMenu}
            setVisible={setVisibleMenu}
            path={path}
          />
          <PageTitle path={path} />
          <ProgressBar />
        </>
        )}
        <main>
          <TransitionViews>{children}</TransitionViews>
        </main>
      </TransitionProvider>
    </>
  );
};

export default Layout;
