/* eslint-disable max-len */
/* eslint-disable import/named */
import React, {
  createRef, useEffect, useRef, useState,
} from 'react';
import { AnimatePresence } from 'framer-motion';
import SimpleBar from 'simplebar-react';
import {
  ModalCurtain, VerticalModalWrapper, StyledModalContainer, StyledModal, ModalText, CloseIcon, StyledImage, TopFade, BottomFade,
} from './styled';
import {
  fadeVariant, unfoldVariant, modalTextVariant, closeIconVariant,
} from './animations';
import 'simplebar/dist/simplebar.min.css';


const Modal = (props) => {
  const {
    body, show, image, close, clear, fullWidth
  } = props;

  const modalContainer = createRef();
  const scrollBarRef = useRef();

  const [topFade, setTopFade] = useState(false);
  const [bottomFade, setBottomFade] = useState(false);

  const scrollHandler = () => {
    if (scrollBarRef.current.scrollTop > 0) setTopFade(true);
    if (scrollBarRef.current.scrollTop === 0) setTopFade(false);
    if (scrollBarRef.current.scrollHeight > scrollBarRef.current.offsetHeight) setBottomFade(true);
    if (scrollBarRef.current.scrollTop + scrollBarRef.current.offsetHeight === scrollBarRef.current.scrollHeight) setBottomFade(false);
  };

  const closeModal = (event) => {
    if (event.key === 'Escape') {
      close();
    }
  };
  const closeModalIconHandler = (event) => {
    if (event.key === 'Enter') {
      close();
    }
  };

  const animComplete = () => {
    if (body) {
      modalContainer.current.focus();
      scrollBarRef.current.scrollTop = 0;
      scrollHandler();
    }
  };

  const closeModal2 = () => {
    close();
  };
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <AnimatePresence
      onExitComplete={clear}
    >
      {show
        && (
          <ModalCurtain
            initial="exit"
            animate="enter"
            exit="exit"
            variants={fadeVariant}
            onKeyDown={closeModal}
            onClick={closeModal2}
          >
            <VerticalModalWrapper
              initial="exit"
              animate="enter"
              exit="exit"
              variants={unfoldVariant}
              fullWidth={fullWidth}
              body={body}
              image={image}
              onClick={stopPropagation}
              onAnimationComplete={animComplete}
            >
              {image
                && (
                  <StyledImage style={{ width: '100%' }} fixed={image} />

                )}
              <StyledModalContainer
                style={{ width: image && !body ? image.width : '' }}
              >

                {body && (
                <>
                  <TopFade
                    initial="exit"
                    animate="enter"
                    exit="exit"
                    variants={unfoldVariant}
                    show={topFade}
                  />
                  <BottomFade
                    initial="exit"
                    animate="enter"
                    exit="exit"
                    variants={unfoldVariant}
                    show={bottomFade}
                  />
                  <SimpleBar
                    scrollableNodeProps={{ ref: scrollBarRef }}
                    style={{ height: '100%' }}
                    onScroll={scrollHandler}
                  >
                    <StyledModal>

                      <ModalText
                        initial="exit"
                        animate="enter"
                        exit="exit"
                        ref={modalContainer}
                        tabindex="0"
                        variants={modalTextVariant}
                      >
                        {body}
                      </ModalText>
                    </StyledModal>
                  </SimpleBar>
                </>
                )}
                <CloseIcon
                  onClick={close}
                  initial="exit"
                  animate="enter"
                  exit="exit"
                  variants={closeIconVariant}
                  red={((body && !image) || (image && !body))}
                  onKeyDown={closeModalIconHandler}
                />
              </StyledModalContainer>
            </VerticalModalWrapper>

          </ModalCurtain>
        )}
    </AnimatePresence>
  );
};

export default Modal;
