import React, { useState, useEffect } from 'react';
import { useTransitionStore } from 'gatsby-plugin-transitions';
import MouseIcon from 'images/_shared/mouse_icon.svg';
import MouseArrow from 'images/_shared/mouse_arrow.svg';
import { getRoutesQuery, getLangFromPath } from 'src/utils';
import { Wrapper, Icon, Arrow } from './styles';

const ScrollIcon = ({
  style, initial, animate, transition, path,
}) => {
  const [, dispatch] = useTransitionStore();
  const [isReadyTransition, setReadyTransition] = useState(true);
  const query = getRoutesQuery();
  const lang = getLangFromPath();
  const routes = query[lang] ? query[lang].exports.routes : [];

  const getCurrentRoutesIndex = () => routes.findIndex(({ route }) => encodeURI(route) === path);

  const nextPagePath = () => {
    const currentIndex = getCurrentRoutesIndex();
    if (currentIndex < routes.length - 1) {
      return routes[currentIndex + 1].route;
    }
    return path;
  };
  useEffect(() => {
    if (isReadyTransition === false) {
      setTimeout(() => {
        setReadyTransition(true);
      }, 2500);
    }
  }, [isReadyTransition]);

  const onMouseClick = () => {
    if (isReadyTransition) {
      dispatch({
        type: 'NAVIGATE',
        to: nextPagePath(),
      });
      setReadyTransition(false);
    }
  };

  return (
    <Wrapper
      initial={initial}
      animate={animate}
      transition={transition}
      style={{ ...style }}
      onClick={onMouseClick}
    >
      <Icon src={MouseIcon} alt="scroll-icon" />
      <Arrow src={MouseArrow} alt="scroll-icon" />
    </Wrapper>
  );
};

export default ScrollIcon;
