/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getRoutesQuery } from 'src/utils';
import { ThemeProvider } from 'styled-components';
import { getLangFromPath } from 'src/utils/index.js';
import {
  TimeLine,
  SideBarContainer,
  TimeLineNav,
  TimeLineElement,
  TimeLineElementLink,
  TimeLineElementSubLink,
  TimeLineLinkWrapper,
  TimeLineTitle,
  TimeLineIndicatorCircle,
  TimeLineNumber,
  TimeLineIndicatorCaro,
  TimeLineElementDot,
  TimeLineProgressBar,
  darkTheme,
  lightTheme,
  TitleHover,
} from './styles';
import Logo from '../logo/logo';
import LanguageSelector from './languageSelector/languageSelector';

const TimeLineMain = ({
  route, name, number, visited, onClick,
}) => (
    <TimeLineElement>
      <TimeLineLinkWrapper>
        <TimeLineElementLink
          to={route}
          onClick={onClick}
          activeClassName="active"
          aria-label={name}
          role="menuitem"
          visited={visited}
        >
          <TimeLineTitle>{name}</TimeLineTitle>
          <TimeLineIndicatorCaro visited={visited.toString()} />
          <TimeLineNumber>{number}</TimeLineNumber>
        </TimeLineElementLink>
      </TimeLineLinkWrapper>
    </TimeLineElement>
  );

const TimeLineSub = ({
  route, onClick, visited, active, name,
}) => (
    <TimeLineElement>
      <TimeLineElementSubLink
        to={route}
        onClick={onClick}
        aria-label={name}
        role="menuitem"
      >
        <TimeLineElementDot>
          <TitleHover>{name}</TitleHover>
          <TimeLineIndicatorCircle visited={visited.toString()} active={active} />
        </TimeLineElementDot>
      </TimeLineElementSubLink>
    </TimeLineElement>
  );

const SideBar = ({
  path, style, initial, animate, transition,
}) => {
  const [navigationIndex, setNavigationIndex] = useState(0);
  const [theme, setTheme] = useState(darkTheme);
  const selectedLocale = getLangFromPath();
  const sliderTheme = useSelector((state) => state.sliderTheme);

  const query = getRoutesQuery();

  const routes = query[selectedLocale]
    ? query[selectedLocale].exports.routes
    : [];

  const getCurrentRoute = () => {
    routes.forEach(({ route, isLightTheme }, index) => {
      if (encodeURI(route) === path) {
        isLightTheme ? setTheme(lightTheme) : setTheme(darkTheme);
        return setNavigationIndex(index);
      }
      return null;
    });
  };

  const onClickLink = (index) => {
    setNavigationIndex(index);
  };

  useEffect(() => {
    getCurrentRoute();
  }, [path]);

  useEffect(() => {
    sliderTheme === "dark" && setTheme(darkTheme)
    sliderTheme === "light" && setTheme(lightTheme)
  }, [sliderTheme]);
  return (
    <ThemeProvider theme={theme}>
      <SideBarContainer initial={initial} animate={animate} transition={transition} style={{ ...style }}>
        <Logo theme={theme} />
        <LanguageSelector path={path} theme={theme} />
        <TimeLine theme={theme}>
          <TimeLineNav role="menu">
            <TimeLineProgressBar
              index={navigationIndex}
              length={routes.length}
            />
            {routes.map(({
              name, route, number, isMainPage,
            }, i) => (

                isMainPage ? (
                  <TimeLineMain
                    key={`${route}_${i}`}
                    name={name}
                    route={route}
                    number={number}
                    onClick={() => onClickLink(i)}
                    visited={(i <= navigationIndex).toString()}
                  />
                ) : (
                    <TimeLineSub
                      key={`${name}_${i}`}
                      name={name}
                      route={route}
                      onClick={() => onClickLink(i)}
                      visited={(i <= navigationIndex).toString()}
                      active={i === navigationIndex}
                    />
                  )

              ))}
          </TimeLineNav>
        </TimeLine>
      </SideBarContainer>
    </ThemeProvider>
  );
};

export default SideBar;
