import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "PL",
  "name": "Niemcy",
  "title": "modal-2-3-1-2"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Rewolucja w Niemczech</h2>
    <p tabIndex="0">W listopadzie 1918 roku sytuacja wewnętrzna w Cesarstwie Niemieckim była gorsza niż na froncie Wielkiej Wojny. Kraj zmęczony był trwającym od czterech lat konfliktem. Uciążliwe były przede wszystkim problemy gospodarcze spowodowane blokadą morską kraju. Niemcy od lat nie widzieli kawy i czekolady, luksusem były ziemniaki i chleb bez trocin i innych dodatków. W powszechnym użyciu były <strong>zamienniki (erzatze)</strong> najpotrzebniejszych produktów – opracowano ich ponad 11 tys. 
    </p>
    <p tabIndex="0">
ERZATZE:
    </p>
    <p tabIndex="0">
Masło -> tłuszcz z żółtym barwnikiem
    </p>
    <p tabIndex="0">
Kawa -> napar na bazie mąki z żołędzi
    </p>
    <p tabIndex="0">
Mięso/kiełbasy -> kiszki ziemniaczane
    </p>
    <p tabIndex="0">
Impas na froncie zachodnim i zapaść gospodarcza powodowały <strong>bunty wśród żołnierzy i niezadowolenie cywilów</strong>. Na początku listopada 1918 roku marynarze podnieśli bunt i opanowali kluczowe miasta portowe i przemysłowe, zaczęli tworzyć rady robotnicze i żołnierskie. Bunt nie został poparty przez niemieckich socjaldemokratów z partii SPD,  po stronie marynarzy stanęli za to marksiści-maksymaliści skupieni w <strong>Związku Spartakusa</strong>, którzy już wcześniej agitowali na rzecz przekształcenia Niemiec w kraj o ustroju podobnym do tego panującego w Rosji Radzieckiej.  
    </p>
    <p tabIndex="0">
Rewolucja opanowała niemal cały kraj. Cesarz Wilhelm II został <strong>zmuszony do abdykacji</strong> i zbiegł do neutralnej Holandii. 9 listopada przywódca spartakusowców <strong>Karl Libknecht</strong> proklamował <strong>republikę socjalistyczną</strong>. Wojskowi, sprawujący dotąd faktyczną władzę w państwie, dostrzegli szansę na stłumienie rewolucji w sojuszu z socjaldemokratami. Dzięki współdziałaniu wojska i SPD udało się nie dopuścić do zwycięstwa komunistów.
    </p>
    <p tabIndex="0">
Kulminacyjnym punktem rewolucji było tzw. <strong>„powstanie Spartakusa”</strong> – zbrojne wystąpienie bojówek Komunistycznej Partii Niemiec przeciwko rządowi socjaldemokratów. Sytuacja została opanowana przez władze, a przywódcy powstania zginęli. Wśród nich byli Karl Liebknecht i <strong>Róża Luksemburg</strong>.
    </p>
    <p tabIndex="0">
Siły rewolucjonistów najdłużej utrzymywały się w Bawarii, gdzie utworzyły <strong>Bawarską Republikę Rad</strong>. Reżim upadł po interwencji wojska na początku maja 1919 roku.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      