/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import './static/fonts/fonts.css';
require('@openfonts/red-hat-display_latin-ext');
require('@openfonts/abril-fatface_latin-ext');
require('@openfonts/prompt_latin-ext');
require('@openfonts/oswald_all');
require('@openfonts/prata_all');
require('@openfonts/open-sans_cyrillic');

exports.shouldUpdateScroll = () => false;
