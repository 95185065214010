export const styles = {
  fonts: {
    header: {
      color: '#1e262a',
      'font-family': 'Abril Fatface, Prata',
      'font-size': '2.81rem',
      'margin-bottom': '1.5rem',
      'line-height': '2.81rem',
    },
    mainText: {
      color: '#1e262a',
      'font-family': 'Red Hat Display, Open Sans',
      'font-size': '1rem',
      'line-height': '1.6rem',
    },
    modalText: {
      color: '#1e262a',
      'font-family': 'Red Hat Display, Open Sans',
      'font-size': '0.875rem',
      'margin-bottom': '1.5rem',
      'letter-spacing': '0.02rem',
      'line-height': '1.5rem',
    },
    mobileModalHeader: {
      'font-family': 'Abril Fatface, Prata',
      'font-size': '2.81rem',
      // 'margin-bottom': '1.5rem',
      'line-height': '2.81rem',
      'font-weight': '400',
    },
    mobileTitleText: {
      'font-family': 'Prompt',
      'font-size': '0.625rem',
      'letter-spacing': '0.085rem',
    },
  },
  colors: {
    black: '#1e262a',
    beigeBg: '#f5f4ee',
    red: '#de2831',
    paleRed: 'rgb(222, 40, 49)',
  },
};

export const locales = ['PL', 'EN', 'DE', 'RU', 'FR', 'UA', 'HU'];
export const introPages = ['/pl/', '/en/', '/fr/', '/de/', '/ru/', '/ua/', '/hu/', '/'];
export const mediaServer = '//bitwa1920.gov.pl/media';
// NEW GLOBALS
export const fonts = {
  headline_1: {
    'font-family': 'Abril Fatface, Prata',
    'font-size': '4.375rem',
    'line-height': '4.375rem',
    'margin-bottom': '1.625rem',
    'font-weight': '400',
  },
  headline_1_large: {
    'font-family': 'Abril Fatface, Prata',
    'font-size': '12rem',
    'line-height': '16rem',
    'margin-bottom': '1rem',
    'font-weight': '400',
  },
  headline_2: {
    'font-family': 'Abril Fatface, Prata',
    'font-size': '2.812rem',
    'line-height': '2.812rem',
    'margin-bottom': '1.5rem',
    'font-weight': '400',
  },
  headline_3a: {
    'font-family': 'Abril Fatface, Prata',
    'font-size': '1.5rem',
    'line-height': '1.875rem',
    'margin-bottom': '1rem',
    'font-weight': '400',
  },
  headline_3b: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '1rem',
    'line-height': '1.625rem',
    'margin-bottom': '1rem',
    'font-weight': '400',
  },
  headline_4: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '1rem',
    'line-height': '1.625rem',
    'margin-bottom': '1rem',
    'font-weight': '400',
  },
  paragraf: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '1rem',
    'line-height': '1.625rem',
    'margin-bottom': '1.625rem',
  },
  popup: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '0.875rem',
    'line-height': '1.375rem',
    'letter-spacing': '0.02rem',
  },
  quote: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '1.875rem',
    'line-height': '2.562rem',
    'margin-bottom': '2.5rem',
    'font-weight': '700',
  },
  page_title: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '0.875rem',
    'line-height': '1.625rem',
    'padding-bottom': '0.437rem',
    'font-weight': '600',
    'lett,er-spacing': '0.1rem',
  },
  map_legend: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '0.625rem',
    'font-weight': '400',
    'letter-spacing': '0.07rem',
  },
  map_legend_title: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '0.75rem',
    'font-weight': '500',
    'letter-spacing': '0.06rem',
    'line-height': '1rem',
    'margin-bottom': '0.8rem',
  },
};

export const fontsMobile = {
  headline_1: {
    'font-family': 'Abril Fatface, Prata',
    'font-size': '4.375rem',
    'line-height': '4.375rem',
    'margin-bottom': '1.625rem',
    'font-weight': '400',
  },
  headline_2: {
    'font-family': 'Abril Fatface, Prata',
    'font-size': '2.812rem',
    'line-height': '2.812rem',
    'margin-bottom': '1.5rem',
    'font-weight': '400',
  },
  headline_3a: {
    'font-family': 'Abril Fatface, Prata',
    'font-size': '1.5rem',
    'line-height': '1.875rem',
    'margin-bottom': '1rem',
    'font-weight': '400',
  },
  headline_3b: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '1rem',
    'line-height': '1.625rem',
    'margin-bottom': '1rem',
    'font-weight': '400',
  },
  headline_4: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '1rem',
    'line-height': '1.625rem',
    'margin-bottom': '1rem',
    'font-weight': '400',
  },
  paragraf: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '1rem',
    'line-height': '1.625rem',
    'margin-bottom': '1.625rem',
  },
  popup: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '0.875rem',
    'line-height': '1.375rem',
    'letter-spacing': '0.02rem',
  },
  quote: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '1rem',
    'line-height': '2.562rem',
    'margin-bottom': '2.5rem',
    'font-weight': '700',
  },
  page_title: {
    'font-family': 'Red Hat Display, Open Sans',
    'font-size': '0.875rem',
    'line-height': '1.625rem',
    'padding-bottom': '0.437rem',
    'font-weight': '600',
    'letter-spacing': '0.1rem',
  },

};

export const colors = {
  dark: {
    color: '#272323',
  },
  light: {
    color: '#f9f8f3',
  },
  red: {
    color: '#de2831',
  },
};

export const sidebarWidth = 6.6;
export const sidebarAdjustment = sidebarWidth / 2;
