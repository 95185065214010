import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "HU",
  "name": "Magyarország",
  "title": "modal-2-3-1-3"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0" style={{
      fontSize: '2.2rem'
    }}>Forradalom Magyarországon</h2>
    <p tabIndex="0">Az I. világháborús kudarc kompromittálta a Habsburgok dinasztiáját és Ausztria-Magyarország felbomlásához vezetett. 1918 őszén Magyarországon és Ausztriában forradalmak törtek ki, amelyek eltávolították a hatalomból a császárt és a királyt.  A Moszkvából Magyarországra küldött kommunisták már 1919 februárjában megkísérelték átvenni a hatalmat Magyarországon, de az akkori puccskísérlet kudarcot vallott. A hatalmat csak 1919. március 21-én sikerült átvenniük Károlyi Mihály köztársasági elnök kompromittálása és így a Berinkey Dénes vezette kormány kompromittálása útján. Ugyanezen a napon a szociáldemokratákkal együttesen kikiáltották a <strong>Magyar Tanácsköztársaságot</strong>, melynek vezetője <strong>Kun Béla lett</strong>. Az országban eluralkodott a „vörös terror” – annak mintájára, amit a bolsevikok Oroszországban bevezettek. A kommunisták egyik ilyen tevékenysége az ellenforradalommal szemben küzdő különítmények – a <strong>„Lenin-fiúk” </strong> létrehozása volt.  A magyar kommunistáknak sikerült egy rövid időtartamra létrehozni a <strong>Szlovák Tanácsköztársaságot is</strong>. A Magyar Tanácsköztársaságnak 1919 augusztusában – az antanttal együttműködő – román hadsereg vetett véget, mely megszállta az ország területének nagyobbik részét, Budapestet is beleértve. A kommunista kormányzat bukása után a hatalmat Horthy Miklós admirális vette át. A szélsőjobb a korábbi rezsim képviselőit komoly represszióknak vetette alá. 
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      