import React, { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import speakerOff from 'src/assets/images/_shared/volume-off.svg';
import speakerOn from 'src/assets/images/_shared/volume-on.svg';
import { useInView } from 'react-intersection-observer';
import actions from 'src/store/actions';
import { useLocation } from '@reach/router';

import {
  FlexContainer, PlayerWrapper, PlayerImage, PlayButton, AudioDescription, PlayIcon
} from './styles';


const AudioPlayer = ({
  audio, audioImage, dark, audioDescription, style, textStyle, audioimage, audiodescription
}) => {
  const dispatch = useDispatch();
  const dispatchModal = () => {
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: audio });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: 'video' });
  };

  const [ref, inView] = useInView({
    threshold: 0.8,
  });

  const playerRef = useRef();

  const setRefs = useCallback(
    (node) => {
      playerRef.current = node;
      ref(node);
    },
    [ref],
  );


  const location = useLocation();
  const playLabel = {
    PL: 'Kliknij aby odtwarzać audio',
    EN: 'Click to play audio',
    DE: 'Audio abspielen',
    FR: "Cliquez pour lire l'audio",
    RU: 'do zmiany',
    UA: 'Натисніть, щоб відтворити',
    HU: 'do zmiany',
  };

  const URLlocale = location.pathname.split('/').filter((element) => Object.keys(playLabel).includes(element.toUpperCase()))[0].toUpperCase();

  const isPlaying = useSelector((state) => state.playerIsPlaying);
  const playerInstance = useSelector((state) => state.playerInstance);

  const checkIsPlaying = () => !!(isPlaying && (playerRef.current === playerInstance));

  return (
    <FlexContainer style={{ ...style }}>
      <PlayerWrapper ref={setRefs}
        tabIndex="0"
        aria-label={playLabel[URLlocale]}
        onClick={dispatchModal}
        onKeyPress={dispatchModal}
      >
        <PlayerImage fluid={audioImage || audioimage} />
        <PlayButton
          dark={dark}
        >
          <PlayIcon
            src={checkIsPlaying() ? speakerOff : speakerOn}
          />
        </PlayButton>
      </PlayerWrapper>
      {audioDescription && (
        <AudioDescription dark={dark} style={textStyle} tabIndex="0">
          <p>{audioDescription}</p>
        </AudioDescription>
      )}
      {audiodescription && (
        <AudioDescription dark={dark} style={textStyle} tabIndex="0">
          <p>{audiodescription}</p>
        </AudioDescription>
      )}
    </FlexContainer>
  );
};

export default AudioPlayer;
