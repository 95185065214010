/* eslint-disable max-len */
/* eslint-disable import/named */
import React, {
  useRef, useState, createRef,
} from 'react';
import {
  ModalWrapper, CloseButton, Arrow
} from './styled';
import { AnimatePresence } from 'framer-motion';
import {
  slideRightVariant, 
} from './animations';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';

const Modal = ({
  body, image, show, close, clear,
}) => {
  const style = {
    transform: 'rotate(135deg)',
  }

  const closeModal = () => {
    close();
  }
  return (
    <AnimatePresence
      onExitComplete={clear}
    >
    {show
      && (
        <ModalWrapper 
          initial="exit"
          animate="enter"
          exit="exit"
          variants={slideRightVariant}
        > 
          <LeftColContent body={body.body} style={{paddingLeft: '4vw'}} audio={body.sound} audioImage={body.photo} smallerTitle>
          </LeftColContent>
          <CloseButton onClick={closeModal}> 
              <Arrow />
              <Arrow style={style}/>
          </CloseButton>
        </ModalWrapper> 
    )}
    </AnimatePresence>
  );
};

export default Modal;
