import styled from 'styled-components';
import Img from 'gatsby-image';
import { isMobile } from 'react-device-detect';
import { fonts, colors } from '../../../../../globals';
import circle from '../assets/circle.svg';


export const FlexContainer = styled.div`
    margin-top: ${isMobile ? '' : '2rem'};
    width: ${isMobile ? '100%' : '10rem'};
    display: flex;
    max-width: 360px;
margin-bottom: ${isMobile ? '2rem' : ''};
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
`;

export const PlayerWrapper = styled.div`
    background-image: url(${circle});
    background-repeat: no-repeat;
    height: ${isMobile ? 'auto' : '5rem'};
    width: ${isMobile ? '35%' : '5rem'};
    position: relative;
    &:hover {
        cursor: pointer;
    }
`;

export const PlayerImage = styled(Img)`
    width: 88.8%;
    margin-left: 3%;
    transform: translate3d(0,-6%,0);
`;

export const PlayButton = styled.div`
    height: ${isMobile ? '25%' : '2rem'};
    width: ${isMobile ? '27%' : '2rem'};
    cursor: pointer;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 1%;
    right: ${isMobile ? '0%' : '-7%'};
    background: ${colors.red.color};
    border-radius: 50%;

`;

export const PlayIcon = styled.img`
    width: 100%;
    padding: 15%;
    position: absolute;
    bottom: 0;
    right: 0;
    filter: invert(1);
`;

export const PlayCircle = styled.img`
    width: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    filter: invert(1);
    & ~ h3, & ~ p {
        display: ${(props) => (props.size !== 2 ? 'none' : 'block')}
    }
`;

export const AudioDescription = styled.div`
    text-align: center;
    z-index: 1;
    ${(props) => (props.dark ? colors.dark : colors.light)}
    h3, p {
        ${fonts.paragraf}
        line-height: inherit;
        margin-bottom: 0;
    }
    h3 {
        font-weight: bold;
    }

`;

