/* eslint-disable no-undef */
import React from 'react';
import { isMobile, isBrowser } from 'react-device-detect';
import DesktopLayout from '../desktop/_layout/layout';
import MobileLayout from '../mobile/_layout/layout';
import './reset.css';

const Layout = ({
  path, children, location,
}) => {
  if (typeof window !== 'undefined') {
    if (isMobile) {
      return (
        <MobileLayout path={path} location={location}>
          {children}
        </MobileLayout>
      );
    }
    if (isBrowser) {
      return (
        <DesktopLayout path={path} location={location}>
          {children}
        </DesktopLayout>
      );
    }
  }
  return <></>;
};

export default Layout;
