import styled, { keyframes }from 'styled-components';
import { motion } from 'framer-motion';
import { styles } from '../../../../../globals';


export const ModalWrapper = styled(motion.div)`
    width: 26vw; 
    height: 100vh;
    position: fixed; 
    background-color: #000;
    right: 0; 
    z-index: 10;
`;

export const CloseButton = styled.div`
    width: 2.9375rem;
    height: 2.9375rem;
    background-color: #df3242;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    left: -1.3rem;
`;

export const Arrow = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-right: 2px solid #ebe6e6;
  border-bottom: 2px solid #ebe6e6;
  transform: rotate(-45deg);
  margin: 0.09rem;
`;