import styled from 'styled-components';
import { motion } from 'framer-motion';
import { styles } from '../../../../../../globals';


export const SelectorWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const CircleBorder = styled.div`
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.theme.mainColor};
    border-radius: 50%;
    cursor: pointer;
`;

export const FlagCircle = styled.div`
    width: 1.6rem;
    height: 1.6rem;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    & #flag {
        transition: 0.3s;
        width: 95%;
        height: 95%;
    }
`;

export const FlagContainer = styled.div`
    position: relative;
    height: 2rem;
    width: 100%;
`;
export const Flag = styled(motion.div)`
  background: url(${({ src }) => src}) content-box;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.defaultColor};
  padding: 2px;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate3d(-50%, 0, 0);
`;

export const Arrow = styled.img`
    height: 0.7rem;
    transition: 0.3s;
    filter: ${({ theme }) => theme.defaultColor === '#a6a59e' && 'invert(1)'};
    transform: rotate(${(props) => (props.turned ? '180deg' : '0deg')});
    margin-left: auto;
    padding: 0 0.5rem;
`;

export const Dropdown = styled.ul`
    border: 1px solid ${(props) => (props.border ? props.theme.defaultColor : 'transparent')};
    color: ${(props) => props.theme.linkColorActive};
    transition: 0.3s;
    list-style: none;
    margin-top: 0.2rem;
    user-select: none;
    max-height: 10rem;
    overflow: hidden;
    a, a:active, a:visited {
        text-decoration: none;
        color: rgba(255, 255, 255, 1);
    }
    background: ${(props) => (props.border ? 'rgba(0,0,0,0.7)' : 'transparent')};
    z-index: 1000;
`;

export const DropdownElement = styled(motion.li)`
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0;
    padding-left: 0.5rem;
    font-size: 0.9rem;
    cursor: pointer;
    &:hover {
        transition: background-color 0.3s;
        background-color: ${styles.colors.red};
    }
`;
