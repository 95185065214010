import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "UA",
  "name": "Фінляндія",
  "title": "modal-2-3-1-4"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Фінська громадянська війна</h2>
    <p tabIndex="0">Фінляндія, яка залишалася частиною Російської імперії до Жовтневого перевороту більшовиків, користуючись підтримкою Німеччини і&nbsp;безладами в&nbsp;Росії, проголосила незалежність в&nbsp;грудні 1917 року. Більшовики визнали незалежність держави, але не збиралися відмовлятися від «експорту революції» у&nbsp;Фінляндію. <strong>«Червона гвардія»</strong> в&nbsp;Хельсінкі, підтримувана повсталими солдатами колишніх російських гарнізонів, проголосила Радянську Республіку. Російські більшовики надали їй «братську допомогу», влаштовуючи озброєне втручання у&nbsp;Фінляндії. На півночі країни <strong>Карл Маннергейм</strong> за&nbsp;допомогою шведських добровольців і&nbsp;Німеччини створив національний уряд і&nbsp;армію, які витіснили «червоних» в&nbsp;Росію. Після Першої світової війни Фінляндія залишилася вільною і&nbsp;незалежною державою.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      