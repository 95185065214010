import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { getRoutesQuery } from 'src/utils';
import { TitleContainer, TitleText } from './styles';
import { fadeVariant } from './styles/animations';

const PageTitle = ({ path }) => {
  const [currentTitle, setCurrentTitle] = useState('');
  const query = getRoutesQuery();

  useEffect(() => {
    const pathname = path;
    const diffLanguages = ['en', 'fr', 'hu', 'ru', 'ua', 'de'];
    let result = 'PL';

    pathname
      && pathname.split('/').forEach((el) => {
        const lang = diffLanguages.find((language) => language === el);
        if (lang) result = lang.toUpperCase();
      });

    const { routes } = query[result].exports;

    const getCurrentRoute = () => {
      routes.forEach((route) => {
        if (encodeURI(route.route) === path) {
          setCurrentTitle(route.pageTitle);
        }
        return null;
      });
    };
    getCurrentRoute();
  }, [path]);


  return (
    <AnimatePresence>
      <TitleContainer>
        <TitleText
          initial="exit"
          animate="enter"
          exit="exit"
          variants={fadeVariant}
        >
          {currentTitle}
        </TitleText>
      </TitleContainer>
    </AnimatePresence>
  );
};

export default PageTitle;
