import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "FR",
  "name": "LÉON TROTSKI",
  "title": "modal-2-3-1-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Léon Trotski</h2>
    <p tabIndex="0">„Les gentilshommes polonais retireront une victoire momentanée - victoire des maraudeurs - mais quand nous en finirons avec Denikin, nous jetterons toutes nos réserves sur le front polonais” 
Léon Trotski, 15 août 1919 dans le journal français „L’Internationale Communiste” 
    </p>
    <p tabIndex="0">
Il était le propagateur de l'idée <strong>d'étendre la révolution</strong> au-delà des frontières de la Russie bolchevique et le principal décideur de <strong>l'Armée rouge</strong>.
    </p>
    <p tabIndex="0">
Il est né sous le nom de <strong>Lejba Bronsztejn</strong> dans une famille de paysans pauvres du sud de l'Ukraine (qui faisait alors partie de l'empire russe). Malgré des conditions financières difficiles, les Bronsztejn ont envoyé Léon l'Ecole Réelle de Saint-Pierre et Paul à Odessa. Après l'école, il rejoint le mouvement radical <strong>Narodnik</strong>, qui promeut la démocratisation de l'État. Au fil du temps, les vues de Léon Trotski (dont il a pris le surnom) se sont radicalisées, il a renoncé à la nationalité pour le marxisme. Il a passé 2 ans en prison pour avoir dirigé sa propre <strong>organisation révolutionnaire</strong> et a subi l'exil en Sibérie. Après deux ans, Trotski s'est échappé de l'exil et s'est rendu à l'étranger en Europe occidentale. Depuis Londres, il a pris contact avec <strong>Lénine</strong>.
    </p>
    <p tabIndex="0">
En 1905, il revient en Russie pour participer à la révolution. Il était le dernier président du <strong>Conseil des ouvriers de Saint-Pétersbourg</strong>. Il a été arrêté après la répression de la révolution, il a été arrêté et condamné à l'exil sibérien à vie. Cette fois encore, il a réussi à s'échapper et à se rendre en Suisse, puis en France. En tant que partisan de la <strong>révolution mondiale du prolétariat</strong>, il était fermement opposé à la Première Guerre mondiale. Il a prêché ses vues dans le "nouveau mot" en langue russe. Menacé d'expulsion vers la Russie, il s'est enfui à New York.
    </p>
    <p tabIndex="0">
Après le déclenchement de la révolution de février en 1917, il est retourné en Russie. En septembre 1917, il rejoint les bolcheviques. Pendant la <strong>Révolution d'octobre</strong>, il a dirigé des opérations militaires pendant les premiers jours de la rébellion. Bientôt, il a pris le poste de <strong>commissaire aux affaires étrangères</strong> de la Russie bolchevique.
    </p>
    <p tabIndex="0">
En septembre 1918, la direction politique bolchevique décide de transformer la Russie soviétique en <strong>camp militaire</strong>. Sergei Kamenev est devenu le chef de l'Armée rouge, mais il était soumis au Conseil de guerre révolutionnaire. Trotski est devenu le chef du Conseil, ce qui a fait de lui le <strong>véritable commandant</strong> de l'armée bolchevique et - après Lénine - <strong>la deuxième personne de l'État</strong>.
    </p>
    <p tabIndex="0">
Trotski était partisan de l'idée <strong>„d'exporter la révolution”</strong> vers l'Occident. Au départ, il pensait que cela devrait être fait en détruisant les empires coloniaux en Asie, ce qui, à son avis, provoquerait une crise et une <strong>révolution en Grande-Bretagne</strong>,aux États-Unis et en France. "La route de Londres et de Paris passe par Calcutta", aurait-il dit. Après le déclenchement de la révolution en Allemagne, il a changé d'avis et a été l'un des promoteurs de <strong>l'attaque contre la Pologne</strong>, en tant que «tampon» séparant la Russie de l'Allemagne.
    </p>
    <p tabIndex="0">
      <strong>Après la mort de Lénine</strong>, il s'est battu pour reprendre le Scheda après le "chef de la révolution" avec Lviv Kamenev (son ancien beau-frère), Grigory Zinoviev et <strong>Joseph Staline</strong>. Finalement, ce dernier a gagné.  Trotski a été progressivement retiré des postes les plus importants de l'URSS. En 1929, par peur pour sa propre vie, il quitte la Russie soviétique.
    </p>
    <p tabIndex="0">
Il a vécu à l'étranger en Turquie, en France, en Norvège et au Mexique. Il a critiqué le système de l'URSS comme s'écartant de l'idée de révolution mondiale. Dans cet esprit, il a fondé la <strong>Quatrième Internationale</strong> – organisation de partis communistes défavorables à la politique de Staline.
    </p>
    <p tabIndex="0">
Il a été partout pourchassé par le <strong>NKVD</strong> (Commissariat du peuple aux affaires intérieures de l'URSS,  police politique soviétique). Toute la famille de Trotski, à l'exception de sa femme, a été assassinée à la demande de Staline. Trotski lui-même a survécu à trois attaques au cours de sa vie. Le quatrième s'est avéré efficace. Le 20 août 1940, le communiste espagnol et agent du NKVD, Ramón Mercader, blessa Trotski avec un piolet à la tête. Le créateur de l'Armée rouge est décédé après vingt heures d'agonie, le 21 août 1940. 
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      