import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "RU",
  "name": "Лев троцкий",
  "title": "modal-2-3-1-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Лев троцкий – человек № 2 революции</h2>
    <p tabIndex="0">“Польские паны, шляхта вырвут временную победу - победу мародеров, но когда мы закончим с Деникиным, мы бросим все наши резервы на польский фронт”.
Лев Троцкий, 15 августа 1919 года на страницах французской “L’Internationale Communiste”.  
    </p>
    <p tabIndex="0">
Он был пропагандистом идеи <strong>расширения революции</strong> за пределы большевистской России и главным организатором <strong>Красной Армии</strong>.
    </p>
    <p tabIndex="0">
Родился в бедной крестьянской семье на юге Украины (тогда входившей в состав Российской империи). Его имя при рождении – <strong>Лейба Бронштейн</strong>. Несмотря на сложные финансовые условия, семья Бронштейнов отправила Льва в реальное училище Св. Петра и Павла в Одессе. После окончания училища он стал участвовать в радикальном <strong>народническом</strong> движении, которое постулировало демократизацию государства. Со временем взгляды Троцкого (такой псевдоним он использовал) стали радикальными, он отказался от народничества в пользу марксизма. Он заплатил за руководство своей <strong>революционной организацией</strong> двумя годами тюремного заключения и ссылкой в Сибирь. Через два года Троцкий сбежал из ссылки и уехал за границу в Западную Европу. В Лондоне он наладил контакты с <strong>Лениным</strong>.
    </p>
    <p tabIndex="0">
В 1905 году он вернулся в Россию, чтобы принять участие в революции. Был последним Председателем <strong>Петроградского совета рабочих</strong>. После подавления революции его арестовали и приговорили к пожизненной ссылке в Сибири. И на этот раз ему удалось сбежать и пробраться в Швейцарию, а затем во Францию. Будучи сторонником <strong>мировой революции пролетариата</strong>, он решительно выступал против Первой мировой войны. Свои взгляды проповедовал в русскоязычной газете “Новое слово”. Под угрозой высылки в Россию он бежал в Нью-Йорк.
    </p>
    <p tabIndex="0">
После начала Февральской революции 1917 года он вернулся в Россию. В сентябре 1917 года присоединился к большевикам. Во время <strong>Октябрьской революции</strong> руководил военными операциями в первые дни восстания. Вскоре занял пост <strong>наркома иностранных дел большевистской России</strong>.
    </p>
    <p tabIndex="0">
В сентябре 1918 года политическое руководство большевиков приняло решение превратить Советскую Россию в <strong>военный лагерь</strong>. Сергей Каменев стал главнокомандующим Красной Армией, но он подчинялся Революционному военному совету. Совет возглавил Троцкий, что сделало его <strong>фактически командующим</strong> большевистской армией и <strong>вторым после Ленина лицом в государстве</strong>.
    </p>
    <p tabIndex="0">
Троцкий был сторонником идеи <strong>“экспорта революции”</strong> на Запад. Первоначально он думал, что это должно быть совершено путем разрушения колониальных империй в Азии, что, по его мнению, вызвало бы кризис и <strong>революцию в Великобритании</strong>, США и Франции. “Дорога в Лондон и Париж ведет через Калькутту”, – говорил он. После начала революции в Германии он передумал и был одним из сторонников нападения <strong>на Польшу </strong>,как на “преграду” разделяющую Россию от Германии.
    </p>
    <p tabIndex="0">
После <strong>смерти Ленина</strong> он хотел занять место “вождя революции” и боролся за власть с Львом Каменевым (своим бывшим шурином), Григорием Зиновьевым и <strong>Иосифом Сталиным</strong>. В конечном счете, выиграл последний.Троцкого постепенно отстраняли от важных должностей в СССР. В 1929 году, опасаясь за свою жизнь, он покинул Советскую Россию. 
    </p>
    <p tabIndex="0">
Он жил в эмиграции в Турции, Франции, Норвегии и Мексике. Критиковал систему СССР как отход от идеи мировой революции. В этом духе он основал <strong>IV Интернационал</strong> - организацию коммунистических партий, отрицающую политику Сталина.
    </p>
    <p tabIndex="0">
Везде за ним следил <strong>НКВД</strong>(Народный комиссариат внутренних дел СССР, советская политическая полиция). Вся семья Троцкого, кроме его жены, была убита по указанию Сталина. Сам Троцкий пережил три покушения на свою жизнь. Четвертое завершилось успешно. 20 августа 1940 года испанский коммунист и агент НКВД Рамон Меркадер убил Троцкого, ударив ледорубом по голове. Человек, некогда создавший Красную Армию, скончался после двадцати часов мучений 21 августа 1940 года.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      