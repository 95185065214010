import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "DE",
  "name": "Finnland",
  "title": "modal-2-3-1-4"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Der Finnische Bürgerkrieg</h2>
    <p tabIndex="0">Finnland, das bis zum bolschewistischen Oktoberumsturz Teil des russischen Imperiums blieb, hat, indem es die Unterstützung Deutschlands und die in Russland herrschenden Wirren nutzte, im Dezember 1917 die Unabhängigkeit ausgerufen. Die Bolschewiki haben die Unabhängigkeit des States zwar anerkannt, wollten aber auf den „Export der Revolution“ nach Finnland nicht verzichten. Die „Roten Garden“ in Helsinki, unterstützt durch die Soldaten ehemaliger russischer Garnisone riefen die Sowjetische Republik aus.  Die russischen Bolschewiki erteilten ihnen „brüderliche Hilfe“ mit einem bewaffneten Eingriff in Finnland. Im Norden des Landes hat Karl Mannerheim mithilfe der schwedischen Freiwilligen und der Deutschen eine nationale Regierung und Armee geschaffen, die die „Roten“ nach Russland vertrieben. Finnland blieb nach dem I. Weltkrieg ein freier und unabhängiger Staat. 
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      