import styled from 'styled-components';
import { motion } from 'framer-motion';
import { styles } from '../../../../../globals';

export const TitleContainer = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 3rem;
        width: 100%;
        height: calc(1.5rem + 2px);
        z-index: 1;
        background-color: rgba(0,0,0,0.8);
        padding-bottom: 2px;
`;

export const TitleText = styled(motion.p)`
        ${{ ...styles.fonts.mobileTitleText }}
        color: ${styles.colors.beigeBg};
        position: relative;
        width: 100%;
        text-align: center;
        font-weight: 300;
`;
