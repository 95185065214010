const actions = {
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_MODAL_CONTENTS: 'SET_MODAL_CONTENTS',
  SET_MODAL_IMAGE: 'SET_MODAL_IMAGE',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_MODAL_TYPE: 'SET_MODAL_TYPE',
  CLEAR_MODAL: 'CLEAR_MODAL',
  SET_PATH: 'SET_PATH',
  TOGGLE_PLAYBACK: 'TOGGLE_PLAYBACK',
  SET_PLAYER_INSTANCE: 'SET_PLAYER_INSTANCE',
  SET_AVATAR_MESSAGE: 'SET_AVATAR_MESSAGE',
  SET_ARMY_MODAL_CONTENTS: 'SET_ARMY_MODAL_CONTENTS',
  SET_SHOW_ARMY_MODAL: 'SET_ARMY_SHOW_MODAL',
  CLEAR_ARMY_MODAL: 'CLEAR_ARMY_MODAL',
  SET_SLIDER_THEME: 'SET_SLIDER_THEME',
};

export default actions;
