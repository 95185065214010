import ModalEN1 from "../../../../src/markdown/en/chapter_2/sub_3/modals/modal-2-3-1-1.mdx";
import ModalEN2 from "../../../../src/markdown/en/chapter_2/sub_3/modals/modal-2-3-1-2.mdx";
import ModalEN3 from "../../../../src/markdown/en/chapter_2/sub_3/modals/modal-2-3-1-3.mdx";
import ModalEN4 from "../../../../src/markdown/en/chapter_2/sub_3/modals/modal-2-3-1-4.mdx";
import React from 'react';
export default {
  ModalEN1,
  ModalEN2,
  ModalEN3,
  ModalEN4,
  React
};