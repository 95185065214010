/* eslint-disable max-len */
/* eslint-disable import/named */
import React, { useRef, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import ReactPlayer from 'react-player';
import { isMobile } from 'react-device-detect';
import {
  ModalCurtain,
  VerticalModalWrapper,
  StyledModalContainer,
  StyledModal,
  CloseIcon,
  Controls,
  Control,
  Icon,
  ProgressBarContainer,
  ProgressBar,
  VolumeSlider,
  VolumeSliderGuide,
  VolumeSliderProgress,
  VolumeSliderKnob,
  Loader,
} from './styled';
import {
  fadeVariant, unfoldVariant, closeIconVariant,
} from './animations';
import playIcon from './icons/player_play.svg';
import pauseIcon from './icons/player_pause.svg';
import volumeIcon from './icons/player_volume.svg';
import muteIcon from './icons/player_mute.svg';


const Modal = (props) => {
  const {
    body, show, image, close, clear, fullWidth
  } = props;

  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [initialPlayingFlag, setInitialPlayingFlag] = useState(true);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [showVolume, setShowVolume] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const playerRef = useRef();
  const playerWrapper = useRef();
  const timeoutRef = useRef();

  useEffect(() => {
    if (playerWrapper.current) {
      playerWrapper.current.focus();
    }
  }, []);

  useEffect(() => () => {
    clearTimeout(timeoutRef.current);
  }, []);

  const closeModal = (event) => {
    if (event.key === 'Escape') {
      close();
    }
  };
  const closeModalIconHandler = (event) => {
    if (event.key === 'Enter') {
      close();
    }
  };

  const handlePlay = () => {
    if (isLoaded) {
      setIsPlaying(!isPlaying);
      setInitialPlayingFlag(!initialPlayingFlag);
    }
  };

  const closeModal2 = () => {
    close();
  };
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const convertSecs = (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(14, 5);
  };

  const handleProgress = ({ playedSeconds }) => {
    setProgress(playedSeconds);
  };

  const updateOnSeek = (seconds) => {
    setProgress(seconds);
  };

  const handleSeek = (e) => {
    if (isPlaying) setIsPlaying(false);
    const currentTargetRect = e.currentTarget.getBoundingClientRect();
    const targetWidth = e.currentTarget.offsetWidth;
    const clickedProgress = ((e.pageX - currentTargetRect.left) / targetWidth);
    playerRef.current.seekTo(clickedProgress, 'fraction');

    const mouseMove = (move) => {
      const draggedProgress = ((move.pageX - currentTargetRect.left) / targetWidth);
      playerRef.current.seekTo(draggedProgress, 'fraction');
    };

    const endSeeking = () => {
      document.removeEventListener('mouseup', endSeeking);
      document.removeEventListener('mousemove', mouseMove);
      setIsPlaying(initialPlayingFlag);
    };

    document.addEventListener('mouseup', endSeeking);
    document.addEventListener('mousemove', mouseMove);
  };

  const handleSetVolume = (e) => {
    const currentTargetRect = e.currentTarget.getBoundingClientRect();
    const targetHeight = e.currentTarget.offsetHeight;
    setIsMuted(false);

    const mouseMove = (move) => {
      const draggedVolume = (currentTargetRect.bottom - move.pageY) / targetHeight;
      if (draggedVolume < 0) {
        setVolume(0);
      } else if (draggedVolume > 1) {
        setVolume(1);
      } else {
        setVolume(draggedVolume);
      }
    };

    const endSetting = () => {
      document.removeEventListener('mouseup', endSetting);
      document.removeEventListener('mousemove', mouseMove);
    };

    document.addEventListener('mouseup', endSetting);
    document.addEventListener('mousemove', mouseMove);
  };

  const handlePlayerKeys = (e) => {
    const rewind = () => {
      let target = progress - (duration * 0.05);
      if (target < 0) target = 0;
      playerRef.current.seekTo(target, 'seconds');
    };

    const fastForward = () => {
      let target = progress + (duration * 0.05);
      if (target > duration) target = duration;
      playerRef.current.seekTo(target, 'seconds');
    };

    const volumeUp = () => {
      clearTimeout(timeoutRef.current);
      setShowVolume(true);
      timeoutRef.current = setTimeout(() => {
        setShowVolume(false);
      }, 1000);
      if (volume + 0.05 > 1) return setVolume(1);
      return setVolume(volume + 0.05);
    };

    const volumeDown = () => {
      clearTimeout(timeoutRef.current);
      setShowVolume(true);
      timeoutRef.current = setTimeout(() => {
        setShowVolume(false);
      }, 1000);
      if (volume - 0.05 < 0) return setVolume(0);
      return setVolume(volume - 0.05);
    };

    if (isLoaded) {
      switch (e.key) {
        case 'Enter':
        case ' ':
          handlePlay();
          break;
        case 'ArrowLeft':
          rewind();
          break;
        case 'ArrowRight':
          fastForward();
          break;
        case 'ArrowUp': {
          volumeUp();
          break;
        }
        case 'ArrowDown': {
          volumeDown();
          break;
        }
        default:
      }
    }
  };

  const handleVolumeMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setShowVolume(true);
  };

  const handleVolumeMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setShowVolume(false), 1000);
  };

  return (
    <AnimatePresence
      onExitComplete={clear}
    >
      {show
        && (
          <ModalCurtain
            initial="exit"
            animate="enter"
            exit="exit"
            variants={fadeVariant}
            onKeyDown={closeModal}
            onClick={closeModal2}
          >
            <VerticalModalWrapper
              isMobile={isMobile}
              initial="exit"
              animate="enter"
              exit="exit"
              variants={unfoldVariant}
              fullWidth={fullWidth}
              body={body}
              image={image}
              onClick={stopPropagation}
              onKeyDown={handlePlayerKeys}
              ref={playerWrapper}
              tabIndex="-1"
            >
              <StyledModalContainer>
                <StyledModal
                  onClick={handlePlay}
                >
                  {!isLoaded && (
                  <Loader>
                    <div />
                    <div />
                    <div />
                    <div />
                  </Loader>
                  )}
                  <ReactPlayer
                    url={image}
                    height="100%"
                    width="100%"
                    playing={isPlaying}
                    onEnded={handlePlay}
                    onDuration={(dur) => setDuration(dur)}
                    onProgress={handleProgress}
                    progressInterval={10}
                    ref={playerRef}
                    onSeek={updateOnSeek}
                    volume={volume}
                    muted={isMuted}
                    onReady={() => {
                      setIsLoaded(true);
                      setIsPlaying(true);
                    }}
                  />
                </StyledModal>
                <CloseIcon
                  onClick={close}
                  initial="exit"
                  animate="enter"
                  exit="exit"
                  variants={closeIconVariant}
                  red={((body && !image) || (image && !body))}
                  onKeyDown={closeModalIconHandler}
                />
                <Controls isMobile={isMobile}>
                  <Control>
                    <Icon
                      src={initialPlayingFlag ? pauseIcon : playIcon}
                      alt="Play"
                      onClick={handlePlay}
                    />
                  </Control>
                  <Control>
                    {`${convertSecs(progress)} / ${convertSecs(duration)}`}
                  </Control>
                  <Control>
                    <ProgressBarContainer onMouseDown={handleSeek}>
                      <ProgressBar style={{ width: `${(progress * 100) / duration}%` }} />
                    </ProgressBarContainer>
                  </Control>
                  <Control>
                    <Icon
                      src={isMuted ? muteIcon : volumeIcon}
                      alt="Volume"
                      onClick={() => setIsMuted(!isMuted)}
                      onMouseOver={handleVolumeMouseEnter}
                      onMouseLeave={handleVolumeMouseLeave}
                      onMouseMove={handleVolumeMouseEnter}
                      onFocus={() => null}
                    />
                    <VolumeSlider
                      style={{ visibility: showVolume ? 'visible' : 'hidden', opacity: showVolume ? '1' : '0' }}
                      onMouseOver={handleVolumeMouseEnter}
                      onMouseLeave={handleVolumeMouseLeave}
                      onMouseMove={handleVolumeMouseEnter}
                      onFocus={() => null}
                    >
                      <VolumeSliderGuide
                        onMouseDown={handleSetVolume}
                      >
                        <VolumeSliderProgress style={{ height: `${volume * 100}%` }} />
                        <VolumeSliderKnob style={{ bottom: `calc(${volume * 100}% - 6px)` }} />
                      </VolumeSliderGuide>
                    </VolumeSlider>
                  </Control>
                </Controls>
              </StyledModalContainer>
            </VerticalModalWrapper>

          </ModalCurtain>
        )}
    </AnimatePresence>
  );
};

export default Modal;
