import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "EN",
  "name": "Finland",
  "title": "modal-2-3-1-4"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Finnish Civil War</h2>
    <p tabIndex="0">Prior to the October Revolution, Finland had been a part of the Russian empire. Now, taking advantage of German support and turmoil in Russia, it proclaimed independence in December 1917. The Bolsheviks acknowledged Finland’s independence, but they did not intend to give up on their “exporting of the revolution” to Finland. <strong>“Red Guards”</strong> in Helsinki, supported by dissenting soldiers from the former Russian garrisons, proclaimed a Soviet Republic. The Russian Bolsheviks granted “fraternal aid” by intervening militarily. In the north of the country, <strong>Karl Mannerheim</strong>, aided by Swedish volunteers and Germany, established a national government and army, which ousted “the Reds” and sent them back to Russia. After the First World War, Finland remained a free and independent state.   
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      