const transition = {
  duration: 1,
  ease: [0.820, 0.000, 0.195, 1.000],
};

export const fadeVariant = {
  exit: {
    opacity: 0,
    clipPath: 'inset(0 50%)',
    transition,
  },
  enter: {
    opacity: 1,
    clipPath: 'inset(0 0%)',
    transition,
  },
};
