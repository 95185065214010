import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { Container, Avatar, Message } from './styles';

const AvatarMessage = ({
  fixed, children, alt, scale, style,
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
  });
  const avatarMessage = useSelector((state) => state.avatarMessage);
  const dispatch = useDispatch();

  useEffect(() => () => dispatch({ type: 'SET_AVATAR_MESSAGE', payload: '' }), []);

  return (
    <Container ref={ref} style={style}>
      <Avatar visible={inView && scale} fixed={fixed} alt={alt} />
      <Message>
        {avatarMessage || children}
      </Message>
    </Container>
  );
};

export default AvatarMessage;
