import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "EN",
  "name": "Lew Trocki",
  "title": "modal-2-3-1-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Leon Trotsky - man no. 2 of the Revolution</h2>
    <p tabIndex="0">“Polish lords and gentry will snatch a temporary, marauders' victory, but when we have finished with Denikin, we shall throw the full weight of our reserves onto the Polish front”. </p>
    <p tabIndex="0">Leon Trotsky, 15 August 1919 in the French “L’Internationale Communiste”. </p>
    <p tabIndex="0">As the leading organiser of the <strong>Red Army</strong>, Trotsky propagated the idea of spreading the revolution beyond the borders of the Bolshevik Russia. </p>
    <p tabIndex="0">Trotsky was born <strong>Lev Bronstein</strong> to a poor, peasant family in the south of Ukraine (a part of the Russian empire back then). Despite their impoverished circumstances, the Bronsteins sent Lev to the St. Peter and Paul School in Odessa. On graduating, he joined the radical movement of the <strong>Narodniks</strong>, which postulated the democratisation of the state. As time went by, the views of Trotsky (this is the alias he had chosen) became more radical and he abandoned the <strong>Narodniks for Marxism</strong>. He paid a high price for being the leader of his own <strong>revolutionary</strong> organisation, as he was sentenced to two years in prison and deported to Siberia. After two years, Trotsky escaped from exile and travelled to Western Europe, where he established <strong>contact with Lenin</strong> in London.  </p>
    <p tabIndex="0">He returned to Russia in 1905 in order to join the revolution. He was the last leader of the <strong>Petrograd Soviet Council of Worker Deputies</strong>. He was arrested after the revolution had been suppressed and sentenced to a lifetime’s exile in Siberia. He managed to escape yet again and journeyed to Switzerland; and from there to France. Being the supporter of the <strong>global revolution of the proletariat</strong>, he came out against the Great War. He voiced his opinions in the “New Word” published in Russian. Threatened with extradition to Russia, he fled to New York.  </p>
    <p tabIndex="0">He returned to Russia after the February Revolution broke out, joining the Bolsheviks in September 1917. During the <strong>October Revolution</strong>, he oversaw military actions in the first days of the rebellion. Soon, he became the <strong>Commissar for the Foreign Affairs of Bolshevik Russia</strong>.</p>
    <p tabIndex="0">In September 1918, the Bolshevik political leaders decided to turn Soviet Russia into a <strong>military camp</strong>.  Sergey Kamenev became the leader of the Red Army, albeit he was subordinate to the Revolutionary Military Council. Trotsky became the Council’s leader, which made him the <strong>true commander of the Bolshevik army</strong> and <strong>the second person in the country</strong> after Lenin.  </p>
    <p tabIndex="0">Trotsky supported the notion of <strong>“exporting revolution”</strong> to the West. He initially believed that this should be achieved by firstly destroying the colonial empires in Asia, which he thought would in turn lead to crisis and <strong>revolution in Great Britain</strong>, the United States and France. “The road to London and Paris goes through Calcutta”, he would say. After revolution had broken out in Germany, he changed his mind and became one of the most ardent supporters of an <strong>attack on Poland</strong>, as a “partition” between Russia and Germany.  </p> 
    <p tabIndex="0">After <strong>Lenin’s death</strong>, Trotsky fought for the position of “Chief of the Revolution”, with his rivals being Lev Kamenev (his former brother-in-law), Grigory Zinoviev and <strong>Joseph Stalin</strong>. Eventually, Stalin emerged victorious.  Trotsky found himself gradually ousted from leading positions in the USSR. He left the Soviet Russia in 1929, fearing for his life.  </p>
    <p tabIndex="0">He resided in Turkey, France, Norway and Mexico, and became a vocal critic of the USSR, claiming that it had departed from the idea of a global revolution. In this spirit, Trotsky established the <strong>Fourth International</strong> – an organisation of communist parties ill-disposed towards Stalin’s policies.</p>
    <p tabIndex="0"><strong>The NKVD</strong> (People's Commissariat for Internal Affairs of the USSR - Soviet political police) were set on hunting Trotsky down. His entire family, besides his wife, was murdered on Stalin’s orders. Trotsky himself survived three attempted assassinations, but the fourth one proved successful. On 20 August 1940, Spanish communist and NKVD agent, Ramón Mercader, wounded Trotsky in the head with an ice axe. The founder of the Red Army <strong>died after an agonizing 20 hours, on 21 August 1940</strong>.  
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      