import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import actions from './actions';

const initialState = {
  language: 'PL',
  showModal: false,
  modalContents: null,
  modalImage: null,
  modalType: 'horizontal',
  path: '/',
  playerInstance: null,
  playerIsPlaying: false,
  avatarMessage: '',
  armyModalContents: null,
  showArmyModal: false,
  sliderTheme: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_LANGUAGE:
      return { ...state, language: action.payload };
    case actions.SET_MODAL_CONTENTS:
      return { ...state, modalContents: action.payload };
    case actions.SET_ARMY_MODAL_CONTENTS:
        return { ...state, armyModalContents: action.payload };
    case actions.SET_MODAL_IMAGE:
      return { ...state, modalImage: action.payload };
    case actions.SET_SHOW_MODAL:
      return { ...state, showModal: action.payload };
    case actions.SET_SHOW_ARMY_MODAL:
        return { ...state, showArmyModal: action.payload };
    case actions.SET_MODAL_TYPE:
      return { ...state, modalType: action.payload };
    case actions.CLEAR_MODAL:
      return {
        ...state,
        modalType: initialState.modalType,
        modalImage: initialState.modalImage,
        modalContents: initialState.modalContents,
      };
    case actions.CLEAR_ARMY_MODAL:
        return {
          ...state,
          armyModalContents: initialState.armyModalContents,
        };
    case actions.SET_PATH:
      return { ...state, path: action.payload };
    case actions.TOGGLE_PLAYBACK:
      return { ...state, playerIsPlaying: !state.playerIsPlaying };
    case actions.SET_PLAYER_INSTANCE:
      return { ...state, playerInstance: action.payload };
    case actions.SET_AVATAR_MESSAGE:
      return { ...state, avatarMessage: action.payload };
    case actions.SET_SLIDER_THEME:
      return { ...state, sliderTheme: action.payload };
    default:
      return state;
  }
}

// preloadedState will be passed in by the plugin
export default (preloadedState) => createStore(reducer, preloadedState, composeWithDevTools());
