import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "DE",
  "name": "DEUTSCHLAND",
  "title": "modal-2-3-1-2"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Die Revolution in Deutschland </h2>
    <p tabIndex="0">Im November 1918 war die innere Situation im Deutschen Kaiserreich schlechter als an der Front des Großen Krieges. Der seit vier Jahren dauernde Konflikt erschöpfte das Land. Beschwerlich waren vor allem die durch die Seeblockade verursachten wirtschaftlichen Probleme. Die Deutschen haben seit Jahren keinen Kaffee und keine Schokolade gesehen, Kartoffel und Brot ohne Sägemehl und andere Zusätze waren ein Luxus. Dagegen kamen die Ersatze der notwendigsten Produkten zur allgemeinen Anwendung - es wurden mehr als 11 Tsd. von ihnen ausgearbeitet. 
    </p>
    <p tabIndex="0">
DIE ERZATZE:
    </p>
    <p tabIndex="0">
Butter -> Fett mit gelbem Farbstoff
    </p>
    <p tabIndex="0">
Kaffe -> Aufguss auf Eichelmehl-Basis
    </p>
    <p tabIndex="0">
Fleisch/Wurst  -> Kartoffel-Wurst
    </p>
    <p tabIndex="0">
Die Sackgasse an der Westfront und die wirtschaftliche Krise führten zu Soldatenaufständen und zur Unzufriedenheit der Zivisten. Anfang November 1918 kam es zu einem Matrosenaufstand, bei dem die Matrosen die Hafen- und Industriestädte eingenommen haben, und angefangen haben, Arbeiter- und Soldatenräte zu bilden. Der Aufstand wurde nicht durch die deutschen Sozialdemokraten der SPD unterstützt, dafür stellten sich aber auf die Seite der Matrosen die Marxisten-Maximalisten aus dem Spartakusbund, die schon früher dazu agitiert haben, Deutschland in ein Land mit einem ähnlichen politischen System wie in dem sowjetischen Russland zu verwandeln. 
    </p>
    <p tabIndex="0">
Die Revolution ergriff fast vom gesamten Land Besitz. Der Kaiser Wilhelm II. war zum Abdanken genötigt und floh ins neutrale Holland. Am 9. November hat der Spartakus-Anführer Karl Liebknecht die Sozialistische Republik ausgerufen. Das Militär, welches bislang die tatsächliche Herrschaft im Staat innehatte, hat die Chance erblickt, zusammen mit den Sozialdemokraten die Revolution niederzuschlagen. Dank der Zusammenarbeit der Armee und der SPD gelang es, den Sieg der Kommunisten zu vereiteln.
    </p>
    <p tabIndex="0">
Der Höhepunkt der Revolution war der sog. „Spartakusaufstand“ - ein bewaffnetes Antreten der Kampftruppen der KPD (Kommunistischen Partei Deutschlands) gegen die Regierung der Sozialdemokraten.  Die Situation wurde durch die Regierung unter Kontrolle gebracht, und die Anführer des Aufstands sind umgekommen. Unter ihnen war Karl Liebknecht und Rosa Luxemburg.
    </p>
    <p tabIndex="0">
Sie Revolutionäre hielten sich am längsten in Baiern auf, wo sie die Räterepublik Baiern bildeten. Das Regime fiel aber nach der Intervention der Armee Anfang Mai 1919.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      