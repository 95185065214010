import styled, { css, keyframes } from 'styled-components';
import { fonts, colors } from '../../../../../globals';

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0,70px,0);
  }
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
`;

const animationTop = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};
const animationBottom = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 400ms forwards
    `;
  }
  return null;
};
const animationTopSecondary = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 1.2s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};

export const ColumnContent = styled.div`
  height: 100%;
  margin-right: 2rem;
  padding-left: 15vw;
  display: flex;
  flex-direction: column;
  justify-content: center;


  & > h2 {
    /* ${{ ...fonts.headline_1 }} */
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    ${(props) => (props.largeHeader ? { ...fonts.headline_1_large } : { ...fonts.headline_1 })}
    position: relative;
    opacity: 0;
    transform:translate3d(0,60px,0);
    animation: ${animationTop};
  }

  & > h3 {
    ${{ ...fonts.headline_3a }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    position: relative;
    opacity: 0;
    margin-bottom: 2.43rem;
    animation: ${animationTopSecondary};
    ${({ smallerTitle }) => smallerTitle && `
       font-size: 2.8125rem;
       line-height: 1;
    `}
  }

  & > p, & > div > p {
    ${{ ...fonts.paragraf }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })};
    max-width: ${({ customWidth }) => (customWidth ? 'auto' : '398px')};
    display: inline;
    ${({ smallerText }) => smallerText && `
      font-size: 0.875rem;
      line-height: 1.4rem;
    `}
  }
  span {
    font-size: 1rem;
    line-height: 1.62rem;
    letter-spacing: 0.03rem;
  }
`;

export const Wrapper = styled.div`
  width: ${(props) => (props.longText ? '28rem' : '22rem')};
  margin-left: ${(props) => (props.marginLeft ? '2rem' : 0)};
  max-zoom: 40%;
  position: relative;
  opacity: 0;
  animation: ${animationBottom};
  ${({ twoColumns }) => twoColumns
    && css`
      margin: 0 auto;
      display:${twoColumns ? 'flex' : 'block'};
      width: ${twoColumns ? '58rem' : '22rem'};
      & > p {
        margin-right: 4rem;
        }
      `}
  ${({ paragraphWidth }) => paragraphWidth && `
    width: ${paragraphWidth}rem;
  `}
  ${({ centerText }) => centerText
    && css`
      margin: 0 auto;
      width: ${centerText ? '44rem' : '22rem'};
      text-align: left;
      `}

  ${({ biggerPP }) => biggerPP
    && css`
      & > p, & > div > p {
        font-size: 1.15rem !important;
        }
      `}
`;
