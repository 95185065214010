import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SelectorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 5rem;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(0.2rem);
`;

export const CircleBorder = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255,255,255,0.3);
    border-radius: 50%;
    cursor: pointer;
    transform: scale(0.75);
`;

export const FlagCircle = styled.div`
    width: 2rem;
    height: 1.8rem;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    & #flag {
        width: 110% !important;
        height: 110% !important;
        background-repeat: no-repeat;
    }
`;

export const SelectedFlagAndArrow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const Arrow = styled.img`
    height: 1rem;
    transition: 0.3s;
    filter: invert(1);
    transform: rotate(${(props) => (props.turned ? '180deg' : '0deg')});
`;

export const Dropdown = styled.ul`
    list-style: none;
    margin-top: 0.2rem;
    user-select: none;
    max-height: 13rem;
    overflow: hidden;
`;

export const DropdownElement = styled(motion.li)`
    display: flex;
    width: 5rem;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    max-height: 0px;
    cursor: pointer;
    transform: translateX(5%);
    & #flag {
        width: 110% !important;
        height: 110% !important;
    }
`;
