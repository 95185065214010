import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "HU",
  "name": "LEV TROCKIJ",
  "title": "modal-2-3-1-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Lev Trockij</h2>
    <p tabIndex="0">„A lengyel pánok-nemesek kizsigerelik a pillanatnyi győzelmet – a martalócok győzelmét – de ha végzünk Gyenyikinnel, minden tartalékunkat a lengyel frontra dobjuk át”</p>
    <p tabIndex="0">Lev Trockij, 1919. augusztus 15, a francia „L’Internationale Communiste” hasábjain</p> 
    <p tabIndex="0">Nagy propagátora volt a <strong>forradalom</strong> bolsevik Oroszország határain kívüli <strong>kiterjesztésének</strong> és a <strong>Vörös Hadsereg</strong> legfőbb szervezője.</p> 
    <p tabIndex="0"><strong>Leb Davidovics Bronsteinként</strong> látta meg a napvilágot dél Ukrajnában (akkor az orosz birodalom része) szegény parasztcsalád gyermekeként. Nehéz anyagi körülményeik dacára a Bronstein család tanulni küldte a fiatal Levet Odesszába a Szent Péter és Pál Reáliskolába. Az iskola után a radikális <strong>narodnyik mozgalomhoz</strong> kötődött, mely az állam demokratizálását követelte. Az idő múlásával Trockij nézetei (ezt a fedőnevet választotta) radikalizálódtak és a marxizmus kedvéért otthagyta a narodnyikokat. <strong>Forradalmi szervezetének</strong> élén elnökként két év börtönnel fizetett tevékenységéért, majd Szibériába száműzték. Két év múltán Trockij megszökött a száműzetésből és külföldre, Nyugat-Európába utazott. Londonban lépett kapcsolatba <strong>Leninnel</strong>.</p> 
    <p tabIndex="0">1905-ben tért vissza Oroszországba, hogy részt vállaljon a forradalomban. Utolsó elnöke volt a <strong>Pétervári Munkástanácsnak</strong>. A forradalom leverését követően letartóztatták és életfogytiglani szibériai száműzetésre ítélték. Ezúttal is sikerült megszöknie és eljutnia Svájcba, majd Franciaországba. A <strong>proletariátus világforradalmának</strong> híveként határozottan ellene volt a világháborúnak. Nézeteit az orosz nyelven megjelenő „Új Világ” c. lapban hangoztatta. Az Oroszországnak való kiadatás veszélye miatt New Yorkba menekült.</p>
    <p tabIndex="0">Az 1917 évi februári forradalom kitörése után tért vissza Oroszországba. 1917 szeptemberében csatlakozott a bolsevikokhoz. Az <strong>októberi forradalom</strong> idején a rebellió első napjaiban irányította a fegyveres cselekményeket. Rövidesen átvette a <strong>bolsevik Oroszország külügyi népbiztosa</strong> beosztását.</p>
    <p tabIndex="0">1918 szeptemberében a bolsevik politikai vezetés határozott a Szovjet Oroszország <strong>katonai táborrá</strong> alakításáról. A Vörös Hadsereg élére Szergej Kamenyev állt, aki a Forradalmi Háborús Tanács alárendeltségében tevékenykedett. A Tanács élére került Trockij, ami azt jelentette, hogy ő volt a bolsevik hadsereg <strong>tényleges parancsnoka</strong> és – Lenin után – <strong>az állam második embere</strong>.</p>
    <p tabIndex="0">Trockij híve volt a Nyugatra irányuló „<strong>forradalom exportjának</strong>”. Kezdetben úgy vélte, hogy először le kell bontani az ázsiai gyarmatbirodalmakat, ami – véleménye szerint – válságot és <strong>forradalmat</strong> idézne elő <strong>Nagy-Britanniában</strong>, az Egyesült Államokban és Franciaországban. „A Londonba és Párizsba vezető út Kalkuttán át vezet” – hangoztatta állítólag. A Németországban kirobbant forradalom után változtatott véleményén és egyike volt a <strong>Lengyelország elleni támadás</strong> hirdetőinek, mert ez az „akadály” választotta el Oroszországot Németországtól.</p>
    <p tabIndex="0"><strong>Lenin halálát követően</strong> küzdött a „forradalom vezére” cím átvételéért egyrészt Lev Kamenyevvel (volt sógorával), Grigorij Zinovjevvel és <strong>Josef Sztálinnal</strong>. Végül is ez utóbbi lett a győztes. Trockij végül is fokozatosan kikerült a Szovjet Oroszország legfontosabb beosztásaiból. 1929-ben életét féltve elhagyta Szovjet Oroszországot.</p>
    <p tabIndex="0">Emigráns idejét Törökországban, Franciaországban, Norvégiában és Mexikóban töltötte. Bírálta a Szovjet Oroszországban uralkodó rendszert, szerinte eltértek a világforradalom eszméjétől. Ennek szellemében hívta életre a <strong>IV. Internacionálét </strong>– azt a szervezetet, amely a Sztálin politikájával egyet nem értő kommunista pártokat tömörítette.</p>
    <p tabIndex="0">Az <strong>NKVD</strong> (Belügyi Népbiztosság, a szovjet politikai rendőrség rövidítése) üldözte. Trockij egész családját (kivéve feleségét) Sztálin parancsára meggyilkolták. Maga Trockij három élete ellen irányuló merényletet élt túl. A negyedik sikeresnek bizonyult. 1940. augusztus 20-án a spanyol kommunista NKVD ügynök Ramón Mercader Trockijt egy csákánnyal megsebesítette a fején. A Vörös Hadsereg egykori alapítója, parancsnoka húsz órával később, 1940. augusztus 21-én belehalt sérüléseibe</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      