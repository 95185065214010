/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable import/named */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'src/store/actions';
import { isMobile } from 'react-device-detect';

import HorizontalModal from './modalHorizontal/modalHorizontal';
import VerticalModal from './modalVertical/modalVertical';
import VideoModal from './modalVideo/modalVideo';
import MobileModal from './modalMobile/modalMobile';

const Modal = (props) => {
  const show = useSelector((state) => state.showModal);
  const body = useSelector((state) => state.modalContents);
  const image = useSelector((state) => state.modalImage);
  const dispatch = useDispatch();
  let type = useSelector((state) => state.modalType);
  const closeHandler = () => {
    dispatch({ type: actions.SET_SHOW_MODAL, payload: false });
  };
  const clearHandler = () => {
    dispatch({ type: actions.CLEAR_MODAL });
  };
  if (!type) type = 'horizontal';
  if ((!image || !body) && type !== 'video') type = 'vertical';
  if (type !== 'video' && isMobile) type = 'mobile';

  switch (type) {
    case 'horizontal':
      return <HorizontalModal {...props} show={show} image={image} body={body} close={closeHandler} clear={clearHandler} />;
    case 'vertical':
      return <VerticalModal {...props} show={show} image={image} body={body} close={closeHandler} clear={clearHandler} />;
    case 'mobile':
      return <MobileModal {...props} show={show} image={image} body={body} close={closeHandler} clear={clearHandler} />;
    case 'video':
      return <VideoModal {...props} show={show} image={image} body={body} close={closeHandler} clear={clearHandler} />;
    default:
      return null;
  }
};

export default Modal;
