import React, { useState, useEffect } from 'react';
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions';
import { AnimatePresence, motion } from 'framer-motion';
import Modal from '../../_shared/modal/modal';
import ArmyModal from '../../_shared/modal/armyModal';
import GlobalStyle, { ApplicationWrapper } from './styles/styles';
import SideBar from '../sideBar/sideBar';
import PageTitle from '../pageTitle/pageTitle';
import ScrollTransition, {
  tranistionAnimations,
} from '../scrollTransition/scrollTransition';
import ScrollIcon from '../scrollIcon/scrollIcon';
import OldBrowser from '../oldBrowser/oldBrowser.jsx';
const Layout = ({ location, path, children }) => {
  const [removeui, setRemoveUI] = useState(true);
  useEffect(() => {
    document.body.addEventListener('keyup', (e) => {
      if (e.which === 9) {
        document.documentElement.classList.remove('no-focus-outline');
      }
    }); 
  },[])

  useEffect(() => {
    const introPages = [
      '/pl/',
      '/en/',
      '/fr/',
      '/de/',
      '/ru/',
      '/ua/',
      '/hu/',
      '/',
    ];
    if (introPages.find((el) => el === path)) {
      setRemoveUI(true);
    } else {
      setRemoveUI(false);
    }
  }, [path]);

  const oldBrowser = () => {
    const userData = window.navigator.userAgent.split(" ");
    const allBrowsers = [
      { name: 'Chrome',
        minVersion: 70 },
      { name: 'Firefox',
        minVersion: 65 },
      { name: 'Version',
        minVersion: 12 },
      { name: 'Edge',
        minVersion: 18 },
      { name: 'Trident',
        minVersion: 8 }
    ]
    const checkBrowser = (engineName) => {
      return userData.filter(x => {return x.includes(engineName);})
    }

    let isBrowserOld = false;

    allBrowsers.forEach( x => {
      const engine = checkBrowser(x.name);
      if (engine.length > 0) {
        if ( parseInt(engine[0].split('/')[1]) < x.minVersion ) { 
          isBrowserOld = true;
        }
      }
    })
    return isBrowserOld;
  }
  return (
    <>
      <GlobalStyle />
      <ApplicationWrapper>
        <TransitionProvider
          location={location}
          mode="immediate"
          {...tranistionAnimations('default')}
        >
          <Modal />
          <ArmyModal />
          <ScrollTransition path={path} />
          {!removeui && (
            <AnimatePresence>
              <SideBar
                path={path}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
              />
            </AnimatePresence>
          )}
          {!removeui && (
            <AnimatePresence>
              <PageTitle
                path={path}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
              />
            </AnimatePresence>
          )}
          <main>
            {!oldBrowser() ? (
              <TransitionViews>{children}</TransitionViews>
            ) : <OldBrowser/>}
          </main>
          {!removeui && (
            <AnimatePresence>
              <ScrollIcon
                path={path}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
              />
            </AnimatePresence>
          )}
        </TransitionProvider>
      </ApplicationWrapper>
    </>
  );
};

export default Layout;
