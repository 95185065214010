import React from 'react';
import HamburgerSqueeze from 'react-animated-burgers/lib/components/HamburgerSqueeze';
import { Link } from 'gatsby';
import { getLangFromPath } from 'src/utils/index.js';
import { HeaderContainer } from './styles';
import LanguageSelector from './languageSelector/languageSelector';


const Header = ({ isVisible, setVisible, path }) => {
  const lang = path.split('/')[1].toUpperCase();
  const url = require(`images/_shared/logo_${lang === 'PL' ? 'PL' : 'EN'}.svg`);

  return (
    <HeaderContainer>
      <HamburgerSqueeze
        buttonWidth={30}
        barColor="#f2efe4"
        isActive={isVisible}
        toggleButton={() => setVisible(!isVisible)}
        style={{ transform: 'scale(0.75)' }}
      />
      <Link to={`/${getLangFromPath().toLowerCase()}`}>
        <img
          alt="Logo"
          src={url}
          style={{
            height: '98%', position: 'absolute', left: '50%', transform: 'translateX(-50%) scale(0.75)', top: '5%',
          }}
        />
      </Link>
      <LanguageSelector path={path} />
    </HeaderContainer>
  );
};


export default Header;
