import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "FR",
  "name": "Hongrie",
  "title": "modal-2-3-1-3"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Révolution en Hongrie</h2>
    <p tabIndex="0">La défaite de la Première Guerre mondiale discrédite la dynastie des Habsbourg et provoque l'éclatement de l'Autriche-Hongrie. À l'automne 1918, une révolution a eu lieu en Hongrie et en Autriche, qui a écarté l'empereur et le roi du pouvoir.  Les communistes envoyés en Hongrie depuis Moscou ont tenté de prendre le pouvoir en Hongrie en février 1919, mais la tentative de coup d'État a échoué. Ils n'ont pris le pouvoir que le 21 mars 1919, face à la défaite du président Mihaly Karolyi et du gouvernement de Denes Berinkei. Ce jour-là, ils ont créé, avec les sociaux-démocrates et les hongrois, <strong> la République des Conseils</strong> sous la direction de <strong>Bela Kun</strong>. Ils ont semé la terreur dans le pays sur le modèle de celle que les bolcheviques ont fait en Russie. L'une des activités des communistes a été la création de milices combattant la contre-révolution - <strong>„Les garçons de Lénine”</strong>. Les communistes hongrois ont également réussi à créer pendant une courte période le <strong>Conseil de la République slovaque</strong>. Au début du mois d'août 1919, le WRR prit fin - l'armée roumaine, qui coopérait avec l'Entente, occupa la majeure partie du territoire, dont Budapest. Après la chute du régime communiste, le pouvoir a été repris par l'amiral Miklos Horthy, et les gouvernements d'extrême droite, qui ont pourchassé les représentants du régime précédent.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      