import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "UA",
  "name": "Lew Trocki",
  "title": "modal-2-3-1-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Лев Троцький - людина №&nbsp;2&nbsp;революції</h2>
    <p tabIndex="0"> «Польські пани, шляхта вирвуть тимчасову перемогу - перемогу мародерів, але коли ми закінчимо з&nbsp;Денікіним, ми&nbsp;кинемо усі наші резерви на&nbsp;польський фронт»</p>
    <p tabIndex="0">Лев Троцький, 15&nbsp;серпня 1919 року на&nbsp;сторінках французької «L'&nbsp;Internationale Communiste»</p>
    <p tabIndex="0">Він був пропагандистом ідеї <strong>розширення революції</strong> за&nbsp;межі більшовицької Росії і&nbsp;головним організатором <strong>Червоної Армії</strong>.</p>
    <p tabIndex="0">Народився у&nbsp;бідній селянській сім'ї на&nbsp;півдні України (яка тоді входила до складу Російської імперії). Його ім'я при народженні - <strong>Лейба Бронштейн</strong>. Незважаючи на складні фінансові умови, сім'я Бронштейнів відправила Льва до реального училища Св. Петра і&nbsp;Павла в&nbsp;Одесі. Після закінчення училища він почав брати участь в&nbsp;радикальному <strong>народницькому русі</strong>, який постулював демократизацію держави. З&nbsp;часом погляди Троцького (такий псевдонім він використав) стали радикальними, він відмовився від народництва на користь марксизму. Він заплатив за керівництво своєю <strong>революційною організацією</strong> двома роками ув'язнення і&nbsp;зісланням в&nbsp;Сибір. Через два роки Троцький втік з&nbsp;зіслання і&nbsp;поїхав за&nbsp;кордон в&nbsp;Західну Європу. У&nbsp;Лондоні він налагодив контакти з&nbsp;<strong>Леніним</strong>.</p>
    <p tabIndex="0">У 1905 році він повернувся в&nbsp;Росію, щоб взяти участь в&nbsp;революції. Був останнім Головою <strong>Петроградської ради робітників</strong>. Після подавлення революції його заарештували і&nbsp;засудили до&nbsp;довічного зіслання в&nbsp;Сибір. І&nbsp;цього разу йому вдалося втекти і&nbsp;пробратися в&nbsp;Швейцарію, а&nbsp;потім у&nbsp;Францію. Будучи прибічником <strong>світової революції пролетаріату</strong>, він рішуче виступав проти Першої світової війни. Свої погляди проповідував в&nbsp;російськомовній газеті «Нове слово». Під загрозою видалення в&nbsp;Росію він втік у&nbsp;Нью-Йорк.</p>
    <p tabIndex="0">Після початку Лютневої революції 1917 року він повернувся в&nbsp;Росію. У&nbsp;вересні 1917 року приєднався до більшовиків. Під час <strong>Жовтневої революції</strong> керував військовими операціями в&nbsp;перші дні повстання. Незабаром зайняв пост <strong>наркома закордонних справ більшовицької Росії</strong>.</p>
    <p tabIndex="0">У вересні 1918 року політичне керівництво більшовиків прийняло рішення перетворити Радянську Росію на <strong>військовий табір</strong>. Сергій Каменев став головнокомандувачем Червоною Армією, але він підкорявся Революційній військовій раді. Раду очолив Троцький, що&nbsp;зробило його <strong>фактично командувачем більшовицькою армією</strong> і&nbsp;<strong>другою після Леніна особою в державі</strong>.</p>
    <p tabIndex="0">Троцький був прибічником ідеї <strong>«експорту революції»</strong> на&nbsp;захід. Спочатку він думав, що&nbsp;це&nbsp;повинно бути здійснено шляхом руйнування колоніальних імперій в&nbsp;Азії, що, на&nbsp;його думку, викликало б&nbsp;кризу і&nbsp;<strong>революцію у&nbsp;Великобританії</strong>, США та&nbsp;Франції. «Дорога в&nbsp;Лондон і&nbsp;Париж веде через Калькутту» - говорив він. Після початку революції в&nbsp;Німеччині він передумав і&nbsp;був одним з&nbsp;прибічників <strong>нападу на Польщу</strong> як на «перешкоду», що розділяє Росію і&nbsp;Німеччину.</p> 
    <p tabIndex="0"><strong>Після смерті Леніна</strong> він хотів зайняти місце «вождя революції» і&nbsp;боровся за владу з&nbsp;Львом Каменевим (своїм колишнім шуряком), Григорієм Зінов'євим і&nbsp;<strong>Йосипом Сталіном</strong>. Кінець кінцем, виграв останній. Троцького поступово відсували від важливих посад в&nbsp;СРСР. У 1929 році, побоюючись за&nbsp;своє життя, він покинув Радянську Росію.</p>
    <p tabIndex="0">Він жив в&nbsp;еміграції в&nbsp;Туреччині, Франції, Норвегії і&nbsp;Мексиці. Критикував систему СРСР як відхід від ідеї світової революції. У&nbsp;цьому дусі він заснував <strong>IV&nbsp;Інтернаціонал</strong> - організацію комуністичних партій, що заперечує політику Сталіна.</p>
    <p tabIndex="0">Скрізь за ним стежив <strong>НКВД</strong> (Народний комісаріат внутрішніх справ СРСР, радянська політична поліція). Уся сім'я Троцького, окрім його дружини, була вбита за&nbsp;вказівкою Сталіна. Сам Троцький пережив три замахи на своє життя. Четвертий завершився успішно. 20 серпня 1940 року іспанський комуніст і&nbsp;агент НКВД Рамон Меркадер вбив Троцького, ударивши льодорубом по голові. Людина, яка колись створила Червону Армію, померла після двадцяти годин страждань 21 серпня 1940 року.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      