/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import { getRoutesQuery } from 'src/utils';
import { getLangFromPath } from 'src/utils/index.js';
import { TitleContainer, TitleText, TitleHeader } from './styles';
import { fadeVariant } from './styles/animations';

const PageTitle = ({ path, style }) => {
  const [currentTitle, setCurrentTitle] = useState('');
  const [currentHeader, setCurrentHeader] = useState('');
  const [darkTheme, setDarkTheme] = useState(false);
  const query = getRoutesQuery();
  const pathLocale = getLangFromPath();
  const sliderTheme = useSelector((state) => state.sliderTheme);

  const headerVersions = {
    PL: 'Bitwa Warszawska',
    EN: 'Battle of Warsaw',
    FR: 'Bataille de Varsovie',
    DE: 'Die Schlacht bei Warschau',
    RU: 'Варшавская битва', // TODO: zweryfikować tłumaczenie, wzięte z ,pliku meta
    UA: 'Варшавська битва',
    HU: 'A varsói csata',
  };

  useEffect(() => {
    if (pathLocale) {
      const { routes } = query[pathLocale].exports;
      setCurrentHeader(headerVersions[pathLocale.toUpperCase()]);
      routes.forEach(({ route, pageTitle, isDark }) => {
        if (encodeURI(route) === path) {
          setCurrentTitle(pageTitle);
          setDarkTheme(isDark);
        }
      });
    }
  }, [path]);

  useEffect(() => {
    sliderTheme === "dark" && setDarkTheme(false);
    // sliderTheme === "light" && setDarkTheme(false);
    console.log(sliderTheme);
  }, [sliderTheme]);

  return (
    <AnimatePresence exitBeforeEnter>
      <TitleContainer
        initial="exit"
        animate="enter"
        exit="exit"
        variants={fadeVariant}
        key={currentTitle}
        style={{ ...style }}
      >
        <TitleHeader dark={darkTheme}>{currentHeader}</TitleHeader>
        <TitleText dark={darkTheme} tabindex="0">{currentTitle}</TitleText>
      </TitleContainer>
    </AnimatePresence>
  );
};

export default PageTitle;
