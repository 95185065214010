// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-de-about-index-jsx": () => import("./../src/pages/de/about/index.jsx" /* webpackChunkName: "component---src-pages-de-about-index-jsx" */),
  "component---src-pages-de-auf-dem-weg-zur-weltrevolution-index-jsx": () => import("./../src/pages/de/auf-dem-weg-zur-weltrevolution/index.jsx" /* webpackChunkName: "component---src-pages-de-auf-dem-weg-zur-weltrevolution-index-jsx" */),
  "component---src-pages-de-dabernon-index-jsx": () => import("./../src/pages/de/dabernon/index.jsx" /* webpackChunkName: "component---src-pages-de-dabernon-index-jsx" */),
  "component---src-pages-de-das-entscheidende-gefecht-index-jsx": () => import("./../src/pages/de/das-entscheidende-gefecht/index.jsx" /* webpackChunkName: "component---src-pages-de-das-entscheidende-gefecht-index-jsx" */),
  "component---src-pages-de-das-konzept-des-staatenbundes-index-jsx": () => import("./../src/pages/de/das-konzept-des-staatenbundes/index.jsx" /* webpackChunkName: "component---src-pages-de-das-konzept-des-staatenbundes-index-jsx" */),
  "component---src-pages-de-das-morgengrauen-des-neuen-europas-index-jsx": () => import("./../src/pages/de/das-morgengrauen-des-neuen-europas/index.jsx" /* webpackChunkName: "component---src-pages-de-das-morgengrauen-des-neuen-europas-index-jsx" */),
  "component---src-pages-de-das-uhrwerk-der-geschichte-anhalten-index-jsx": () => import("./../src/pages/de/das-uhrwerk-der-geschichte-anhalten/index.jsx" /* webpackChunkName: "component---src-pages-de-das-uhrwerk-der-geschichte-anhalten-index-jsx" */),
  "component---src-pages-de-der-augenblick-der-wahrheit-index-jsx": () => import("./../src/pages/de/der-augenblick-der-wahrheit/index.jsx" /* webpackChunkName: "component---src-pages-de-der-augenblick-der-wahrheit-index-jsx" */),
  "component---src-pages-de-der-grosse-krieg-verandert-die-welt-index-jsx": () => import("./../src/pages/de/der-große-krieg-verändert-die-welt/index.jsx" /* webpackChunkName: "component---src-pages-de-der-grosse-krieg-verandert-die-welt-index-jsx" */),
  "component---src-pages-de-der-krieg-der-chiffren-index-jsx": () => import("./../src/pages/de/der-krieg-der-chiffren/index.jsx" /* webpackChunkName: "component---src-pages-de-der-krieg-der-chiffren-index-jsx" */),
  "component---src-pages-de-die-folgen-fur-die-welt-index-jsx": () => import("./../src/pages/de/die-folgen-fur-die-welt/index.jsx" /* webpackChunkName: "component---src-pages-de-die-folgen-fur-die-welt-index-jsx" */),
  "component---src-pages-de-die-grosse-rote-offensive-index-jsx": () => import("./../src/pages/de/die-große-rote-Offensive/index.jsx" /* webpackChunkName: "component---src-pages-de-die-grosse-rote-offensive-index-jsx" */),
  "component---src-pages-de-die-hilfe-aus-der-welt-index-jsx": () => import("./../src/pages/de/die-hilfe-aus-der-welt/index.jsx" /* webpackChunkName: "component---src-pages-de-die-hilfe-aus-der-welt-index-jsx" */),
  "component---src-pages-de-die-unabhangige-index-jsx": () => import("./../src/pages/de/die-unabhangige/index.jsx" /* webpackChunkName: "component---src-pages-de-die-unabhangige-index-jsx" */),
  "component---src-pages-de-formierung-der-armee-index-jsx": () => import("./../src/pages/de/formierung-der-armee/index.jsx" /* webpackChunkName: "component---src-pages-de-formierung-der-armee-index-jsx" */),
  "component---src-pages-de-frauen-im-krieg-index-jsx": () => import("./../src/pages/de/frauen-im-krieg/index.jsx" /* webpackChunkName: "component---src-pages-de-frauen-im-krieg-index-jsx" */),
  "component---src-pages-de-index-jsx": () => import("./../src/pages/de/index.jsx" /* webpackChunkName: "component---src-pages-de-index-jsx" */),
  "component---src-pages-de-jozef-pilsudski-index-jsx": () => import("./../src/pages/de/jozef-pilsudski/index.jsx" /* webpackChunkName: "component---src-pages-de-jozef-pilsudski-index-jsx" */),
  "component---src-pages-de-krieg-der-gesamten-gesellschaft-index-jsx": () => import("./../src/pages/de/krieg-der-gesamten-gesellschaft/index.jsx" /* webpackChunkName: "component---src-pages-de-krieg-der-gesamten-gesellschaft-index-jsx" */),
  "component---src-pages-de-krieg-und-frieden-index-jsx": () => import("./../src/pages/de/krieg-und-frieden/index.jsx" /* webpackChunkName: "component---src-pages-de-krieg-und-frieden-index-jsx" */),
  "component---src-pages-de-morde-und-diebstahle-index-jsx": () => import("./../src/pages/de/morde-und-diebstahle/index.jsx" /* webpackChunkName: "component---src-pages-de-morde-und-diebstahle-index-jsx" */),
  "component---src-pages-de-polnisch-sowjetischer-krieg-index-jsx": () => import("./../src/pages/de/polnisch-sowjetischer-krieg/index.jsx" /* webpackChunkName: "component---src-pages-de-polnisch-sowjetischer-krieg-index-jsx" */),
  "component---src-pages-de-russlands-untergang-und-triumph-der-bolschewiki-index-jsx": () => import("./../src/pages/de/russlands-untergang-und-triumph-der-bolschewiki/index.jsx" /* webpackChunkName: "component---src-pages-de-russlands-untergang-und-triumph-der-bolschewiki-index-jsx" */),
  "component---src-pages-de-tuchatschewski-index-jsx": () => import("./../src/pages/de/tuchatschewski/index.jsx" /* webpackChunkName: "component---src-pages-de-tuchatschewski-index-jsx" */),
  "component---src-pages-de-vergleich-des-militarischen-potenzials-der-armeen-index-jsx": () => import("./../src/pages/de/vergleich-des-militarischen-potenzials-der-armeen/index.jsx" /* webpackChunkName: "component---src-pages-de-vergleich-des-militarischen-potenzials-der-armeen-index-jsx" */),
  "component---src-pages-de-verletztes-europa-index-jsx": () => import("./../src/pages/de/verletztes-europa/index.jsx" /* webpackChunkName: "component---src-pages-de-verletztes-europa-index-jsx" */),
  "component---src-pages-de-wladimir-lenin-index-jsx": () => import("./../src/pages/de/wladimir-lenin/index.jsx" /* webpackChunkName: "component---src-pages-de-wladimir-lenin-index-jsx" */),
  "component---src-pages-de-wort-krieg-index-jsx": () => import("./../src/pages/de/wort-krieg/index.jsx" /* webpackChunkName: "component---src-pages-de-wort-krieg-index-jsx" */),
  "component---src-pages-en-a-decisive-fight-index-jsx": () => import("./../src/pages/en/a-decisive-fight/index.jsx" /* webpackChunkName: "component---src-pages-en-a-decisive-fight-index-jsx" */),
  "component---src-pages-en-a-war-of-the-entire-society-index-jsx": () => import("./../src/pages/en/a-war-of-the-entire-society/index.jsx" /* webpackChunkName: "component---src-pages-en-a-war-of-the-entire-society-index-jsx" */),
  "component---src-pages-en-about-index-jsx": () => import("./../src/pages/en/about/index.jsx" /* webpackChunkName: "component---src-pages-en-about-index-jsx" */),
  "component---src-pages-en-aftermath-for-the-world-index-jsx": () => import("./../src/pages/en/aftermath-for-the-world/index.jsx" /* webpackChunkName: "component---src-pages-en-aftermath-for-the-world-index-jsx" */),
  "component---src-pages-en-aid-from-the-world-index-jsx": () => import("./../src/pages/en/aid-from-the-world/index.jsx" /* webpackChunkName: "component---src-pages-en-aid-from-the-world-index-jsx" */),
  "component---src-pages-en-cipher-war-index-jsx": () => import("./../src/pages/en/cipher-war/index.jsx" /* webpackChunkName: "component---src-pages-en-cipher-war-index-jsx" */),
  "component---src-pages-en-comparison-of-the-potentials-of-the-armies-index-jsx": () => import("./../src/pages/en/comparison-of-the-potentials-of-the-armies/index.jsx" /* webpackChunkName: "component---src-pages-en-comparison-of-the-potentials-of-the-armies-index-jsx" */),
  "component---src-pages-en-dabernon-index-jsx": () => import("./../src/pages/en/dabernon/index.jsx" /* webpackChunkName: "component---src-pages-en-dabernon-index-jsx" */),
  "component---src-pages-en-federation-conception-index-jsx": () => import("./../src/pages/en/federation-conception/index.jsx" /* webpackChunkName: "component---src-pages-en-federation-conception-index-jsx" */),
  "component---src-pages-en-fight-with-words-index-jsx": () => import("./../src/pages/en/fight-with-words/index.jsx" /* webpackChunkName: "component---src-pages-en-fight-with-words-index-jsx" */),
  "component---src-pages-en-forming-the-army-index-jsx": () => import("./../src/pages/en/forming-the-army/index.jsx" /* webpackChunkName: "component---src-pages-en-forming-the-army-index-jsx" */),
  "component---src-pages-en-independent-index-jsx": () => import("./../src/pages/en/independent/index.jsx" /* webpackChunkName: "component---src-pages-en-independent-index-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-jozef-pilsudski-index-jsx": () => import("./../src/pages/en/jozef-pilsudski/index.jsx" /* webpackChunkName: "component---src-pages-en-jozef-pilsudski-index-jsx" */),
  "component---src-pages-en-murders-and-plunders-index-jsx": () => import("./../src/pages/en/murders-and-plunders/index.jsx" /* webpackChunkName: "component---src-pages-en-murders-and-plunders-index-jsx" */),
  "component---src-pages-en-the-dawn-of-new-europe-index-jsx": () => import("./../src/pages/en/the-dawn-of-new-europe/index.jsx" /* webpackChunkName: "component---src-pages-en-the-dawn-of-new-europe-index-jsx" */),
  "component---src-pages-en-the-fall-of-russia-and-the-triumph-of-the-bolsheviks-index-jsx": () => import("./../src/pages/en/the-fall-of-russia-and-the-triumph-of-the-bolsheviks/index.jsx" /* webpackChunkName: "component---src-pages-en-the-fall-of-russia-and-the-triumph-of-the-bolsheviks-index-jsx" */),
  "component---src-pages-en-the-great-red-offensive-index-jsx": () => import("./../src/pages/en/the-great-red-offensive/index.jsx" /* webpackChunkName: "component---src-pages-en-the-great-red-offensive-index-jsx" */),
  "component---src-pages-en-the-great-war-changes-the-world-index-jsx": () => import("./../src/pages/en/the-great-war-changes-the-world/index.jsx" /* webpackChunkName: "component---src-pages-en-the-great-war-changes-the-world-index-jsx" */),
  "component---src-pages-en-the-moment-of-truth-index-jsx": () => import("./../src/pages/en/the-moment-of-truth/index.jsx" /* webpackChunkName: "component---src-pages-en-the-moment-of-truth-index-jsx" */),
  "component---src-pages-en-the-polish-soviet-war-index-jsx": () => import("./../src/pages/en/the-polish-soviet-war/index.jsx" /* webpackChunkName: "component---src-pages-en-the-polish-soviet-war-index-jsx" */),
  "component---src-pages-en-to-halt-the-flow-of-history-index-jsx": () => import("./../src/pages/en/to-halt-the-flow-of-history/index.jsx" /* webpackChunkName: "component---src-pages-en-to-halt-the-flow-of-history-index-jsx" */),
  "component---src-pages-en-towards-the-global-revolution-index-jsx": () => import("./../src/pages/en/towards-the-global-revolution/index.jsx" /* webpackChunkName: "component---src-pages-en-towards-the-global-revolution-index-jsx" */),
  "component---src-pages-en-tukhachevsky-index-jsx": () => import("./../src/pages/en/tukhachevsky/index.jsx" /* webpackChunkName: "component---src-pages-en-tukhachevsky-index-jsx" */),
  "component---src-pages-en-vladimir-lenin-index-jsx": () => import("./../src/pages/en/vladimir-lenin/index.jsx" /* webpackChunkName: "component---src-pages-en-vladimir-lenin-index-jsx" */),
  "component---src-pages-en-war-and-peace-index-jsx": () => import("./../src/pages/en/war-and-peace/index.jsx" /* webpackChunkName: "component---src-pages-en-war-and-peace-index-jsx" */),
  "component---src-pages-en-women-in-war-index-jsx": () => import("./../src/pages/en/women-in-war/index.jsx" /* webpackChunkName: "component---src-pages-en-women-in-war-index-jsx" */),
  "component---src-pages-en-wounded-europe-index-jsx": () => import("./../src/pages/en/wounded-europe/index.jsx" /* webpackChunkName: "component---src-pages-en-wounded-europe-index-jsx" */),
  "component---src-pages-fr-about-index-jsx": () => import("./../src/pages/fr/about/index.jsx" /* webpackChunkName: "component---src-pages-fr-about-index-jsx" */),
  "component---src-pages-fr-aide-du-monde-index-jsx": () => import("./../src/pages/fr/aide-du-monde/index.jsx" /* webpackChunkName: "component---src-pages-fr-aide-du-monde-index-jsx" */),
  "component---src-pages-fr-arreter-le-cours-de-lhistoire-index-jsx": () => import("./../src/pages/fr/arrêter-le-cours-de-l'histoire/index.jsx" /* webpackChunkName: "component---src-pages-fr-arreter-le-cours-de-lhistoire-index-jsx" */),
  "component---src-pages-fr-comparaison-des-potentiels-de-larmee-index-jsx": () => import("./../src/pages/fr/comparaison-des-potentiels-de-l'armée/index.jsx" /* webpackChunkName: "component---src-pages-fr-comparaison-des-potentiels-de-larmee-index-jsx" */),
  "component---src-pages-fr-concept-de-federation-index-jsx": () => import("./../src/pages/fr/concept-de-fédération/index.jsx" /* webpackChunkName: "component---src-pages-fr-concept-de-federation-index-jsx" */),
  "component---src-pages-fr-dabernon-index-jsx": () => import("./../src/pages/fr/dabernon/index.jsx" /* webpackChunkName: "component---src-pages-fr-dabernon-index-jsx" */),
  "component---src-pages-fr-du-local-aux-polonais-index-jsx": () => import("./../src/pages/fr/du-local-aux-polonais/index.jsx" /* webpackChunkName: "component---src-pages-fr-du-local-aux-polonais-index-jsx" */),
  "component---src-pages-fr-formation-darmee-index-jsx": () => import("./../src/pages/fr/formation-d'armée/index.jsx" /* webpackChunkName: "component---src-pages-fr-formation-darmee-index-jsx" */),
  "component---src-pages-fr-guerre-de-paroles-index-jsx": () => import("./../src/pages/fr/guerre-de-paroles/index.jsx" /* webpackChunkName: "component---src-pages-fr-guerre-de-paroles-index-jsx" */),
  "component---src-pages-fr-guerre-des-chiffres-index-jsx": () => import("./../src/pages/fr/guerre-des-chiffres/index.jsx" /* webpackChunkName: "component---src-pages-fr-guerre-des-chiffres-index-jsx" */),
  "component---src-pages-fr-guerre-et-paix-index-jsx": () => import("./../src/pages/fr/guerre-et-paix/index.jsx" /* webpackChunkName: "component---src-pages-fr-guerre-et-paix-index-jsx" */),
  "component---src-pages-fr-guerre-polono-bolchevique-index-jsx": () => import("./../src/pages/fr/guerre-polono-bolchevique/index.jsx" /* webpackChunkName: "component---src-pages-fr-guerre-polono-bolchevique-index-jsx" */),
  "component---src-pages-fr-incidence-sur-le-monde-index-jsx": () => import("./../src/pages/fr/incidence-sur-le-monde/index.jsx" /* webpackChunkName: "component---src-pages-fr-incidence-sur-le-monde-index-jsx" */),
  "component---src-pages-fr-independant-index-jsx": () => import("./../src/pages/fr/indépendant/index.jsx" /* webpackChunkName: "component---src-pages-fr-independant-index-jsx" */),
  "component---src-pages-fr-index-jsx": () => import("./../src/pages/fr/index.jsx" /* webpackChunkName: "component---src-pages-fr-index-jsx" */),
  "component---src-pages-fr-jozef-pilsudski-index-jsx": () => import("./../src/pages/fr/jozef-pilsudski/index.jsx" /* webpackChunkName: "component---src-pages-fr-jozef-pilsudski-index-jsx" */),
  "component---src-pages-fr-laube-dune-nouvelle-europe-index-jsx": () => import("./../src/pages/fr/l'aube-d'une-nouvelle-europe/index.jsx" /* webpackChunkName: "component---src-pages-fr-laube-dune-nouvelle-europe-index-jsx" */),
  "component---src-pages-fr-leurope-blessee-index-jsx": () => import("./../src/pages/fr/l'europe-blessée/index.jsx" /* webpackChunkName: "component---src-pages-fr-leurope-blessee-index-jsx" */),
  "component---src-pages-fr-la-chute-de-la-russie-et-le-triomphe-bolchevique-index-jsx": () => import("./../src/pages/fr/la-chute-de-la-russie-et-le-triomphe-bolchevique/index.jsx" /* webpackChunkName: "component---src-pages-fr-la-chute-de-la-russie-et-le-triomphe-bolchevique-index-jsx" */),
  "component---src-pages-fr-la-grande-guerre-change-le-monde-index-jsx": () => import("./../src/pages/fr/la-grande-guerre-change-le-monde/index.jsx" /* webpackChunkName: "component---src-pages-fr-la-grande-guerre-change-le-monde-index-jsx" */),
  "component---src-pages-fr-les-femmes-en-guerre-index-jsx": () => import("./../src/pages/fr/les-femmes-en-guerre/index.jsx" /* webpackChunkName: "component---src-pages-fr-les-femmes-en-guerre-index-jsx" */),
  "component---src-pages-fr-moment-de-verite-index-jsx": () => import("./../src/pages/fr/moment-de-vérité/index.jsx" /* webpackChunkName: "component---src-pages-fr-moment-de-verite-index-jsx" */),
  "component---src-pages-fr-morts-et-blesses-index-jsx": () => import("./../src/pages/fr/morts-et-blessés/index.jsx" /* webpackChunkName: "component---src-pages-fr-morts-et-blesses-index-jsx" */),
  "component---src-pages-fr-rencontre-decisive-index-jsx": () => import("./../src/pages/fr/rencontre-décisive/index.jsx" /* webpackChunkName: "component---src-pages-fr-rencontre-decisive-index-jsx" */),
  "component---src-pages-fr-toukhatchevski-index-jsx": () => import("./../src/pages/fr/toukhatchevski/index.jsx" /* webpackChunkName: "component---src-pages-fr-toukhatchevski-index-jsx" */),
  "component---src-pages-fr-une-grande-offensive-rouge-index-jsx": () => import("./../src/pages/fr/une-grande-offensive-rouge/index.jsx" /* webpackChunkName: "component---src-pages-fr-une-grande-offensive-rouge-index-jsx" */),
  "component---src-pages-fr-vers-une-revolution-mondiale-index-jsx": () => import("./../src/pages/fr/vers-une-révolution-mondiale/index.jsx" /* webpackChunkName: "component---src-pages-fr-vers-une-revolution-mondiale-index-jsx" */),
  "component---src-pages-fr-vladimir-lenine-index-jsx": () => import("./../src/pages/fr/vladimír-lénine/index.jsx" /* webpackChunkName: "component---src-pages-fr-vladimir-lenine-index-jsx" */),
  "component---src-pages-hu-a-hadero-potencialok-osszevetese-index-jsx": () => import("./../src/pages/hu/a-hadero-potencialok-osszevetese/index.jsx" /* webpackChunkName: "component---src-pages-hu-a-hadero-potencialok-osszevetese-index-jsx" */),
  "component---src-pages-hu-a-hadsereg-megalakitasa-index-jsx": () => import("./../src/pages/hu/a-hadsereg-megalakitasa/index.jsx" /* webpackChunkName: "component---src-pages-hu-a-hadsereg-megalakitasa-index-jsx" */),
  "component---src-pages-hu-a-lengyel-bolsevik-haboru-index-jsx": () => import("./../src/pages/hu/a-lengyel-bolsevik-haboru/index.jsx" /* webpackChunkName: "component---src-pages-hu-a-lengyel-bolsevik-haboru-index-jsx" */),
  "component---src-pages-hu-a-megtepett-europa-index-jsx": () => import("./../src/pages/hu/a-megtepett-europa/index.jsx" /* webpackChunkName: "component---src-pages-hu-a-megtepett-europa-index-jsx" */),
  "component---src-pages-hu-a-nagy-haboru-megvaltoztatja-a-vilagot-index-jsx": () => import("./../src/pages/hu/a-nagy-haboru-megvaltoztatja-a-vilagot/index.jsx" /* webpackChunkName: "component---src-pages-hu-a-nagy-haboru-megvaltoztatja-a-vilagot-index-jsx" */),
  "component---src-pages-hu-a-nagy-voros-offenziva-index-jsx": () => import("./../src/pages/hu/a-nagy-voros-offenziva/index.jsx" /* webpackChunkName: "component---src-pages-hu-a-nagy-voros-offenziva-index-jsx" */),
  "component---src-pages-hu-a-vilagforradalom-fele-index-jsx": () => import("./../src/pages/hu/a-vilagforradalom-fele/index.jsx" /* webpackChunkName: "component---src-pages-hu-a-vilagforradalom-fele-index-jsx" */),
  "component---src-pages-hu-about-index-jsx": () => import("./../src/pages/hu/about/index.jsx" /* webpackChunkName: "component---src-pages-hu-about-index-jsx" */),
  "component---src-pages-hu-asszonyok-a-haboruban-index-jsx": () => import("./../src/pages/hu/asszonyok-a-haboruban/index.jsx" /* webpackChunkName: "component---src-pages-hu-asszonyok-a-haboruban-index-jsx" */),
  "component---src-pages-hu-az-egesz-tarsadalom-haboruja-index-jsx": () => import("./../src/pages/hu/az-egesz-tarsadalom-haboruja/index.jsx" /* webpackChunkName: "component---src-pages-hu-az-egesz-tarsadalom-haboruja-index-jsx" */),
  "component---src-pages-hu-az-igazsag-pillanata-index-jsx": () => import("./../src/pages/hu/az-igazsag-pillanata/index.jsx" /* webpackChunkName: "component---src-pages-hu-az-igazsag-pillanata-index-jsx" */),
  "component---src-pages-hu-az-uj-europa-hajnala-index-jsx": () => import("./../src/pages/hu/az-uj-europa-hajnala/index.jsx" /* webpackChunkName: "component---src-pages-hu-az-uj-europa-hajnala-index-jsx" */),
  "component---src-pages-hu-dabernon-index-jsx": () => import("./../src/pages/hu/dabernon/index.jsx" /* webpackChunkName: "component---src-pages-hu-dabernon-index-jsx" */),
  "component---src-pages-hu-donto-osszecsapasok-index-jsx": () => import("./../src/pages/hu/donto-osszecsapasok/index.jsx" /* webpackChunkName: "component---src-pages-hu-donto-osszecsapasok-index-jsx" */),
  "component---src-pages-hu-foderacios-koncepcio-index-jsx": () => import("./../src/pages/hu/foderacios-koncepcio/index.jsx" /* webpackChunkName: "component---src-pages-hu-foderacios-koncepcio-index-jsx" */),
  "component---src-pages-hu-fuggetlen-index-jsx": () => import("./../src/pages/hu/fuggetlen/index.jsx" /* webpackChunkName: "component---src-pages-hu-fuggetlen-index-jsx" */),
  "component---src-pages-hu-gyilkossagok-es-zsakmanyolasok-index-jsx": () => import("./../src/pages/hu/gyilkossagok-es-zsakmanyolasok/index.jsx" /* webpackChunkName: "component---src-pages-hu-gyilkossagok-es-zsakmanyolasok-index-jsx" */),
  "component---src-pages-hu-haboru-es-beke-index-jsx": () => import("./../src/pages/hu/haboru-es-beke/index.jsx" /* webpackChunkName: "component---src-pages-hu-haboru-es-beke-index-jsx" */),
  "component---src-pages-hu-index-jsx": () => import("./../src/pages/hu/index.jsx" /* webpackChunkName: "component---src-pages-hu-index-jsx" */),
  "component---src-pages-hu-jozef-pilsudski-index-jsx": () => import("./../src/pages/hu/jozef-pilsudski/index.jsx" /* webpackChunkName: "component---src-pages-hu-jozef-pilsudski-index-jsx" */),
  "component---src-pages-hu-kovetkezmenyek-a-vilag-szamara-index-jsx": () => import("./../src/pages/hu/kovetkezmenyek-a-vilag-szamara/index.jsx" /* webpackChunkName: "component---src-pages-hu-kovetkezmenyek-a-vilag-szamara-index-jsx" */),
  "component---src-pages-hu-megallitani-a-tortenelem-orajat-index-jsx": () => import("./../src/pages/hu/megallitani-a-tortenelem-orajat/index.jsx" /* webpackChunkName: "component---src-pages-hu-megallitani-a-tortenelem-orajat-index-jsx" */),
  "component---src-pages-hu-oroszorszag-bukasa-es-a-bolsevik-diadal-index-jsx": () => import("./../src/pages/hu/oroszorszag-bukasa-es-a-bolsevik-diadal/index.jsx" /* webpackChunkName: "component---src-pages-hu-oroszorszag-bukasa-es-a-bolsevik-diadal-index-jsx" */),
  "component---src-pages-hu-oroszorszog-bukasa-es-a-bolsevik-diadal-index-jsx": () => import("./../src/pages/hu/oroszorszog-bukasa-es-a-bolsevik-diadal/index.jsx" /* webpackChunkName: "component---src-pages-hu-oroszorszog-bukasa-es-a-bolsevik-diadal-index-jsx" */),
  "component---src-pages-hu-rejtjelhaboru-index-jsx": () => import("./../src/pages/hu/rejtjelhaboru/index.jsx" /* webpackChunkName: "component---src-pages-hu-rejtjelhaboru-index-jsx" */),
  "component---src-pages-hu-rozne-mundury-index-jsx": () => import("./../src/pages/hu/rozne-mundury/index.jsx" /* webpackChunkName: "component---src-pages-hu-rozne-mundury-index-jsx" */),
  "component---src-pages-hu-segitseg-a-vilagbol-index-jsx": () => import("./../src/pages/hu/segitseg-a-vilagbol/index.jsx" /* webpackChunkName: "component---src-pages-hu-segitseg-a-vilagbol-index-jsx" */),
  "component---src-pages-hu-szavak-harca-index-jsx": () => import("./../src/pages/hu/szavak-harca/index.jsx" /* webpackChunkName: "component---src-pages-hu-szavak-harca-index-jsx" */),
  "component---src-pages-hu-tuhacsevszkij-index-jsx": () => import("./../src/pages/hu/tuhacsevszkij/index.jsx" /* webpackChunkName: "component---src-pages-hu-tuhacsevszkij-index-jsx" */),
  "component---src-pages-hu-vlagyimir-iljics-lenin-index-jsx": () => import("./../src/pages/hu/vlagyimir-iljics-lenin/index.jsx" /* webpackChunkName: "component---src-pages-hu-vlagyimir-iljics-lenin-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pl-chwila-prawdy-index-jsx": () => import("./../src/pages/pl/chwila-prawdy/index.jsx" /* webpackChunkName: "component---src-pages-pl-chwila-prawdy-index-jsx" */),
  "component---src-pages-pl-dabernon-index-jsx": () => import("./../src/pages/pl/dabernon/index.jsx" /* webpackChunkName: "component---src-pages-pl-dabernon-index-jsx" */),
  "component---src-pages-pl-decydujace-starcie-index-jsx": () => import("./../src/pages/pl/decydujace-starcie/index.jsx" /* webpackChunkName: "component---src-pages-pl-decydujace-starcie-index-jsx" */),
  "component---src-pages-pl-formowanie-armii-index-jsx": () => import("./../src/pages/pl/formowanie-armii/index.jsx" /* webpackChunkName: "component---src-pages-pl-formowanie-armii-index-jsx" */),
  "component---src-pages-pl-index-jsx": () => import("./../src/pages/pl/index.jsx" /* webpackChunkName: "component---src-pages-pl-index-jsx" */),
  "component---src-pages-pl-jozef-pilsudski-index-jsx": () => import("./../src/pages/pl/jozef-pilsudski/index.jsx" /* webpackChunkName: "component---src-pages-pl-jozef-pilsudski-index-jsx" */),
  "component---src-pages-pl-kobiety-na-wojnie-index-jsx": () => import("./../src/pages/pl/kobiety-na-wojnie/index.jsx" /* webpackChunkName: "component---src-pages-pl-kobiety-na-wojnie-index-jsx" */),
  "component---src-pages-pl-koncepcja-federacyjna-index-jsx": () => import("./../src/pages/pl/koncepcja-federacyjna/index.jsx" /* webpackChunkName: "component---src-pages-pl-koncepcja-federacyjna-index-jsx" */),
  "component---src-pages-pl-ku-swiatowej-rewolucji-index-jsx": () => import("./../src/pages/pl/ku-swiatowej-rewolucji/index.jsx" /* webpackChunkName: "component---src-pages-pl-ku-swiatowej-rewolucji-index-jsx" */),
  "component---src-pages-pl-mordy-i-grabieze-index-jsx": () => import("./../src/pages/pl/mordy-i-grabieze/index.jsx" /* webpackChunkName: "component---src-pages-pl-mordy-i-grabieze-index-jsx" */),
  "component---src-pages-pl-niepodlegla-index-jsx": () => import("./../src/pages/pl/niepodlegla/index.jsx" /* webpackChunkName: "component---src-pages-pl-niepodlegla-index-jsx" */),
  "component---src-pages-pl-o-projekcie-index-jsx": () => import("./../src/pages/pl/o-projekcie/index.jsx" /* webpackChunkName: "component---src-pages-pl-o-projekcie-index-jsx" */),
  "component---src-pages-pl-pokaleczona-europa-index-jsx": () => import("./../src/pages/pl/pokaleczona-europa/index.jsx" /* webpackChunkName: "component---src-pages-pl-pokaleczona-europa-index-jsx" */),
  "component---src-pages-pl-pomoc-ze-swiata-index-jsx": () => import("./../src/pages/pl/pomoc-ze-swiata/index.jsx" /* webpackChunkName: "component---src-pages-pl-pomoc-ze-swiata-index-jsx" */),
  "component---src-pages-pl-porownanie-potencjalow-armii-index-jsx": () => import("./../src/pages/pl/porownanie-potencjalow-armii/index.jsx" /* webpackChunkName: "component---src-pages-pl-porownanie-potencjalow-armii-index-jsx" */),
  "component---src-pages-pl-rozne-mundury-index-jsx": () => import("./../src/pages/pl/rozne-mundury/index.jsx" /* webpackChunkName: "component---src-pages-pl-rozne-mundury-index-jsx" */),
  "component---src-pages-pl-skutki-dla-swiata-index-jsx": () => import("./../src/pages/pl/skutki-dla-swiata/index.jsx" /* webpackChunkName: "component---src-pages-pl-skutki-dla-swiata-index-jsx" */),
  "component---src-pages-pl-swit-nowej-europy-index-jsx": () => import("./../src/pages/pl/swit-nowej-europy/index.jsx" /* webpackChunkName: "component---src-pages-pl-swit-nowej-europy-index-jsx" */),
  "component---src-pages-pl-tuchaczewski-index-jsx": () => import("./../src/pages/pl/tuchaczewski/index.jsx" /* webpackChunkName: "component---src-pages-pl-tuchaczewski-index-jsx" */),
  "component---src-pages-pl-upadek-rosji-i-triumf-bolszewikow-index-jsx": () => import("./../src/pages/pl/upadek-rosji-i-triumf-bolszewikow/index.jsx" /* webpackChunkName: "component---src-pages-pl-upadek-rosji-i-triumf-bolszewikow-index-jsx" */),
  "component---src-pages-pl-wielka-czerwona-ofensywa-index-jsx": () => import("./../src/pages/pl/wielka-czerwona-ofensywa/index.jsx" /* webpackChunkName: "component---src-pages-pl-wielka-czerwona-ofensywa-index-jsx" */),
  "component---src-pages-pl-wielka-wojna-zmienia-swiat-index-jsx": () => import("./../src/pages/pl/wielka-wojna-zmienia-swiat/index.jsx" /* webpackChunkName: "component---src-pages-pl-wielka-wojna-zmienia-swiat-index-jsx" */),
  "component---src-pages-pl-wlodzimierz-lenin-index-jsx": () => import("./../src/pages/pl/wlodzimierz-lenin/index.jsx" /* webpackChunkName: "component---src-pages-pl-wlodzimierz-lenin-index-jsx" */),
  "component---src-pages-pl-wojna-calego-spoleczenstwa-index-jsx": () => import("./../src/pages/pl/wojna-calego-spoleczenstwa/index.jsx" /* webpackChunkName: "component---src-pages-pl-wojna-calego-spoleczenstwa-index-jsx" */),
  "component---src-pages-pl-wojna-i-pokoj-index-jsx": () => import("./../src/pages/pl/wojna-i-pokoj/index.jsx" /* webpackChunkName: "component---src-pages-pl-wojna-i-pokoj-index-jsx" */),
  "component---src-pages-pl-wojna-na-slowa-index-jsx": () => import("./../src/pages/pl/wojna-na-slowa/index.jsx" /* webpackChunkName: "component---src-pages-pl-wojna-na-slowa-index-jsx" */),
  "component---src-pages-pl-wojna-polsko-bolszewicka-index-jsx": () => import("./../src/pages/pl/wojna-polsko-bolszewicka/index.jsx" /* webpackChunkName: "component---src-pages-pl-wojna-polsko-bolszewicka-index-jsx" */),
  "component---src-pages-pl-wojna-szyfrow-index-jsx": () => import("./../src/pages/pl/wojna-szyfrow/index.jsx" /* webpackChunkName: "component---src-pages-pl-wojna-szyfrow-index-jsx" */),
  "component---src-pages-pl-zatrzymany-zegar-historii-index-jsx": () => import("./../src/pages/pl/zatrzymany-zegar-historii/index.jsx" /* webpackChunkName: "component---src-pages-pl-zatrzymany-zegar-historii-index-jsx" */),
  "component---src-pages-ru-about-index-jsx": () => import("./../src/pages/ru/about/index.jsx" /* webpackChunkName: "component---src-pages-ru-about-index-jsx" */),
  "component---src-pages-ru-index-jsx": () => import("./../src/pages/ru/index.jsx" /* webpackChunkName: "component---src-pages-ru-index-jsx" */),
  "component---src-pages-ru-большое-красное-наступление-index-jsx": () => import("./../src/pages/ru/Большое-красное-наступление/index.jsx" /* webpackChunkName: "component---src-pages-ru-большое-красное-наступление-index-jsx" */),
  "component---src-pages-ru-великая-война-меняет-мир-index-jsx": () => import("./../src/pages/ru/Великая-война-меняет-мир/index.jsx" /* webpackChunkName: "component---src-pages-ru-великая-война-меняет-мир-index-jsx" */),
  "component---src-pages-ru-владимир-ленин-index-jsx": () => import("./../src/pages/ru/Владимир-Ленин/index.jsx" /* webpackChunkName: "component---src-pages-ru-владимир-ленин-index-jsx" */),
  "component---src-pages-ru-война-всего-общества-index-jsx": () => import("./../src/pages/ru/Война-всего-общества/index.jsx" /* webpackChunkName: "component---src-pages-ru-война-всего-общества-index-jsx" */),
  "component---src-pages-ru-война-и-мир-index-jsx": () => import("./../src/pages/ru/Война-и-мир/index.jsx" /* webpackChunkName: "component---src-pages-ru-война-и-мир-index-jsx" */),
  "component---src-pages-ru-война-шифров-index-jsx": () => import("./../src/pages/ru/Война-шифров/index.jsx" /* webpackChunkName: "component---src-pages-ru-война-шифров-index-jsx" */),
  "component---src-pages-ru-дабернон-index-jsx": () => import("./../src/pages/ru/ДАбернон/index.jsx" /* webpackChunkName: "component---src-pages-ru-дабернон-index-jsx" */),
  "component---src-pages-ru-женщины-на-войне-index-jsx": () => import("./../src/pages/ru/Женщины-на-войне/index.jsx" /* webpackChunkName: "component---src-pages-ru-женщины-на-войне-index-jsx" */),
  "component---src-pages-ru-израненная-европа-index-jsx": () => import("./../src/pages/ru/Израненная-Европа/index.jsx" /* webpackChunkName: "component---src-pages-ru-израненная-европа-index-jsx" */),
  "component---src-pages-ru-концепция-федерации-index-jsx": () => import("./../src/pages/ru/Концепция-федерации/index.jsx" /* webpackChunkName: "component---src-pages-ru-концепция-федерации-index-jsx" */),
  "component---src-pages-ru-момент-истины-index-jsx": () => import("./../src/pages/ru/Момент-истины/index.jsx" /* webpackChunkName: "component---src-pages-ru-момент-истины-index-jsx" */),
  "component---src-pages-ru-на-пути-к-мировой-революции-index-jsx": () => import("./../src/pages/ru/На-пути-к-мировой-революции/index.jsx" /* webpackChunkName: "component---src-pages-ru-на-пути-к-мировой-революции-index-jsx" */),
  "component---src-pages-ru-независимая-index-jsx": () => import("./../src/pages/ru/Независимая/index.jsx" /* webpackChunkName: "component---src-pages-ru-независимая-index-jsx" */),
  "component---src-pages-ru-остановить-часы-истории-index-jsx": () => import("./../src/pages/ru/Остановить-часы-истории/index.jsx" /* webpackChunkName: "component---src-pages-ru-остановить-часы-истории-index-jsx" */),
  "component---src-pages-ru-падение-россии-и-триумф-большевиков-index-jsx": () => import("./../src/pages/ru/Падение-России-и-триумф-большевиков/index.jsx" /* webpackChunkName: "component---src-pages-ru-падение-россии-и-триумф-большевиков-index-jsx" */),
  "component---src-pages-ru-польско-большевистская-война-index-jsx": () => import("./../src/pages/ru/Польско-большевистская-война/index.jsx" /* webpackChunkName: "component---src-pages-ru-польско-большевистская-война-index-jsx" */),
  "component---src-pages-ru-помощь-от-мира-index-jsx": () => import("./../src/pages/ru/Помощь-от-мира/index.jsx" /* webpackChunkName: "component---src-pages-ru-помощь-от-мира-index-jsx" */),
  "component---src-pages-ru-последствия-для-мира-index-jsx": () => import("./../src/pages/ru/Последствия-для-мира/index.jsx" /* webpackChunkName: "component---src-pages-ru-последствия-для-мира-index-jsx" */),
  "component---src-pages-ru-рассвет-новой-европы-index-jsx": () => import("./../src/pages/ru/Рассвет-новой-Европы/index.jsx" /* webpackChunkName: "component---src-pages-ru-рассвет-новой-европы-index-jsx" */),
  "component---src-pages-ru-решающее-сражение-index-jsx": () => import("./../src/pages/ru/Решающее-сражение/index.jsx" /* webpackChunkName: "component---src-pages-ru-решающее-сражение-index-jsx" */),
  "component---src-pages-ru-словесная-перепалка-index-jsx": () => import("./../src/pages/ru/Словесная-перепалка/index.jsx" /* webpackChunkName: "component---src-pages-ru-словесная-перепалка-index-jsx" */),
  "component---src-pages-ru-сравнение-потенциалов-армий-index-jsx": () => import("./../src/pages/ru/Сравнение-потенциалов-армий/index.jsx" /* webpackChunkName: "component---src-pages-ru-сравнение-потенциалов-армий-index-jsx" */),
  "component---src-pages-ru-тухачевский-index-jsx": () => import("./../src/pages/ru/Тухачевский/index.jsx" /* webpackChunkName: "component---src-pages-ru-тухачевский-index-jsx" */),
  "component---src-pages-ru-убийства-и-грабежи-index-jsx": () => import("./../src/pages/ru/Убийства-и-грабежи/index.jsx" /* webpackChunkName: "component---src-pages-ru-убийства-и-грабежи-index-jsx" */),
  "component---src-pages-ru-формирование-армии-index-jsx": () => import("./../src/pages/ru/Формирование-армии/index.jsx" /* webpackChunkName: "component---src-pages-ru-формирование-армии-index-jsx" */),
  "component---src-pages-ru-юзеф-пилсудский-index-jsx": () => import("./../src/pages/ru/Юзеф-Пилсудский/index.jsx" /* webpackChunkName: "component---src-pages-ru-юзеф-пилсудский-index-jsx" */),
  "component---src-pages-ua-about-index-jsx": () => import("./../src/pages/ua/about/index.jsx" /* webpackChunkName: "component---src-pages-ua-about-index-jsx" */),
  "component---src-pages-ua-index-jsx": () => import("./../src/pages/ua/index.jsx" /* webpackChunkName: "component---src-pages-ua-index-jsx" */),
  "component---src-pages-ua-rozne-mundury-index-jsx": () => import("./../src/pages/ua/rozne-mundury/index.jsx" /* webpackChunkName: "component---src-pages-ua-rozne-mundury-index-jsx" */),
  "component---src-pages-ua-вбивства-і-грабежі-index-jsx": () => import("./../src/pages/ua/вбивства-і-грабежі/index.jsx" /* webpackChunkName: "component---src-pages-ua-вбивства-і-грабежі-index-jsx" */),
  "component---src-pages-ua-велика-війна-змінює-світ-index-jsx": () => import("./../src/pages/ua/велика-війна-змінює-світ/index.jsx" /* webpackChunkName: "component---src-pages-ua-велика-війна-змінює-світ-index-jsx" */),
  "component---src-pages-ua-великий-червоний-наступ-index-jsx": () => import("./../src/pages/ua/великий-червоний-наступ/index.jsx" /* webpackChunkName: "component---src-pages-ua-великий-червоний-наступ-index-jsx" */),
  "component---src-pages-ua-вирішальна-битва-index-jsx": () => import("./../src/pages/ua/вирішальна-битва/index.jsx" /* webpackChunkName: "component---src-pages-ua-вирішальна-битва-index-jsx" */),
  "component---src-pages-ua-війна-і-мир-index-jsx": () => import("./../src/pages/ua/війна-і-мир/index.jsx" /* webpackChunkName: "component---src-pages-ua-війна-і-мир-index-jsx" */),
  "component---src-pages-ua-війна-шифрів-index-jsx": () => import("./../src/pages/ua/війна-шифрів/index.jsx" /* webpackChunkName: "component---src-pages-ua-війна-шифрів-index-jsx" */),
  "component---src-pages-ua-володимир-ленін-index-jsx": () => import("./../src/pages/ua/володимир-ленін/index.jsx" /* webpackChunkName: "component---src-pages-ua-володимир-ленін-index-jsx" */),
  "component---src-pages-ua-дабернон-index-jsx": () => import("./../src/pages/ua/дАбернон/index.jsx" /* webpackChunkName: "component---src-pages-ua-дабернон-index-jsx" */),
  "component---src-pages-ua-допомога-від-світу-index-jsx": () => import("./../src/pages/ua/допомога-від-світу/index.jsx" /* webpackChunkName: "component---src-pages-ua-допомога-від-світу-index-jsx" */),
  "component---src-pages-ua-жінки-на-війні-index-jsx": () => import("./../src/pages/ua/жінки-на-війні/index.jsx" /* webpackChunkName: "component---src-pages-ua-жінки-на-війні-index-jsx" */),
  "component---src-pages-ua-з-місцевих-в-поляки-index-jsx": () => import("./../src/pages/ua/з-місцевих-в-поляки/index.jsx" /* webpackChunkName: "component---src-pages-ua-з-місцевих-в-поляки-index-jsx" */),
  "component---src-pages-ua-зранена-європа-index-jsx": () => import("./../src/pages/ua/зранена-європа/index.jsx" /* webpackChunkName: "component---src-pages-ua-зранена-європа-index-jsx" */),
  "component---src-pages-ua-зупинити-годинник-історії-index-jsx": () => import("./../src/pages/ua/зупинити-годинник-історії/index.jsx" /* webpackChunkName: "component---src-pages-ua-зупинити-годинник-історії-index-jsx" */),
  "component---src-pages-ua-концепція-федерації-index-jsx": () => import("./../src/pages/ua/концепція-федерації/index.jsx" /* webpackChunkName: "component---src-pages-ua-концепція-федерації-index-jsx" */),
  "component---src-pages-ua-момент-істини-index-jsx": () => import("./../src/pages/ua/момент-істини/index.jsx" /* webpackChunkName: "component---src-pages-ua-момент-істини-index-jsx" */),
  "component---src-pages-ua-на-шляху-до-світової-революції-index-jsx": () => import("./../src/pages/ua/на-шляху-до-світової-революції/index.jsx" /* webpackChunkName: "component---src-pages-ua-на-шляху-до-світової-революції-index-jsx" */),
  "component---src-pages-ua-наслідки-для-світу-index-jsx": () => import("./../src/pages/ua/наслідки-для-світу/index.jsx" /* webpackChunkName: "component---src-pages-ua-наслідки-для-світу-index-jsx" */),
  "component---src-pages-ua-незалежна-index-jsx": () => import("./../src/pages/ua/незалежна/index.jsx" /* webpackChunkName: "component---src-pages-ua-незалежна-index-jsx" */),
  "component---src-pages-ua-падіння-росії-і-тріумф-більшовиків-index-jsx": () => import("./../src/pages/ua/падіння-росії-і-тріумф-більшовиків/index.jsx" /* webpackChunkName: "component---src-pages-ua-падіння-росії-і-тріумф-більшовиків-index-jsx" */),
  "component---src-pages-ua-польсько-більшовицька-війна-index-jsx": () => import("./../src/pages/ua/польсько-більшовицька-війна/index.jsx" /* webpackChunkName: "component---src-pages-ua-польсько-більшовицька-війна-index-jsx" */),
  "component---src-pages-ua-порівняння-потенціалів-армій-index-jsx": () => import("./../src/pages/ua/порівняння-потенціалів-армій/index.jsx" /* webpackChunkName: "component---src-pages-ua-порівняння-потенціалів-армій-index-jsx" */),
  "component---src-pages-ua-світанок-нової-європи-index-jsx": () => import("./../src/pages/ua/світанок-нової-європи/index.jsx" /* webpackChunkName: "component---src-pages-ua-світанок-нової-європи-index-jsx" */),
  "component---src-pages-ua-словесна-перепалка-index-jsx": () => import("./../src/pages/ua/словесна-перепалка/index.jsx" /* webpackChunkName: "component---src-pages-ua-словесна-перепалка-index-jsx" */),
  "component---src-pages-ua-тухачевський-index-jsx": () => import("./../src/pages/ua/тухачевський/index.jsx" /* webpackChunkName: "component---src-pages-ua-тухачевський-index-jsx" */),
  "component---src-pages-ua-формування-армії-index-jsx": () => import("./../src/pages/ua/формування-армії/index.jsx" /* webpackChunkName: "component---src-pages-ua-формування-армії-index-jsx" */),
  "component---src-pages-ua-юзеф-пілсудський-index-jsx": () => import("./../src/pages/ua/юзеф-пілсудський/index.jsx" /* webpackChunkName: "component---src-pages-ua-юзеф-пілсудський-index-jsx" */)
}

