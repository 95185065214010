import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "FR",
  "name": "Finlande",
  "title": "modal-2-3-1-4"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Guerre civile finlandaise</h2>
    <p tabIndex="0">La Finlande, qui faisait jusqu'à présent partie de l'empire russe, bénéficiant du soutien de l'Allemagne et des troubles internes en Russie, a déclaré son indépendance en décembre 1917. Les bolcheviques reconnaissent l'indépendance de l'Etat, mais ils n'ont pas l'intention de renoncer à "exporter la révolution" en Finlande. Les <strong>"gardes rouges"</strong> d'Helsinki, soutenues par des soldats révoltés d'anciennes garnisons russes, proclament la République soviétique. Les bolcheviques russes lui ont apporté une "aide fraternelle" en entreprenant une intervention armée en Finlande. Dans le nord du pays, <strong>Karl Mannerheim</strong>, avec l'aide de volontaires suédois et de l'Allemagne, a créé un gouvernement national et une armée qui ont reconduit les "rouge" vers la Russie. La Finlande est restée un État libre et indépendant après la Première Guerre mondiale.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      