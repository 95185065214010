/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getRoutesQuery, getLangFromPath } from 'src/utils';
import { AnimatePresence } from 'framer-motion';
import {
  MenuContainer, MenuLink, LinkBackground, Mask,
} from './styles';

const disableScroll = () => {
  document.body.style.overflow = 'hidden';
};

const enableScroll = () => {
  document.body.style.overflow = 'auto';
};

const MenuDropdown = ({ isVisible, setVisible, path }) => {
  const selectedLocale = getLangFromPath();

  useEffect(() => {
    setVisible(false);
  }, [path, setVisible]);

  useEffect(() => {
    isVisible ? disableScroll() : enableScroll();
  }, [isVisible]);

  const query = getRoutesQuery();
  const { routes } = query[selectedLocale].exports;

  return (
    <>
      <AnimatePresence>
        {isVisible && (
          <MenuContainer
            initial={{ transform: 'translateY(-100%)' }}
            animate={{
              transform: 'translateY(0%)',
              transition: { duration: 0.5 },
            }}
            exit={{
              transform: 'translateY(-100%)',
              transition: { duration: 0.5, delay: 0.7 },
            }}
          >
            {routes.map(
              ({
                name, isMainPage, route, mobileBackground,
              }) => isMainPage && (
                <MenuLink
                  key={route}
                  to={route}
                  image={mobileBackground && mobileBackground.publicURL}
                  isActive={path === route}
                >
                  <LinkBackground
                    isActive={path === route}
                    image={mobileBackground && mobileBackground.publicURL}
                  >
                    {path === route && <Mask />}
                  </LinkBackground>
                  <span>{name}</span>
                </MenuLink>
              ),
            )}
          </MenuContainer>
        )}
      </AnimatePresence>
    </>
  );
};

export default MenuDropdown;
