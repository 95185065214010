/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTransitionStore } from 'gatsby-plugin-transitions';
import { getRoutesQuery } from 'src/utils';
import BazierEasing from 'bezier-easing';
import { getLangFromPath } from 'src/utils/index.js';
import { introPages } from '../../../../globals';

export const tranistionAnimations = (direction) => {
  switch (direction) {
    case 'nextPage':
      return {
        enter: {
          opacity: 1,
          transform: 'translate3d(0,0.1vh,0)',
          config: {
            duration: 1000,
          },
          onStart: () => {
            const wrapper = document.querySelectorAll('.view-container')[1];
            if (wrapper) wrapper.classList.add('onTop');
          },
          onRest: () => {
            const wrapper = document.querySelectorAll('.view-container')[1];
            if (wrapper) wrapper.classList.remove('onTop');
          },
        },
        usual: {
          opacity: 1,
          transform: 'translate3d(0vh,0vh,0)',
        },
        leave: {
          opacity: 1,
          transform: 'translate3d(0vh,-100vh,0)',
          config: {
            mass: 1,
            tension: 220,
            clamp: true,
            duration: 1000,
            easing: (t) => BazierEasing(0.82, 0.0, 0.195, 1.0)(t),
          },
        },
      };

    case 'prevPage':
      return {
        enter: {
          opacity: 1,
          transform: 'translate3d(0,-100vh,0)',
          config: {
            mass: 1,
            tension: 220,
            clamp: true,
            duration: 1000,
            easing: (t) => BazierEasing(0.82, 0.0, 0.195, 1.0)(t),
          },
          onStart: () => {
            const wrapper = document.querySelectorAll('.view-container')[0];
            if (wrapper) wrapper.classList.add('onTop');
          },
          onRest: () => {
            const wrapper = document.querySelectorAll('.view-container')[0];
            if (wrapper) wrapper.classList.remove('onTop');
          },
        },
        usual: {
          opacity: 1,
          transform: 'translate3d(0vh,0vh,0)',
        },
        leave: {
          opacity: 1,
          transform: 'translate3d(0vh,-0.1vh,0)',
          config: {
            duration: 1000,
            clamp: true,
          },
        },
      };

    default:
      return {
        enter: {
          opacity: 1,
          transform: 'translate3d(0,0.1vh,0)',
          config: {
            duration: 1000,
          },
        },
        usual: {
          opacity: 1,
          transform: 'translate3d(0vh,0vh,0)',
        },
        leave: {
          opacity: 1,
          transform: 'translate3d(0vh,-100vh,0)',
          config: {
            mass: 1,
            tension: 220,
            clamp: true,
            duration: 1000,
            easing: (t) => BazierEasing(0.82, 0.0, 0.195, 1.0)(t),
          },
        },
      };
  }
};

const ScrollTransition = ({ path }) => {
  const [, dispatch] = useTransitionStore();
  const [selectedLocale, setSelectedLocale] = useState(null);
  const [isReadyTransition, setReadyTransition] = useState(true);
  const isModalClose = useSelector((state) => !state.showModal);
  const splitPath = getLangFromPath();

  useEffect(() => {
    if (isReadyTransition === false) {
      setTimeout(() => {
        setReadyTransition(true);
      }, 2500);
    }
  }, [isReadyTransition]);

  const query = getRoutesQuery();
  const setCurrentLang = () => {
    splitPath && setSelectedLocale(splitPath);
  };

  const routes = query[selectedLocale] ? query[selectedLocale].exports.routes : [];

  const getCurrentRoutesIndex = () => routes.findIndex(({ route }) => encodeURI(route) === path);

  const nextPagePath = () => {
    const currentIndex = getCurrentRoutesIndex();
    if (currentIndex < routes.length - 1) {
      return routes[currentIndex + 1].route;
    }
    return path;
  };
  const prevPagePath = () => {
    const currentIndex = getCurrentRoutesIndex();
    if (currentIndex > 0) {
      return routes[currentIndex - 1].route;
    }
    return path;
  };

  useEffect(() => setCurrentLang(), [path]);


  useEffect(() => {
    const changePageOnArrowClick = (event) => {
      if (event.key === 'ArrowUp') {
        if (isReadyTransition && isModalClose) {
          dispatch({
            type: 'NAVIGATE',
            to: prevPagePath(),
            ...tranistionAnimations('prevPage'),
          });
          setReadyTransition(false);
        }
      } else if (event.key === 'ArrowDown') {
        if (isReadyTransition && isModalClose) {
          dispatch({
            type: 'NAVIGATE',
            to: nextPagePath(),
            ...tranistionAnimations('nextPage'),
          });
          setReadyTransition(false);
        }
      }
    };
    window.addEventListener('keydown', changePageOnArrowClick);
    return () => {
      window.removeEventListener('keydown', changePageOnArrowClick);
    };
  }, [path, isReadyTransition, selectedLocale, isModalClose]);

  useEffect(() => {
    const onScroll = (e) => {
      if (e.deltaY > 1 && isReadyTransition && isModalClose && !introPages.includes(path)) {
        dispatch({
          type: 'NAVIGATE',
          to: nextPagePath(),
          ...tranistionAnimations('nextPage'),
        });
        setReadyTransition(false);
      }
      if (e.deltaY < -1 && isReadyTransition && isModalClose && !introPages.includes(path)) {
        dispatch({
          type: 'NAVIGATE',
          to: prevPagePath(),
          ...tranistionAnimations('prevPage'),
        });
        setReadyTransition(false);
      }
    };
    window.addEventListener('wheel', onScroll);
    return () => window.removeEventListener('wheel', onScroll);
  }, [path, isReadyTransition, selectedLocale, isModalClose]);

  return null;
};

export default ScrollTransition;
