import styled from 'styled-components';
import Img from 'gatsby-image';
import { motion } from 'framer-motion';
import { styles } from '../../../../../globals';

export const ModalCurtain = styled(motion.div)`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const MobileModalWrapper = styled(motion.div)`
    width: 100%;
    height: 100%;
    max-width: auto;
    max-height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: relative;
`;
export const StyledModalContainer = styled.div`
    flex-shrink: 1;
    height: ${(props) => (props.image ? '67%' : '100%')};
`;
export const StyledModal = styled.div`
    height: 100%;
    width: 100%;
    /* padding: 1rem 2rem; */
    overflow-Y: auto;
    background-color: ${styles.colors.beigeBg};
`;

export const ModalText = styled(motion.div)`
    width: 100vw;
    p {
        ${{ ...styles.fonts.modalText }}
        color: ${styles.colors.black};
        padding: 1rem;
    }
    h2,h3 {
        text-align: center;
        ${{ ...styles.fonts.mobileModalHeader }}
        color: ${styles.colors.black};
        padding: 3rem 1rem 0 1rem;
    }
    & > p{
        padding-top: 3rem;
    }
`;

export const StyledImage = styled(Img)`
    width: ${(props) => (props.body ? '100% !important' : '100vw')};
    height: ${(props) => (props.body ? '33% !important' : '100vh')};
    img {
        object-position: top center !important;
    }
`;

export const CloseIcon = styled(motion.i)`
    width: 2.31rem;
    height: 2.31rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: ${styles.colors.paleRed};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &::before, &::after {
        transform-origin: 50% 50%;
        position: absolute;
    }
    &::before, &::after {
        content: '';
        width: 4%;
        height: 4%;
        background-color: ${styles.colors.beigeBg};
        border-radius: 2px;
    }
    &::before {
        animation: expandL 0.7s 0.9s forwards cubic-bezier(0.820, 0.000, 0.195, 1.000);
    }
    &::after {
        animation: expandR 0.7s 0.9s forwards cubic-bezier(0.820, 0.000, 0.195, 1.000);
    }
    @keyframes expandL {
        0% {
            height: 4%;
        }
        50% {
            transform: rotate(0deg);
            height: 50%;
        }
        100% {
            transform: rotate(45deg);
            height: 50%;

        }
    }
    @keyframes expandR {
        0% {
            height: 4%;
        }
        50% {
            transform: rotate(0deg);
            height: 50%;
        }
        100% {
            transform: rotate(-45deg);
            height: 50%;

        }
    }
`;

export const TestBtn = styled.button`
    background: red;
`;
