/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { ProgressBarContainer, ProgressIndicator } from './styles'

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  const getDocHeight = () => Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight,
  );

  const calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset; // how much the user has scrolled by
    const winHeight = window.innerHeight;
    const docHeight = getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    const scrollPostion = Math.floor((scrollTop / totalDocScrollLength) * 100);

    setProgress(scrollPostion >= 0 ? scrollPostion : 0);
  };

  const listenToScrollEvent = () => {
    document.addEventListener('scroll', () => {
      requestAnimationFrame(() => {
        calculateScrollDistance();
      });
    });
  };

  useEffect(() => {
    listenToScrollEvent();
  }, []);

  return (
    <ProgressBarContainer>
      <ProgressIndicator width={progress} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
