import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "DE",
  "name": "UNGARN",
  "title": "modal-2-3-1-3"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Die Revolution in Ungarn</h2>
    <p tabIndex="0">Die Niederlage im I. Weltkrieg hat die Dynastie der Habsburger komprommitiert und den Zerfall von Österreich-Ungarn verursacht. Im Herbst 1918 fand in Ungarn und in Österreich eine Revolution statt, die den Kaiser und den König absetzte.  Die aus Moskau nach Ungarn georderten Kommunisten haben schon im Februar 1919 versucht, die Macht in Ungarn an sich zu reißen, aber der Putschversuch misslang. Erst am 21. März 1919 haben sie angesichts der Komprommitierung des Präsidenten Mihály Károlyi und der Regierung von Dénes Berinkey die Herrschaft übernommen. An diesem Tag haben sie gemeinsam mit den Sozialdemokraten die <strong>Ungarische Räterepublik</strong> unter der Führung von <strong>Béla Kun</strong> ausgerufen. Im Land herrschte „roter Terror“, ähnlich dem, den die Bolschewiki in Russland einführten. Eine der Maßnahmen der Kommunisten war die Bildung der die Kontrrevolution bekämpfenden Kampftruppen - der sog. <strong>„Lenin-Jungs“</strong>. Den ungarischen Kommunisten gelang es, kurzfristig die <strong>Slowakische Räterepublik</strong> zu erschaffen. Anfang August 1919 hat die mit der Entente zusammenarbeitende rumänische Armee, die den Großteil des Gebietes, samt Budapest besetzte, der Ungarischen Räterepublik ein Ende gesetzt. Nach dem Fall der kommunistischen Regierung hat Admiral Miklós Horthy und die rechtsextreme Partei, die die Vertreter des bisherigen Regimes repräsentierte, die Regierung im Land übernommen.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      