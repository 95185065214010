import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "language": "UA",
  "name": "Німеччина",
  "title": "modal-2-3-1-2"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 tabIndex="0">Революція в&nbsp;Німеччині</h2>
    <p tabIndex="0">У листопаді 1918 року внутрішня обстановка в&nbsp;Германській імперії була гірша, ніж на фронті Першої світової війни. Країна втомилася від конфлікту, який тривав чотири роки. Передусім, обтяжливими були економічні проблеми, викликані морською блокадою країни. Німці роками не бачили кави і&nbsp;шоколаду, картопля і&nbsp;хліб без тирси і&nbsp;інших добавок були розкішшю. Широко використовувалися <strong>замінники (ерзаци)</strong> найнеобхідніших продуктів - їх було розроблено більше 11 тис.
    </p>
    <p tabIndex="0">
ЕРЗАЦИ:
    </p>
    <p tabIndex="0">
Масло -> жир з жовтим барвником
    </p>
    <p tabIndex="0">
Кава -> настій на основі жолудевого борошна
    </p>
    <p tabIndex="0">
М'ясо/ковбасні вироби -> картопляна ковбаса
    </p>
    <p tabIndex="0">
Патова ситуація на&nbsp;Західному фронті і&nbsp;економічний крах викликали <strong>повстання серед солдатів і&nbsp;невдоволення мирних жителів</strong>. На початку листопада 1918 року повстали моряки і&nbsp;захопили ключові портові і&nbsp;промислові міста, почали формувати робочі і&nbsp;солдатські ради. Повстання не було підтримане німецькими соціал-демократами від партії СДПГ, замість цього на сторону моряків стали марксисти-максималісти з&nbsp;<strong>«Союзу Спартака»</strong>, які раніше агітували за перетворення Німеччини на країну з&nbsp;системою, подібною до тієї, яка існувала в&nbsp;Радянській Росії.
    </p>
    <p tabIndex="0">
Революція охопила майже усю країну. Імператор Вільгельм II був <strong>вимушений відректися від престолу</strong> і&nbsp;втекти в&nbsp;нейтральну Голландію. 9&nbsp;листопада лідер «Союзу Спартака» <strong>Карл Лібкнехт</strong> проголосив <strong>соціалістичну республіку</strong>. Військові, які досі фактично правили в&nbsp;країні, побачили можливість подавити революцію в&nbsp;союзі з&nbsp;соціал-демократами. Завдяки співпраці армії і&nbsp;СДПГ, комуністи були позбавлені перемоги.
    </p>
    <p tabIndex="0">
Кульмінаційною точкою революції було так зване <strong>«Повстання спартакістів»</strong> - озброєне повстання войовничих угрупувань Комуністичної партії Німеччини проти правління соціал-демократів. Влада опанувала ситуацію, а&nbsp;лідери повстання були вбиті. Серед них були Карл Лібкнехт і&nbsp;<strong>Роза Люксембург</strong>.
    </p>
    <p tabIndex="0">
Революційні сили проіснували найдовше у&nbsp;Баварії, де&nbsp;вони утворили <strong>Баварську радянську республіку</strong>. Режим рухнув після втручання армії на&nbsp;початку травня 1919 року.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      