import styled, { keyframes, css } from 'styled-components';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { sidebarWidth } from '../../../../../globals';

export const darkTheme = {
  defaultColor: '#a6a59e',
  activeColor: '#df3242',
  mainColor: 'rgba(25,22,22,0.15)',
  mainColorHover: 'rgba(25,22,22,1)',
  linkColor: 'rgba(255,255,255,0.4)',
  linkColorActive: 'rgba(255,255,255,1)',
};

export const lightTheme = {
  defaultColor: '#5d5955',
  activeColor: '#df3242',
  mainColor: 'rgba(247,246,234,0.15)',
  mainColorHover: 'rgba(247,246,234,1)',
  linkColor: 'rgba(31,31,31,0.4)',
  linkColorActive: 'rgba(31,31,31,1)',
};

const dotPulse = keyframes`
  0% {
    transform: scale(1) translateX(0.25px);
  }
  50% {
    transform: scale(2.2) translateX(0.25px);
  }
  100% {
    transform: scale(1.8) translateX(0.25px);
  }
`;

const dotPulseAnimation = ({ active }) => {
  if (active) {
    return css`
      ${dotPulse} 1s ease-out 500ms forwards
    `;
  }
  return null;
};

export const SideBarContainer = styled(motion.nav)`
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  width: ${sidebarWidth}%;
  height: 100vh;
  background-color: ${(props) => props.theme.mainColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: "Red Hat Display", sans-serif;
  transition: background-color 1s ease-in;
`;

// timeline

export const TimeLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${(props) => (props.theme.defaultColor === '#a6a59e' ? 'rgba(242,239,228,0.12)' : 'rgba(31,31,31,0.15)')} ;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
`;

export const TimeLineNav = styled.ul`
  position: absolute;
  margin: 0;
  padding: 0;
  left: 0;
  top: 20%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.7rem;
  font-weight: bold;
  color: white;
  list-style: none;
`;

export const TimeLineProgressBar = styled.li`
  position: absolute;
  top: calc(1.5rem - 13px);
  left: 50%;
  background-color: ${(props) => props.theme.activeColor};
  width: 1px;
  height: ${(props) => (props.index ? `${1.5 * props.index}rem` : '0')};
  transition: height 1s;
`;

export const TimeLineElement = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
`;

export const TimeLineElementLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-decoration: none;
  color: ${({ theme, visited }) => (visited === 'true' ? theme.linkColorActive : theme.linkColor)};

  &.active {
    color: ${(props) => props.theme.linkColorActive};
  }
  transition: color 0.5s 0.5s;
  pointer-events: none;
`;

export const TitleHover = styled.div`
  background: ${(props) => props.theme.mainColorHover};
  border: 1px solid rgba(242,239,228,0.12);
  color: ${(props) => props.theme.linkColorActive};
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
  font-size: 0.68rem;
  padding: 0.5rem;
  white-space: nowrap;
  /* min-width: 6rem; */
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 1;
  pointer-events: none;

  &::before{
    position: absolute;
    content: '';
    background: ${(props) => props.theme.mainColorHover};
    direction: block;
    height: 10px;
    width: 10px;
    transform: rotate(-45deg) translateY(-50%);
    top: 40%;
    left: -2px;
    border-left: 1px solid rgba(242,239,228,0.12);
    border-top: 1px solid rgba(242,239,228,0.12);
    z-index: 2;
  }
`;

export const TimeLineElementSubLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-decoration: none;
  color: ${(props) => props.theme.linkColor};

  &.active {
    color: ${(props) => props.theme.linkColorActive};
  }
  transition: color 0.5s 0.5s;
  position: relative;
  &:hover ${TitleHover}{
    opacity: 1;
  }
  &::after{
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
    pointer-events: all;
  }
`;


export const TimeLineElementDot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  
`;

export const TimeLineLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 13rem;
  transform: translateX(0.5px);
  a {
    display: flex;
    justify-content: space-between;
    width: 9rem;
    margin-left: -42%;
  }
`;

export const TimeLineIndicatorCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 48%;
  background-color: ${(props) => (props.visited === 'true' ? props.theme.activeColor : props.theme.mainColor)};
  border: ${(props) => (props.visited === 'true' ? '' : `1px solid ${props.theme.defaultColor}`)};
  transition: all 500ms;
  animation: ${dotPulseAnimation};
  transform: translateX(0.5px);
  pointer-events: all;
  @media (max-width: 1600px){
    width: 4px;
    height: 4px;
  }
`;

export const TimeLineIndicatorCaro = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  transform: rotate(45deg) translateX(0.5px);
  background-color: ${(props) => (props.visited === 'true' ? props.theme.activeColor : props.theme.defaultColor)};
  transition: background-color 1s 500ms;
  pointer-events: all;
  @media (max-width: 1600px){
    width: 8px;
    height: 8px;
  }
`;

export const TimeLineTitle = styled.div`
  width: 70%;
  text-align: right;
  font-size: 0.75rem;
  pointer-events: all;
`;

export const TimeLineNumber = styled.div`
  width: 10%;
  font-size: 0.8rem;
  pointer-events: none;
`;
