import styled from 'styled-components';
import Img from 'gatsby-image';
import { motion } from 'framer-motion';
import { styles } from '../../../../../globals';

export const ModalCurtain = styled(motion.div)`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const VerticalModalWrapper = styled(motion.div)`
    width: ${({ isMobile }) => (isMobile ? '100%' : '66.5%')};
    height: ${({ isMobile }) => (isMobile ? '100%' : 'calc(65vw * 9 / 16)')};
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

export const StyledModalContainer = styled.div`
    flex-shrink: 1;
    width: 100%;
    height: 100%;
`;
export const StyledModal = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${styles.colors.black};
`;

export const ModalText = styled(motion.div).attrs(() => ({ tabIndex: 0 }))`
    &:focus {
       box-shadow:none;
       outline: none;
    }
    p {
        ${{ ...styles.fonts.modalText }}
        color: ${styles.colors.black};
    }
    h2 {
        text-align: center;
        ${{ ...styles.fonts.header }}
        color: ${styles.colors.black};
    }
`;

export const StyledImage = styled(Img)`
    width: 100%;
`;

export const CloseIcon = styled(motion.i).attrs(() => ({ tabIndex: 0 }))`
    width: 2.31rem;
    height: 2.31rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: ${(props) => (props.red ? styles.colors.red : styles.colors.beigeBg)};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &::before, &::after {
        transform-origin: 50% 50%;
        position: absolute;
    }
    &::before, &::after {
        content: '';
        width: 4%;
        height: 4%;
        background-color: ${(props) => (props.red ? 'white' : styles.colors.black)};
        border-radius: 2px;
    }
    &::before {
        animation: expandL 0.7s 0.9s forwards cubic-bezier(0.820, 0.000, 0.195, 1.000);
    }
    &::after {
        animation: expandR 0.7s 0.9s forwards cubic-bezier(0.820, 0.000, 0.195, 1.000);
    }
    @keyframes expandL {
        0% {
            height: 4%;
        }
        50% {
            transform: rotate(0deg);
            height: 50%;
        }
        100% {
            transform: rotate(45deg);
            height: 50%;

        }
    }
    @keyframes expandR {
        0% {
            height: 4%;
        }
        50% {
            transform: rotate(0deg);
            height: 50%;
        }
        100% {
            transform: rotate(-45deg);
            height: 50%;

        }
    }
`;

export const TestBtn = styled.button`
    background: red;
`;

export const Controls = styled.div`
    display: grid;
    grid-template: 1fr / 5% 20% 70% 5%;
    width: 100%;
    height: 10%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 2%;
    font-size: ${({ isMobile }) => (isMobile ? '2vw' : '1rem')}
`;

export const Control = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-family: 'Red Hat Display';
    color: #fbf6f6;
    position: relative;
    user-select: none;
`;

export const Icon = styled.img`
    cursor: pointer;
    user-select: none;
    padding: 25%;
`;

export const ProgressBar = styled.div`
        position: absolute;
        width: 0%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #df3242;
        user-select: none;
`;

export const ProgressBarContainer = styled.div`
    height: 3px;
    width: 100%;
    background-color: #3f4040;
    position: relative;
    user-select: none;
    cursor: pointer;
    &::after {
        content: '';
        position: absolute;
        height: 800%;
        width: 100%;
        cursor: pointer;
        top: calc(-400% + 2px);
    }
`;

export const VolumeSlider = styled.div`
    width: 50%;
    height: 300%;
    position: absolute;
    left: 25%;
    bottom: 100%;
    background: rgba(35,32,32,0.5);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s;
`;

export const VolumeSliderGuide = styled.div`
    width: 3px;
    height: 90%;
    background-color: rgba(23,22,22, 0.4);
    position: relative;
    cursor: pointer;
    &::after {
        content: '';
        width: 500%;
        height: 100%;
        position: absolute;
        left: calc(-250% + 1px);
    }
`;

export const VolumeSliderProgress = styled.div`
    position: absolute;
    left: 0%;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color: rgba(223,50,66,0.4);
`;

export const VolumeSliderKnob = styled.div`
    width: 11px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 6px);
    background-color: #df3242;
    cursor: pointer;
`;

export const Loader = styled.div`
  display: inline-block;
  position: absolute;
  left: calc(50% - 40px);
  bottom: calc(50% - 40px);
  width: 80px;
  height: 80px;
  & div {
    box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid  #df3242;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color:  #df3242 transparent transparent transparent;
  }
  & div:nth-child(1) {
  animation-delay: -0.45s;
}
& div:nth-child(2) {
  animation-delay: -0.3s;
}
& div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
}}
`;
