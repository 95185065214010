/* eslint-disable no-undef */
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

let webpsupport;

export const isWebpSupported = () => {
  if (typeof webpsupport !== 'undefined') return webpsupport;

  const elem = typeof document === 'object' ? document.createElement('canvas') : {};

  webpsupport = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;

  return webpsupport;
};

export const isBrowser = () => typeof window !== 'undefined';

export const getRoutesQuery = () => {
  const query = useStaticQuery(graphql`
    query {
      PL: mdx(
        frontmatter: {
          language: { eq: "PL" }
          title: { eq: "routes-navigation" }
        }
      ) {
        exports {
          routes {
            name
            number
            route
            isMainPage
            pageTitle
            isLightTheme
            isDark
            mobileBackground {
              publicURL
            }
          }
        }
      }
      EN: mdx(
        frontmatter: {
          language: { eq: "EN" }
          title: { eq: "routes-navigation" }
        }
      ) {
        exports {
          routes {
            name
            number
            route
            isMainPage
            pageTitle
            isLightTheme
            isDark
            mobileBackground {
              publicURL
            }
          }
        }
      }

      DE: mdx(
        frontmatter: {
          language: { eq: "DE" }
          title: { eq: "routes-navigation" }
        }
      ) {
        exports {
          routes {
            name
            number
            route
            isMainPage
            pageTitle
            isLightTheme
            isDark
            mobileBackground {
              publicURL
            }
          }
        }
      }
      FR: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "routes-navigation" }
        }
      ) {
        exports {
          routes {
            name
            number
            route
            isMainPage
            pageTitle
            isLightTheme
            isDark
            mobileBackground {
              publicURL
            }
          }
        }
      }
      RU: mdx(
        frontmatter: {
          language: { eq: "RU" }
          title: { eq: "routes-navigation" }
        }
      ) {
        exports {
          routes {
            name
            number
            route
            isMainPage
            pageTitle
            isLightTheme
            isDark
            mobileBackground {
              publicURL
            }
          }
        }
      }
      UA: mdx(
        frontmatter: {
          language: { eq: "UA" }
          title: { eq: "routes-navigation" }
        }
      ) {
        exports {
          routes {
            name
            number
            route
            isMainPage
            pageTitle
            isLightTheme
            isDark
            mobileBackground {
              publicURL
            }
          }
        }
      }
      HU: mdx(
        frontmatter: {
          language: { eq: "HU" }
          title: { eq: "routes-navigation" }
        }
      ) {
        exports {
          routes {
            name
            number
            route
            isMainPage
            pageTitle
            isLightTheme
            isDark
            mobileBackground {
              publicURL
            }
          }
        }
      }
    }
  `);

  return query;
};


export const getLangFromPath = () => {
  const { pathname } = useLocation();
  const diffLanguages = ['en', 'fr', 'hu', 'ru', 'ua', 'de'];
  let result = 'PL';

  pathname && pathname.split('/').forEach((el) => {
    const lang = diffLanguages.find((language) => language === el);
    if (lang) result = lang.toUpperCase();
  });

  return result;
};
